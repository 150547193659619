import React from "react";
import { Link } from "react-router-dom";

export default function ReportWelcome() {
  return (
    <div>
      <div class="grid grid-cols-1 lg:grid-cols-4 p-4 gap-2">
        <>
          <Link to="/report/assurances">
            <div class="bg-white dark:bg-gray-800 shadow-lg rounded-md flex items-center justify-between p-3 border-b-4 border-green-600 dark:border-gray-600 text-black font-medium group">
              <p className="text-2xl text-center">ASSURANCES</p>
              <div class="text-right">
                <div class="p-0"></div>
              </div>
            </div>
          </Link>
          <Link to="/report/expenses">
            <div class="bg-white dark:bg-gray-800 shadow-lg rounded-md flex items-center justify-between p-3 border-b-4 border-green-600 dark:border-gray-600 text-black font-medium group">
              <p className="text-2xl">DEPENSES </p>
              <div class="text-right">
                <div class="p-0"></div>
              </div>
            </div>
          </Link>
          <Link to="/report/patients">
            <div class="bg-white dark:bg-gray-800 shadow-lg rounded-md flex items-center justify-between p-3 border-b-4 border-green-600 dark:border-gray-600 text-black font-medium group">
              <p className="text-xl">PATIENTS </p>
              <div class="text-right">
                <div class="p-0"></div>
              </div>
            </div>
          </Link>
          <Link to="/report/treatments-rendezvous">
            <div class="bg-white dark:bg-gray-800 shadow-lg rounded-md flex items-center justify-between p-3 border-b-4 border-green-600 dark:border-gray-600 text-black font-medium group mt-1">
              <p className="text-xl uppercase">TRAITEMENTS & Rendezvous</p>
              <div class="text-right">
                <div className="text-start">
                  <div className="p-0"></div>
                </div>
              </div>
            </div>
          </Link>

         
         
        </>
      </div>
    </div>
  );
}

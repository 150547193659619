import React from "react";
import { useState } from "react";
import * as cdiService from "../../services/cdiService";

import { useNavigate, useParams } from "react-router-dom";
import Header from "../../components/Header";
import SideBar2 from "../../components/SideBar2";
import { useEffect } from "react";

export default function ExpenseTypesUpdate() {
  const id = useParams();

  const [name] = useState("");
  const navigate = useNavigate();

  const ListExpenseType = () => {
    cdiService.loadExpenseType(id.id).then((res) => {
      setData(res.data);
    });
  };

  useEffect(() => {
    ListExpenseType();
  }, []);

  const [data, setData] = useState({
    name,
  });

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      cdiService
        .updateExpenseType(id.id, data)
        .then(
          (res) => {
            console.log("CHECKING DATA", res.data);
            navigate("/expenses");
          },
          (error) => {
            console.log(error);
          }
        )
        .catch((error) => {
          console.log("ERROR", error.res);
        });
    } catch (err) {
      console.log(err);
    }
  };
  console.log("DATA + ", data);

  return (
    <div class="min-h-screen flex flex-col flex-auto flex-shrink-0 antialiased bg-white dark:bg-gray-700 text-black dark:text-white">
      <Header />
      <SideBar2 />
      <div className="h-full ml-14 mt-14 mb-10 md:ml-64">
        <div className="grid grid-cols-1 lg:grid-cols-1 p-4 gap-2">
          <div className="bg-white rounded shadow-lg p-4 px-4 md:p-8 mb-6">
            <div className="grid gap-4 gap-y-2 text-sm grid-cols-1 lg:grid-cols-3">
              <div className="lg:col-span-2">
                <form onSubmit={handleSubmit}>
                  <div className="grid gap-4 gap-y-2 text-sm grid-cols-1 md:grid-cols-5">
                    <div className="md:col-span-3"></div>
                    <div className="md:col-span-2 -mt-1">
                      <input
                        type="text"
                        required
                        value={data.name}
                        onChange={(e) =>
                          setData({
                            ...data,
                            name: e.target.value,
                          })
                        }
                        className=" flex items-center h-10 border border-black mt-1 rounded px-4 w-full"
                        placeholder="Nom du type de depense"
                      />
                    </div>
                  </div>
                  <div className="grid gap-4 gap-y-2 text-sm grid-cols-1 md:grid-cols-5 mt-6">
                    <div className="md:col-span-3"></div>
                    <div className="md:col-span-2 -mt-1">
                      <button
                        type="submit"
                        className="dark:active:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.2),0_4px_18px_0_rgba(59,113,202,0.1)]] inline-block  rounded bg-primary px-6 pb-2 pt-2.5 text-xs font-medium uppercase leading-normal text-black shadow-[0_4px_9px_-4px_#3b71ca] transition duration-150 ease-in-out hover:bg-primary-600 hover:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] focus:bg-primary-600 focus:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] focus:outline-none focus:ring-0 active:bg-primary-700 active:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] dark:shadow-[0_4px_9px_-4px_rgba(59,113,202,0.5)] dark:hover:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.2),0_4px_18px_0_rgba(59,113,202,0.1)] dark:focus:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.2),0_4px_18px_0_rgba(59,113,202,0.1)] w-full"
                        onClick={handleSubmit}
                      >
                        Mettre à jour
                      </button>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

import React from "react";
import { Carousel } from "react-carousel-minimal";

export default function ImageSlider() {
  const data = [
    {
      image: "../assets/dental-clinic-renovation.jpeg",
    },

    {
      image: "../assets/surgery.jpeg",
    },

    {
      image: "../assets/equipe.jpeg",
    },

    {
      image: "../assets/mal.jpg",
    },

    {
      image: "../assets/cabinetdentaire.jpg",
    },

    {
      image: "../assets/cancer.jpg",
    },
  ];

  const captionStyle = {
    fontSize: "2em",
    fontWeight: "bold",
  };
  const slideNumberStyle = {
    fontSize: "10px",
    fontWeight: "bold",
  };

  return (
    <div style={{ textAlign: "center" }}>
      <div style={{ padding: "0 10px" }}>
        <Carousel
          data={data}
          time={2000}
          width="100%"
          height="450px"
          captionStyle={captionStyle}
          radius="5px"
          slideNumber={true}
          slideNumberStyle={slideNumberStyle}
          captionPosition="bottom"
          automatic={true}
          dots={true}
          pauseIconColor="white"
          pauseIconSize="40px"
          slideBackgroundColor="darkgrey"
          slideImageFit="cover"
          thumbnails={true}
          thumbnailWidth="100px"
          style={{
            textAlign: "center",
            maxWidth: "100%",
            Height: "300px",
            margin: "30px auto",
          }}
        />
      </div>
    </div>
  );
}

import React, { useState } from "react";
import * as cdiService from "../../services/cdiService";

import { useNavigate } from "react-router-dom";
import Header from "../../components/Header";
import SideBar2 from "../../components/SideBar2";

export default function NewIntervention() {
  const navigate = useNavigate();

  const [name, setName] = useState("");
  const [price, setPrice] = useState("");
  const [description, setDescription] = useState("");
  // const [email, setEmail] = useState("");

  const data = {
    name,
    price,
    description,
  };

  console.log("DATA + ", data);

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      cdiService
        .addIntervention(data)
        .then(
          (res) => {
            console.log("CHECKING DATA", res.data);
            navigate("/intervention");
          },
          (error) => {
            console.log(error);
          }
        )
        .catch((error) => {
          console.log("ERROR", error.res);
        });
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <div class="min-h-screen flex flex-col flex-auto flex-shrink-0 antialiased bg-white dark:bg-gray-700 text-black dark:text-white">
      <Header />
      <SideBar2 />
      <div className="h-full ml-14 mt-14 mb-10 md:ml-64">
        <div className="grid grid-cols-1 lg:grid-cols-1 p-4 gap-2">
          <div className="bg-white rounded shadow-lg p-4 px-4 md:p-8 mb-6">
            <div className="grid gap-4 gap-y-2 text-sm grid-cols-1 lg:grid-cols-3">
              <div className="text-gray-600">
                <p className="font-medium text-lg">
                  Information sur l'intervention
                </p>
                <p>Remplissez ce formulaire, s'il vout plait.</p>
              </div>

              <div className="lg:col-span-2">
                <form onSubmit={handleSubmit}>
                  <div className="grid gap-4 gap-y-2 text-sm grid-cols-1 md:grid-cols-6">
                    <div className="md:col-span-3">
                      <label for="address">Intervention</label>
                      <input
                        type="text"
                        name="name"
                        value={name}
                        onChange={(e) => setName(e.target.value)}
                        className="h-10 border mt-1 rounded px-4 w-full bg-gray-50"
                        placeholder=""
                        required
                      />
                    </div>
                    <div className="md:col-span-3">
                      <label for="email">Prix de l'intervention</label>
                      <input
                        type="price"
                        name="price"
                        value={price}
                        onChange={(e) => setPrice(e.target.value)}
                        className="h-10 border mt-1 rounded px-4 w-full bg-gray-50"
                        placeholder=""
                      />
                    </div>
                    <div className="md:col-span-3">
                      <label for="city">Description</label>
                      <textarea
                        type="text"
                        required
                        rows={2}
                        name="description"
                        value={description}
                        onChange={(e) => setDescription(e.target.value)}
                        class="block p-2.5 w-full text-sm text-gray-900 bg-gray-50 rounded-sm border border-gray-300 focus:ring-black focus:border-black dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-black dark:focus:border-black"
                        placeholder="Description de l'intervention"
                      ></textarea>
                    </div>
                    <button className="bg-white text-black font-bold py-2 px-4 ml-40 rounded w-[130px]">
                      Sauvegarder
                    </button>
                  </div>

                  
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

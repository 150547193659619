import React, { useEffect, useState } from "react";
import * as cdiService from "../../services/cdiService";
import { Link } from "react-router-dom";
import { AiOutlineUser } from "react-icons/ai";
import DataTable from "react-data-table-component";

export default function UsersList() {
  const [record, setRecord] = useState([]);

  const [filterrecords, setFilterRecords] = useState([]);

  const columns = [
    {
      name: "#",
      selector: (row) => row.id,
      sortable: true,
    },
    {
      name: "Role",
      selector: (row) => row.role,
      sortable: true,
    },
    {
      name: " Prénoms & Nom",
      selector: (row) => row.firstname + " " + row.lastname,
      sortable: true,
    },

    {
      name: "email",
      selector: (row) => row.email,
      sortable: true,
    },
    {
      name: " N° Telephone",
      selector: (row) => row.telephone,
      sortable: true,
    },

    
  ];

  const allUsers = () => {
    cdiService.usersList().then((res) => {
      setRecord(res.data);
      setFilterRecords(res.data);
    });
  };

  useEffect(() => {
    allUsers();
  }, []);

  const search = (event) => {
    const newData = filterrecords.filter(
      (row) =>
        (row.firstname + " " + row?.lastname)
          .toLowerCase()
          .includes(event.target.value.toLowerCase()) ||
        (row?.email).toLowerCase().includes(event.target.value.toLowerCase()) ||
        (row?.telephone)
          .toLowerCase()
          .includes(event.target.value.toLowerCase()) ||
        row.id.toString().includes(event.target.value.toLowerCase())
    );
    setRecord(newData);
  };

  const tableHeaderstyle = {
    headCells: {
      style: {
        fontWeight: "bold",
        fontSize: "14px",
        backgroundColor: "black",
        color: "white",
      },
    },
  };

  return (
    <div class="grid grid-cols-1 lg:grid-cols-1 p-4 gap-4">
      <div>
        <Link
          className=" text-white bg-black font-bold uppercase text-sm px-1 py-2  shadow hover:shadow-lg outline-none focus:outline-none mr-1  ease-linear transition-all duration-150 rounded-md float-left w-[110px] h-[34px] mb-0"
          variant="outlined"
          Link
          to={"/user/new"}
        >
          <span className="ml-4"> Ajouter</span>
          <AiOutlineUser className="text-white font-extrabold -mt-4" />
        </Link>
        <Link
          className=" text-white bg-black font-bold uppercase text-sm px-1 py-2  shadow hover:shadow-lg outline-none focus:outline-none mr-1  ease-linear transition-all duration-150 rounded-md float-left w-[160px] h-[34px] mb-0"
          variant="outlined"
          Link
          to={"/user/admin"}
        >
          <span className="ml-4"> Administrator(s)</span>
         
          <AiOutlineUser className="text-white font-extrabold -mt-4" />
        </Link>
        <Link
          className=" text-white bg-black font-bold uppercase text-sm px-1 py-2  shadow hover:shadow-lg outline-none focus:outline-none mr-1  ease-linear transition-all duration-150 rounded-md float-left w-[140px] h-[34px] mb-0"
          variant="outlined"
          Link
          to={"/user/accountant"}
        >  <span className="ml-4">Comptable(s)</span>
          
          <AiOutlineUser className="text-white font-extrabold -mt-4" />
        </Link>
        <Link
          className=" text-white bg-black font-bold uppercase text-sm px-1 py-2  shadow hover:shadow-lg outline-none focus:outline-none mr-1  ease-linear transition-all duration-150 rounded-md float-left w-[180px] h-[34px] mb-0"
          variant="outlined"
          Link
          to={"/user/doctor"}
        >
          <span className="ml-4"> Docteurs</span>
          Docteur(s) <AiOutlineUser className="text-white font-extrabold -mt-4" />
        </Link>
        <Link
          className=" text-white bg-black font-bold uppercase text-sm px-1 py-2  shadow hover:shadow-lg outline-none focus:outline-none mr-1  ease-linear transition-all duration-150 rounded-md float-left w-[150px] h-[34px] mb-0"
          variant="outlined"
          Link
          to={"/user/cashier"}
        >
          <span className="ml-4">Receptionniste(s)</span>
         
          <AiOutlineUser className="text-white font-extrabold -mt-4" />
        </Link>
      </div>
      <DataTable
        className="-mt-5"
        columns={columns}
        data={record}
        pagination
        fixedHeader
        fixedHeaderScrollHeight="450px"
        selectableRowsHighlight
        highlightOnHover
        customStyles={tableHeaderstyle}
        subHeader
        subHeaderComponent={
          <input
            type="text"
            placeholder="search here..."
            className="w-[70%] h-[35px] float-left  rounded-md text-black 
            border border-[#0b1011] py-1 px-4 mb-4 -ml-6  bg-slate-50 mt-0"
            onChange={search}
          />
        }
        subHeaderAlign="left"
      />
      ​
    </div>
  );
}

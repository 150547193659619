import React, { useEffect, useState } from "react";

import { Link } from "react-router-dom";
import { RiLoginCircleLine } from "react-icons/ri";
import * as cdiService from "../services/cdiService";

export default function NewNavBar() {
  const [showMenu, setShowMenu] = useState(false);

  const [isScrolled, setIsScrolled] = useState(false);
  const [record, setRecord] = useState([]);

  const ClinicInfo = () => {
    cdiService.clinicPublic().then((res) => {
      console.log("DATA", res.data);
      setRecord(res.data);
    });
  };

  useEffect(() => {
    ClinicInfo();
  }, []);

  const handleMenuClick = () => {
    setShowMenu(!showMenu);
  };

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY >= 33) {
        setIsScrolled(true);
      } else {
        setIsScrolled(false);
      }
    };
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <div
      className={` z-50 w-full shadow sticky top-0 h-20 flex justify-between items-center px-4 text-black ${
        isScrolled ? "bg-white" : "bg-transparent"
      }`}
    >
      {record.map((menu, index) => (
        <div className="text-4xl cursor-pointer inline-flex items-center text-black" key={index}>
          <Link to="/" smooth={true} duration={500}>
           {menu.name}
          </Link>
        </div>
      ))}

      <div className="md:hidden" onClick={handleMenuClick}>
        {showMenu ? (
          <svg
            className="h-6 w-6 text-black cursor-pointer"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 20 20"
            fill="currentColor"
          >
            <path
              fillRule="evenodd"
              d="M14.95 5.879l-1.414-1.414L10 8.586 6.464 5.05 5.05 6.464 8.586 10l-3.536 3.536 1.414 1.414L10 11.414l3.536 3.536 1.414-1.414L11.414 10l3.536-3.536z"
              clipRule="evenodd"
            />
          </svg>
        ) : (
          <svg
            className="h-6 w-6 text-black cursor-pointer"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 20 20"
            fill="currentColor"
          >
            <path
              fillRule="evenodd"
              d="M3 4a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM3 10a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM3 16a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1z"
              clipRule="evenodd"
            />
          </svg>
        )}
      </div>
      <ul className="hidden md:flex">
        <li className="text-black  p-4 font-bold ">
          <a href="#about" smooth={true} duration={500}>
            About
          </a>
        </li>
        <li className="text-black  p-4 font-bold">
          <a href="#contact" smooth={true} duration={500}>
            Assurances
          </a>
        </li>
        <li className="text-black  p-4 font-bold">
          <a href="#services" smooth={true} duration={500}>
            Services
          </a>
        </li>
      </ul>
      <div className="hidden md:flex">
        <button className="px-4 py-2 text-white  hover hover:text-white mx-2 rounded-full">
          <Link to="/login" smooth={true} duration={500}>
            <RiLoginCircleLine size={20} className="bg-black" />
          </Link>
        </button>
      </div>
      <div
        className={`${
          showMenu ? "flex" : "hidden"
        } md:hidden flex flex-col bg-white text-black w-full absolute top-16 left-0 z-10`}
      >
        <a
          href="#about"
          smooth={true}
          duration={500}
          className="p-4 cursor-pointer"
          onClick={handleMenuClick}
        >
          About
        </a>
        <a
          href="#contact"
          smooth={true}
          duration={500}
          className="p-4 cursor-pointer"
          onClick={handleMenuClick}
        >
          Assurances
        </a>

        <a
          href="#services"
          smooth={true}
          duration={500}
          className="p-4 cursor-pointer"
          onClick={handleMenuClick}
        >
          Services
        </a>

        <Link
          to="/login"
          smooth={true}
          duration={500}
          className="p-4 cursor-pointer"
          onClick={handleMenuClick}
        >
          Se Connecter
        </Link>

        {/* <Notification notify={notify} setNotify={setNotify} /> */}
      </div>
    </div>
  );
}

import React, { useState } from "react";
import * as cdiService from "../../services/cdiService";
import * as authService from "../../services/authService";
import jwt_decode from "jwt-decode";
import { useNavigate, useParams } from "react-router-dom";
import Header from "../../components/Header";
import SideBar2 from "../../components/SideBar2";

export default function NewAppointment() {
  const navigate = useNavigate();
  const [currentUser] = useState(authService.getCurrentUser());
  const [user] = useState(jwt_decode(currentUser.access));
  const { id } = useParams();
  const [commentaire, setCommentaire] = useState("");
  const [rendezvousdate, setRendezvousdate] = useState("");
  const [rendezvoustime, setRendezvoustime] = useState("");

  const data = {
    user: user.id,
    patient: id,
    status: "En-cours",
    commentaire,
    rendezvousdate,
    rendezvoustime,
  };
  console.log("Data for appointment", data);
  const handledate = (e) => {
    const getdatevalue = e.target.value;
    setRendezvousdate(getdatevalue);
  };
  const handletime = (e) => {
    const gettimevalue = e.target.value;
    setRendezvoustime(gettimevalue);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      cdiService
        .addAppointment(data)
        .then(
          (res) => {
            console.log("CHECKING APPOINTMENT DATA", res.data);
            navigate("/appointment");
          },
          (error) => {
            console.log(error);
          }
        )
        .catch((error) => {
          console.log("ERROR", error.res);
        });
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <div class="min-h-screen flex flex-col flex-auto flex-shrink-0 antialiased bg-white dark:bg-gray-700 text-black dark:text-white">
      <Header />
      <SideBar2 />
      <div className="h-full ml-14 mt-14 mb-10 md:ml-64">
        <div className="grid grid-cols-1 lg:grid-cols-1 p-4 gap-2">
          <div className="bg-white rounded shadow-lg p-4 px-4 md:p-8 mb-6">
            <div className="grid gap-4 gap-y-2 text-sm grid-cols-1 lg:grid-cols-3">
              <div className="text-gray-600">
                <p className="font-medium text-lg">Details tu Rendez-vous</p>
                <p>Please fill out all the fields.</p>
              </div>
              <div className="lg:col-span-2">
                <form onSubmit={handleSubmit}>
                  <div className="grid gap-4 gap-y-2 text-sm grid-cols-1 md:grid-cols-6">
                    <div className="md:col-span-4">
                      <label
                        for="date"
                        class="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                      >
                        Date
                      </label>
                      <input
                        type="date"
                        class="block p-2.5 w-full text-sm text-gray-900 bg-gray-50 rounded-lg border border-black focus:border-black"
                        onChange={(e) => handledate(e)}
                      />
                    </div>
                    <div className="md:col-span-2 ">
                      <label
                        for="time"
                        class="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                      >
                        Heure
                      </label>

                      <input
                        type="time"
                        class="block p-2.5 w-full text-sm text-gray-900 bg-gray-50 rounded-lg border border-black focus:border-black"
                        onChange={(e) => handletime(e)}
                      />
                    </div>

                    <div className="md:col-span-6">
                      <label
                        for="message"
                        class="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                      >
                        Raison du rendez-vous
                      </label>
                      <textarea
                        id="message"
                        rows="4"
                        onChange={(e) => setCommentaire(e.target.value)}
                        class="block p-2.5 w-full text-sm text-gray-900 bg-gray-50 rounded-sm border border-gray-300 focus:ring-black focus:border-black dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-black dark:focus:border-black"
                        placeholder="Raison du rendez-vous..."
                      ></textarea>
                    </div>
                    <div className="md:col-span-6">
                      <div className="w-full">
                        <button className="bg-white hover:bg-green-700 text-black font-bold py-2 px-4 rounded w-full">
                          Sauvegarder
                        </button>
                      </div>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

import { Route, Routes } from "react-router-dom";
import "./App.css";
import Welcome from "./pages/Welcome";
import Login from "./pages/Login";
import Patient from "./pages/patients/Patient";
import NewPatient from "./pages/patients/NewPatient";
import UpdatePatient from "./pages/patients/UpdatePatient";
import Users from "./pages/users/Users";
import NewUser from "./pages/users/NewUser";
import Insurances from "./pages/insurances/Insurances";
import NewInsurances from "./pages/insurances/NewInsurance";
import Treatment from "./pages/treatments/Treatment";
import NewTreatment from "./pages/treatments/NewTreatment";
import PatientForTreatment from "./pages/treatments/PatientForTreatment";
import Payments from "./pages/payments/Payment";
import TreatmentTobePaid from "./pages/payments/TreatmentTobePaid";
import NewPayment from "./pages/payments/NewPayment";
import Appointment from "./pages/appointments/Appointment";
import NewAppointment from "./pages/appointments/NewAppointment";
import UpdateAppointment from "./pages/appointments/UpdateAppointment";
import PatientForAppointment from "./pages/appointments/PatientForAppointment";
import ViewTreatment from "./pages/treatments/ViewTreatment";
// import UpdateUser from "./pages/users/UpdateAdmin";
import UpdateInsurances from "./pages/insurances/UpdateInsurance";
import Dashboard from "./pages/Dashboard";
import Reports from "./pages/Reporting/Reports";
import Intervention from "./pages/interventions/intervention";
import NewIntervention from "./pages/interventions/NewIntervention";
import UpdateIntervention from "./pages/interventions/UpdateIntervention";
import Home from "./pages/Home";
import ClinicSettings from "./pages/clinicInfo/ClinicSettings";
import Clinic from "./pages/clinicInfo/Clinic";

import UsersAdmin from "./pages/users/UsersAdmin";
import UpdateAdmin from "./pages/users/UpdateAdmin";
import UsersDoctor from "./pages/users/UsersDoctor";
import UpdateClinicSettings from "./pages/clinicInfo/UpdateClinicSettings";
import NewPatientUser from "./pages/users/NewPatientUser";
import DashboardWelcome from "./pages/DashboardWelcome";
import ReportDataAssurance from "./pages/Reporting/ReportDataAssurance";
import ReportDataPatient from "./pages/Reporting/ReportDataPatient";
import ReportDataTreatment from "./pages/Reporting/ReportDataTreatment";
import ExpenseTypes from "./pages/expenses/ExpenseTypes";
import Expenses from "./pages/expenses/Expenses";
import ExpensesHome from "./pages/expenses/ExpensesHome";
import ExpenseTypeList from "./pages/expenses/ExpenseTypeList";
import ExpensesList from "./pages/expenses/ExpensesList";
import ExpensesUpdate from "./pages/expenses/ExpensesUpdate";
import ReportDataExpenses from "./pages/Reporting/ReportDataExpenses";
import { ProtectedRoutes } from "./components/ProtectedRoutes";
import SendMessage from "./pages/patients/SendMessage";
import MessageList from "./pages/patients/MessageList";
import UsersAccountant from "./pages/users/UsersAccountant";
import UsersCashier from "./pages/users/UsersCashier";
import ExpenseTypesUpdate from "./pages/expenses/ExpenseTypesUpdate";
import ViewMessage from "./pages/patients/ViewMessage";

function App() {

  return (
    <div className="h-screen bg-white ">
      <Routes>
        <Route path="login" element={<Login />} />
        <Route path="" element={<Home />} />

        <Route element={<ProtectedRoutes />}>
        <Route path="clinic" element={<Clinic />} />
        <Route path="clinic/add" element={<ClinicSettings />} />
        <Route path="clinic/:id" element={<UpdateClinicSettings />} />
        <Route path="welcome" element={<Welcome />} />
        <Route path="patient" element={<Patient />} />
        <Route path="patient/new" element={<NewPatient />} />
        <Route path="patient/update/:id" element={<UpdatePatient />} />
        <Route path="send-message" element={<SendMessage />} />
        <Route path="message" element={<MessageList />} />
        <Route path="message/:id" element={<ViewMessage />} />
        <Route path="intervention" element={<Intervention />} />
        <Route path="intervention/new" element={<NewIntervention />} />
        <Route path="intervention/:id" element={<UpdateIntervention />} />
        <Route path="user" element={<Users />} />
        <Route path="user/new" element={<NewUser />} />
        <Route
          path="user/create-patient-user/:id"
          element={<NewPatientUser />}
        />
        <Route path="user/admin" element={<UsersAdmin />} />
        <Route path="user/admin/:id" element={<UpdateAdmin />} />
        <Route path="user/doctor" element={<UsersDoctor />} />
        <Route path="user/accountant" element={<UsersAccountant />} />
        <Route path="user/cashier" element={<UsersCashier />} />
        <Route path="insurance" element={<Insurances />} />
        <Route path="insurance/new" element={<NewInsurances />} />
        <Route path="insurance/:id" element={<UpdateInsurances />} />
        <Route path="treatment" element={<Treatment />} />
        <Route path="treatment/get-patient" element={<PatientForTreatment />} />
        <Route
          path="treatment/new/:id/:insurance_percentage/:firstname/:lastname"
          element={<NewTreatment />}
        />
        <Route path="treatment/:id" element={<ViewTreatment />} />

        <Route path="bill" element={<Payments />} />
        <Route path="bill/get-treatment" element={<TreatmentTobePaid />} />
        <Route
          path="bill/new/:id/:partpatient/:treatmentamount/:partinsurance/:patient"
          element={<NewPayment />}
        />

        <Route path="appointment" element={<Appointment />} />
        <Route
          path="appointment/get-patient"
          element={<PatientForAppointment />}
        />
        <Route path="appointment/new/:id" element={<NewAppointment />} />
        <Route path="appointment/update/:id" element={<UpdateAppointment />} />

        <Route path="dashboard" element={<Dashboard />} />
        <Route path="dashboardwelcome" element={<DashboardWelcome />} />
        <Route path="report" element={<Reports />} />
        <Route path="report/assurances" element={<ReportDataAssurance />} />
        <Route path="report/patients" element={<ReportDataPatient />} />
        <Route path="report/expenses" element={<ReportDataExpenses />} />
        <Route
          path="report/treatments-rendezvous"
          element={<ReportDataTreatment />}
        />

        <Route path="expenses" element={<ExpensesHome />} />
        <Route path="expense-types" element={<ExpenseTypes />} />
        <Route path="expense-types/:id" element={<ExpenseTypesUpdate />} />
        <Route path="expense-types-list" element={<ExpenseTypeList />} />
        <Route path="new-expense" element={<Expenses />} />
        <Route path="expense-list" element={<ExpensesList />} />
        <Route path="expense/:id" element={<ExpensesUpdate />} />
        {/* Handle other routes */}
      </Route>

        
      </Routes>
    </div>
  );
}

export default App;

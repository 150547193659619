import React, { useEffect, useState } from "react";
import * as cdiService from "../../services/cdiService";
import { Link } from "react-router-dom";
import { MdSick } from "react-icons/md";
import DataTable from "react-data-table-component";

export default function GetTreatmentForBill() {
  const [record, setRecord] = useState([]);
  const [filterrecords, setFilterRecords] = useState([]);

  const columns = [
    {
      name: "#",
      selector: (row) => row.id,
      sortable: true,
    },

    {
      name: "Traitement Montant",
      selector: (row) => row.treatmentamount,
      sortable: true,
    },
    {
      name: "Part Patient",
      selector: (row) => row.partpatient,
      sortable: true,
    },
    {
      name: "Part Assurance",
      selector: (row) => row.partinsurance,
      sortable: true,
    },

    {
      name: "Assigner",
      cell: (row) => (
        <Link
          Link
          to={`/bill/new/${row.id}/${row.partpatient}/${row.treatmentamount}/${row.partinsurance}/${row.patient.id}`}
        >
          <MdSick size={20} className="text-green-700" />
        </Link>
      ),
    },
  ];

  const unpaidTreatments = () => {
    cdiService.allTreatments().then((res) => {
      let unpaid = res.data.filter((x) => x.statuspayment === "Impayé");
      console.log("UNPAID", unpaid);
      setRecord(unpaid);
      setFilterRecords(unpaid);
    });
  };

  useEffect(() => {
    unpaidTreatments();
  }, []);

  const search = (event) => {
    const newData = filterrecords.filter((row) =>
      row.id.toString().includes(event.target.value.toLowerCase())
    );
    setRecord(newData);
  };

  const tableHeaderstyle = {
    headCells: {
      style: {
        fontWeight: "bold",
        fontSize: "14px",
        backgroundColor: "black",
        color: "white",
      },
    },
  };

  return (
    <div class="grid grid-cols-1 lg:grid-cols-1 p-4 gap-4">

        <DataTable
          className="-mt-2"
          columns={columns}
          data={record}
          pagination
          fixedHeader
          fixedHeaderScrollHeight="450px"
          selectableRowsHighlight
          highlightOnHover
          customStyles={tableHeaderstyle}
          subHeader
          subHeaderComponent={
            <input
              type="text"
              placeholder="search here..."
              className="w-[70%] h-[35px] float-left  rounded-md text-black 
            border border-[#0b1011] py-1 px-4 -mb-1 -ml-6 -mt-4 bg-slate-50"
              onChange={search}
            />
          }
          subHeaderAlign="left"
        />
    
    </div>
  );
}

import React, { useEffect, useState } from "react";
import * as cdiService from "../../services/cdiService";
import { useNavigate, useParams } from "react-router-dom";
import Header from "../../components/Header";
import SideBar2 from "../../components/SideBar2";

export default function UpdateClinicSettings() {
  const navigate = useNavigate();

  const id = useParams();
  console.log("PARAMS", id.id);

  const [name] = useState("");
  const [address] = useState("");
  const [about] = useState("");
  const [telephone] = useState("");
  const [telephonemobile] = useState("");
  const [telephonemobile2] = useState("");
  const [email] = useState("");
  const [vision] = useState("");
  const [mission] = useState("");
  const [faceBook] = useState("");
  const [youTube] = useState("");
  const [linkedIn] = useState("");
  const [twitter] = useState("");

  const loadClinicInfo = () => {
    cdiService.getClinicInfo(id.id).then((res) => {
      console.log("DATA CLINIC", res.data);
      setData(res.data);
    });
  };

  useEffect(() => {
    loadClinicInfo();
  }, []);

  const [data, setData] = useState({
    name,
    address,
    about,
    email,
    telephone,
    faceBook,
    linkedIn,
    twitter,
    youTube,
    vision,
    mission,
    telephonemobile,
    telephonemobile2,
  });

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      cdiService
        .UpdateClinicSettings(id.id, data)
        .then(
          (res) => {
            console.log("CHECKING DATA", res.data);
            navigate("/clinic");
          },
          (error) => {
            console.log(error);
          }
        )
        .catch((error) => {
          console.log("ERROR", error.res);
        });
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <div class="min-h-screen flex flex-col flex-auto flex-shrink-0 antialiased bg-white dark:bg-gray-700 text-black dark:text-white">
      <Header />
      <SideBar2 />
      <div className="h-full ml-14 mt-14 mb-10 md:ml-64">
        <div className="grid grid-cols-1 lg:grid-cols-1 p-4 gap-2">
          <div className="bg-white rounded shadow-lg p-4 px-4 md:p-8 mb-6">
            <div className="grid gap-4 gap-y-2 text-sm grid-cols-1 lg:grid-cols-3">
              <div className="text-gray-600">
                <p className="font-medium text-lg">
                  Informations de la clinique
                </p>
                <p>Remplissez ce formulaire, s'il vout plait.</p>
              </div>

              <div className="lg:col-span-2">
                <form class="w-full" onSubmit={handleSubmit}>
                  <div class="flex flex-wrap -mx-3 mb-1">
                    <div class="w-full px-3">
                      <label
                        class="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                        for="grid-password"
                      >
                        Nom de la clinique
                      </label>
                      <input
                        class="appearance-none block w-full text-gray-700 border border-gray-200 rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                        type="text"
                        name="name"
                        value={data.name}
                        onChange={(e) =>
                          setData({
                            ...data,
                            name: e.target.value,
                          })
                        }
                        placeholder="Nom de la clinique"
                      />
                    </div>
                  </div>

                  <div class="flex flex-wrap -mx-3 mb-1">
                    <div class="w-full md:w-1/2 px-3 mb-6 md:mb-0">
                      <label class="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2">
                        N° Téléphone Fixe
                      </label>
                      <input
                        class="appearance-none block w-full  border rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white"
                        type="text"
                        required
                        value={data.telephone}
                        onChange={(e) =>
                          setData({
                            ...data,
                            telephone: e.target.value,
                          })
                        }
                        placeholder="+225"
                      />{" "}
                    </div>

                    <div class="w-full md:w-1/2 px-3">
                      <label class="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2">
                        N° Téléphone Mobile 1
                      </label>
                      <input
                        class="appearance-none block w-full   border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                        type="text"
                        required
                        value={data.telephonemobile}
                        onChange={(e) =>
                          setData({
                            ...data,
                            telephonemobile: e.target.value,
                          })
                        }
                        placeholder="+225"
                      />
                    </div>
                  </div>
                  <div class="flex flex-wrap -mx-3 mb-1">
                    <div class="w-full md:w-1/2 px-3 mb-6 md:mb-0">
                      <label class="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2">
                        N° Téléphone Mobile 2
                      </label>
                      <input
                        class="appearance-none block w-full  border rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white"
                        type="text"
                        required
                        value={data.telephonemobile2}
                        onChange={(e) =>
                          setData({
                            ...data,
                            telephonemobile2: e.target.value,
                          })
                        }
                        placeholder="+225"
                      />
                    </div>

                    <div class="w-full md:w-1/2 px-3">
                      <label class="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2">
                        Email Addresse
                      </label>
                      <input
                        class="appearance-none block w-full   border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                        type="email"
                        name="email"
                        onChange={(e) =>
                          setData({
                            ...data,
                            email: e.target.value,
                          })
                        }
                        value={data.email}
                        placeholder="email...@..com"
                      />
                    </div>
                  </div>

                  <div className="flex flex-wrap -mx-3 mb-1">
                    <div className="w-full px-3">
                      <label className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2">
                        Addresse
                      </label>
                      <textarea
                        class="appearance-none block w-full  text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                        type="text"
                        name="address"
                        value={data.address}
                        onChange={(e) =>
                          setData({
                            ...data,
                            address: e.target.value,
                          })
                        }
                        placeholder="Addresse"
                      />
                    </div>
                  </div>

                  <div className="flex flex-wrap -mx-3 mb-1">
                    <div className="w-full px-3">
                      <label className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2">
                        A propos de nous
                      </label>
                      <textarea
                        class="appearance-none block w-full  text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                        type="text"
                        name="about"
                        value={data.about}
                        onChange={(e) =>
                          setData({
                            ...data,
                            about: e.target.value,
                          })
                        }
                        placeholder="Addresse"
                      />
                    </div>
                  </div>
                  <div className="flex flex-wrap -mx-3 mb-1">
                    <div className="w-full md:w-1/2 px-3 mb-6 md:mb-0">
                      <label className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2">
                        Vision
                      </label>
                      <textarea
                        class="appearance-none block w-full  text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                        type="text"
                        required
                        name="vision"
                        value={data.vision}
                        onChange={(e) =>
                          setData({
                            ...data,
                            vision: e.target.value,
                          })
                        }
                        placeholder="Vision"
                      />
                    </div>
                    <div className="w-full md:w-1/2 px-3 mb-6 md:mb-0">
                      <label className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2">
                        Mission
                      </label>
                      <textarea
                        className="appearance-none block w-full  text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                        type="text"
                        required
                        name="mission"
                        value={data.mission}
                        onChange={(e) =>
                          setData({
                            ...data,
                            mission: e.target.value,
                          })
                        }
                        placeholder="Mission"
                      />
                    </div>
                  </div>

                  <div className="flex flex-wrap -mx-3 mb-1">
                    <div className="w-full md:w-1/2 px-3 mb-6 md:mb-0">
                      <label className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2">
                        Lien FaceBook
                      </label>
                      <input
                        className="appearance-none block w-full text-gray-700 border border-gray-200 rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                        type="text"
                        name="faceBook"
                        required
                        value={data.faceBook}
                        onChange={(e) =>
                          setData({
                            ...data,
                            faceBook: e.target.value,
                          })
                        }
                        placeholder="Lien FaceBook"
                      />
                    </div>

                    <div className="w-full md:w-1/2 px-3 mb-6 md:mb-0">
                      <label className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2">
                        Lien YouTube
                      </label>
                      <input
                        className="appearance-none block w-full text-gray-700 border border-gray-200 rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                        type="text"
                        name="youTube"
                        value={data.youTube}
                        onChange={(e) =>
                          setData({
                            ...data,
                            youTube: e.target.value,
                          })
                        }
                        placeholder="Lien YouTube"
                      />
                    </div>
                  </div>
                  <div className="flex flex-wrap -mx-3 mb-1">
                  </div>
                  <div className="flex flex-wrap -mx-3 mb-1">
                    <div className="w-full md:w-1/2 px-3 mb-6 md:mb-0">
                      <label className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2">
                        Lien Twitter
                      </label>
                      <input
                        className="appearance-none block w-full text-gray-700 border border-gray-200 rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                        type="text"
                        name="twitter"
                        value={data.twitter}
                        onChange={(e) =>
                          setData({
                            ...data,
                            twitter: e.target.value,
                          })
                        }
                        placeholder="Lien Twitter"
                      />
                    </div>

                    <div className="w-full md:w-1/2 px-3 mb-6 md:mb-0">
                      <label className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2">
                        Lien LinkedIn
                      </label>
                      <input
                        className="appearance-none block w-full text-gray-700 border border-gray-200 rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                        type="text"
                        placeholder="Lien LinkedIn"
                        name="linkedIn"
                        value={data.linkedIn}
                        onChange={(e) =>
                          setData({
                            ...data,
                            linkedIn: e.target.value,
                          })
                        }
                      />
                    </div>

                  </div>
                  <div className="flex flex-wrap -mx-3 mb-1">
                  </div>

                  <div className="flex flex-wrap -mx-3 mb-1">
                    <div className="w-full px-3">
                      <button className="bg-white  text-black font-bold py-2 px-4 rounded w-full">
                        Sauvegarder
                      </button>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

import React, { useEffect, useState } from "react";
// import PatientListList from "./PatientList";
import Header from "../../components/Header";
import SideBar2 from "../../components/SideBar2";
import * as cdiService from "../../services/cdiService";
import Select from "react-select";
import { useNavigate, useParams } from "react-router-dom";

export default function ExpensesUpdate() {
  const navigate = useNavigate();
  const id = useParams();
  console.log("PARAMS", id.id);

  const [record, setRecord] = useState([]);
  const [expense, setExpense] = useState("");
  const [amount] = useState("");
  const [description] = useState("");

  const loadExpenseInfo = () => {
    cdiService.loadExpense(id.id).then((res) => {
      setData(res.data);
    });
  };

  useEffect(() => {
    loadExpenseInfo();
  }, []);

  const expensesTypes = [
    record.map((expenseType) => {
      return {
        value: expenseType.id,
        label: expenseType.name,
      };
    }),
  ];

  const handleSelectChange = (value) => {
    setExpense(value);
  };

  const ListExpenses = () => {
    cdiService.allExpenseType().then((res) => {
      console.log("EXPENSE TYPES ", res.data);
      setRecord(res.data);
    });
  };

  useEffect(() => {
    ListExpenses();
  }, []);

  const [data, setData] = useState({
    expense: expense,
    amount,
    description,
  });

  console.log("EXPENSE", data);

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      cdiService
        .updateExpense(id.id, data)
        .then(
          (res) => {
            navigate("/expenses");
          },
          (error) => {
            console.log(error);
          }
        )
        .catch((error) => {
          console.log("ERROR", error.res);
        });
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <div class="min-h-screen flex flex-col flex-auto flex-shrink-0 antialiased bg-white dark:bg-gray-700 text-black dark:text-white">
      <Header />
      <SideBar2 />
      <div className="h-full ml-14 mt-14 mb-10 md:ml-64">
        <div className="grid grid-cols-1 lg:grid-cols-1 p-4 gap-2">
          <div className="bg-white rounded shadow-lg p-4 px-4 md:p-8 mb-6">
            <div className="grid gap-4 gap-y-2 text-sm grid-cols-1 lg:grid-cols-3 mt-10">
              <div className="text-gray-600">
                <p className="font-medium text-lg">Formulaire des depenses</p>
                <p>Please fill out all the fields.</p>
              </div>
              <div className="lg:col-span-2">
                <form onSubmit={handleSubmit}>
                  <div className="grid gap-4 gap-y-2 text-sm grid-cols-1 md:grid-cols-6">
                    <div className="md:col-span-3">
                      <label for="Status">Le type de depense</label>
                      <Select
                        placeholder="Chosissez le type de depense"
                        options={expensesTypes[0]}
                        value={data.expense}
                        onChange={(handleSelectChange) =>
                          setData({
                            ...data,
                            expense: handleSelectChange.value,
                          })
                        }
                        noOptionsMessage={() => "Depense introuvable..."}
                        styles={{
                          placeholder: (baseStyles, state) => ({
                            ...baseStyles,
                            color: "red",
                          }),
                          dropdownIndicator: () => ({
                            color: "red",
                          }),
                        }}
                      />
                    </div>

                    <div className="md:col-span-3 -mt-1">
                      <label for="Amount">Le Montant</label>
                      <input
                        type="number"
                        required
                        value={data.amount}
                        onChange={(e) =>
                          setData({
                            ...data,
                            amount: e.target.value,
                          })
                        }
                        className=" flex items-center h-10 border border-black mt-1 rounded px-4 w-full"
                        placeholder="Montant du de la depanse"
                      />
                    </div>
                    <div className="md:col-span-3 -mt-1">
                      <label for="commentaire">Commentaire / Description</label>
                      <textarea
                        rows={2}
                        value={data.description}
                        onChange={(e) =>
                          setData({
                            ...data,
                            description: e.target.value,
                          })
                        }
                        className=" flex items-center h-10 border border-black mt-1 rounded px-4 w-full"
                        placeholder="Montant du de la depanse"
                      />
                    </div> 
                    <button className="bg-white text-black font-bold py-2 px-4 rounded w-full">
                      Mettre à jour
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

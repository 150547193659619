import React, { useEffect, useState } from "react";
import * as cdiService from "../../services/cdiService";

import Select from "react-select";
import { Link, useNavigate } from "react-router-dom";

import { Tab } from "@headlessui/react";
import Header from "../../components/Header";
import SideBar2 from "../../components/SideBar2";
import { MdSick } from "react-icons/md";
import DataTable from "react-data-table-component";

export default function NewUser() {
  const [record, setRecord] = useState([]);
  const [filterrecords, setFilterRecords] = useState([]);
  const columns = [
    {
      name: "#",
      selector: (row) => row.id,
      sortable: true,
    },
    {
      name: " Prénoms & Nom",
      selector: (row) => row.firstname + " " + row.lastname,
      sortable: true,
    },
    {
      name: "Assurance",
      selector: (row) => row.insurance,
      sortable: true,
    },
    {
      name: "Assigner",
      cell: (row) => (
        <Link Link to={`/user/create-patient-user/${row.id}`}>
          <MdSick size={20} className="text-green-700" />
        </Link>
      ),
    },
  ];

  const allClients = () => {
    cdiService.patientList().then((res) => {
      setRecord(res.data);
      setFilterRecords(res.data);
    });
  };

  useEffect(() => {
    allClients();
  }, []);

  const search = (event) => {
    const newData = filterrecords.filter(
      (row) =>
        (row.firstname + " " + row.lastname)
          .toLowerCase()
          .includes(event.target.value.toLowerCase()) ||
        row.insurance
          .toLowerCase()
          .includes(event.target.value.toLowerCase()) ||
        row.id.toString().includes(event.target.value.toLowerCase())
    );
    setRecord(newData);
  };

  const tableHeaderstyle = {
    headCells: {
      style: {
        fontWeight: "bold",
        fontSize: "14px",
        backgroundColor: "black",
        color: "white",
      },
    },
  };

  const navigate = useNavigate();

  // const [username, setUsername] = useState("");
  const [firstname, setFirstname] = useState("");
  const [lastname, setLastname] = useState("");
  const [email, setEmail] = useState("");
  const [telephone, setTelephone] = useState("");
  const [password, setPassword] = useState("");
  const [gender, setGender] = useState("MALE");

  const options = [
    { value: "MALE", label: "Male" },
    { value: "FEMALE", label: "Femele" },
    { value: "OTHER", label: "Autre" },
  ];

  const handleSelectChange = ({ value }) => {
    console.log("SELECTED GENDER ", value);
    setGender(value);
  };

  const data = {
    firstname,
    lastname,
    email,
    password,
    telephone,
    gender,
  };

  console.log("DATA + ", data);

  const CreateAdminUser = async (e) => {
    e.preventDefault();
    try {
      cdiService
        .signupAdmin(data)
        .then(
          (res) => {
            console.log("CHECKING DATA", res.data);
            navigate("/user");
          },
          (error) => {
            console.log(error);
          }
        )
        .catch((error) => {
          console.log("ERROR", error.res);
        });
    } catch (err) {
      console.log(err);
    }
  };

  const CreateDoctorUser = async (e) => {
    e.preventDefault();
    try {
      cdiService
        .signupDoctor(data)
        .then(
          (res) => {
            console.log("CHECKING DATA", res.data);
            navigate("/user");
          },
          (error) => {
            console.log(error);
          }
        )
        .catch((error) => {
          console.log("ERROR", error.res);
        });
    } catch (err) {
      console.log(err);
    }
  };

  
  const CreateCashierUser = async (e) => {
    e.preventDefault();
    try {
      cdiService
        .signupCahier(data)
        .then(
          (res) => {
            console.log("CHECKING DATA", res.data);
            navigate("/user");
          },
          (error) => {
            console.log(error);
          }
        )
        .catch((error) => {
          console.log("ERROR", error.res);
        });
    } catch (err) {
      console.log(err);
    }
  };


  const CreateAccountantUser = async (e) => {
    e.preventDefault();
    try {
      cdiService
        .signupAccountant(data)
        .then(
          (res) => {
            console.log("CHECKING DATA", res.data);
            navigate("/user");
          },
          (error) => {
            console.log(error);
          }
        )
        .catch((error) => {
          console.log("ERROR", error.res);
        });
    } catch (err) {
      console.log(err);
    }
  };

  


  return (
    <div class="min-h-screen flex flex-col flex-auto flex-shrink-0 antialiased bg-white dark:bg-gray-700 text-black dark:text-white">
      <Header />
      <SideBar2 />
      <div className="h-full ml-14 mt-14 mb-10 md:ml-64">
        <div className="grid grid-cols-1 lg:grid-cols-1 p-4 gap-2">
          <div className="bg-white rounded shadow-lg p-4 px-4 md:p-8 mb-6">
            <div className="mt-10">
              <Tab.Group>
                <Tab.List>
                  <Tab className="mr-4 ml-4 pr-4"> ADMIN</Tab>
                  <Tab className="mr-4 ml-4 pr-4"> DOCTEUR </Tab>
                  <Tab className="mr-4 ml-4 pr-4"> RECEPTIONNISTE</Tab>
                  <Tab className="mr-4 ml-4 pr-4"> COMPTABLE</Tab>
                  <Tab className="mr-4 ml-4 pr-4"> PATIENT</Tab>
                </Tab.List>
                <Tab.Panels>
                  {/* Admin creation */}
                  <Tab.Panel>
                    <div className="container max-w-screen-lg mx-auto">
                      <div>
                        <div className="bg-white rounded shadow-lg p-4 px-4 md:p-8 mb-6">
                          <div className="grid gap-4 gap-y-2 text-sm grid-cols-1 lg:grid-cols-3">
                            <div className="text-gray-600">
                              <p className="font-medium text-lg">
                                Information Personnelles de l'Administrateur
                              </p>
                              <p>Remplissez ce formulaire, s'il vout plait.</p>
                            </div>

                            <div className="lg:col-span-2">
                              <form onSubmit={CreateAdminUser}>
                                <div className="grid gap-4 gap-y-2 text-sm grid-cols-1 md:grid-cols-6">
                                  <div className="md:col-span-3">
                                    <label for="Status"> Sex</label>
                                    <Select
                                      required
                                      placeholder="Assignez le sex"
                                      options={options}
                                      onChange={handleSelectChange}
                                      isSearchable
                                      autoFocus
                                      noOptionsMessage={() =>
                                        "No gender found..."
                                      }
                                      styles={{
                                        placeholder: (baseStyles, state) => ({
                                          ...baseStyles,
                                          color: "red",
                                        }),
                                        dropdownIndicator: () => ({
                                          color: "red",
                                        }),
                                      }}
                                    />
                                  </div>
                                  <div className="md:col-span-3">
                                    <label for="address">
                                      <span className="my-4">Prénoms</span>
                                    </label>
                                    <input
                                      type="text"
                                      name="firstname"
                                      value={firstname}
                                      onChange={(e) =>
                                        setFirstname(e.target.value)
                                      }
                                      className="h-10 border  rounded px-4 w-full bg-gray-50 -mt-1 "
                                      placeholder=""
                                      required
                                    />
                                  </div>

                                  <div className="md:col-span-3">
                                    <label for="city">Nom</label>
                                    <input
                                      type="text"
                                      required
                                      name="lastname"
                                      value={lastname}
                                      onChange={(e) =>
                                        setLastname(e.target.value)
                                      }
                                      className="h-10 border mt-1 rounded px-4 w-full bg-gray-50"
                                      placeholder=""
                                    />
                                  </div>

                                  <div className="md:col-span-3">
                                    <label for="address">Email</label>
                                    <input
                                      type="email"
                                      name="email"
                                      required
                                      value={email}
                                      onChange={(e) => setEmail(e.target.value)}
                                      className="h-10 border mt-1 rounded px-4 w-full bg-gray-50"
                                      placeholder=""
                                    />
                                  </div>

                                  <div className="md:col-span-3">
                                    <label for="address">N° Téléphone</label>
                                    <input
                                      type="text"
                                      name="telephone"
                                      required
                                      value={telephone}
                                      onChange={(e) =>
                                        setTelephone(e.target.value)
                                      }
                                      className="h-10 border mt-1 rounded px-4 w-full bg-gray-50"
                                      placeholder="N° de téléphone"
                                    />
                                  </div>

                                  <div className="md:col-span-3">
                                    <label for="password">Mot de passe</label>
                                    <input
                                      type="password"
                                      name="password"
                                      required
                                      value={password}
                                      onChange={(e) =>
                                        setPassword(e.target.value)
                                      }
                                      className="h-10 border mt-1 rounded px-4 w-full bg-gray-50"
                                      placeholder="Mot de passe"
                                    />
                                  </div>
                                  <div className="md:col-span-3 mt-6">
                                    <button className="bg-white hover:bg-green-700 text-black font-bold py-2 px-4 rounded w-full">
                                      Sauvegarder
                                    </button>
                                  </div>
                                </div>
                              </form>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </Tab.Panel>
                  {/* Doctor creation */}
                  <Tab.Panel>
                    <div className="container max-w-screen-lg mx-auto">
                      <div>
                        <div className="bg-white rounded shadow-lg p-4 px-4 md:p-8 mb-6">
                          <div className="grid gap-4 gap-y-2 text-sm grid-cols-1 lg:grid-cols-3">
                            <div className="text-gray-600">
                              <p className="font-medium text-lg">
                                Information Personnelles du Docteur
                              </p>
                              <p>Remplissez ce formulaire, s'il vout plait.</p>
                            </div>

                            <div className="lg:col-span-2">
                              <form onSubmit={CreateDoctorUser}>
                                <div className="grid gap-4 gap-y-2 text-sm grid-cols-1 md:grid-cols-6">
                                  <div className="md:col-span-3">
                                    <label for="Status"> Sex</label>
                                    <Select
                                      required
                                      placeholder="Assignez le sex"
                                      options={options}
                                      onChange={handleSelectChange}
                                      isSearchable
                                      autoFocus
                                      noOptionsMessage={() =>
                                        "No gender found..."
                                      }
                                      styles={{
                                        placeholder: (baseStyles, state) => ({
                                          ...baseStyles,
                                          color: "red",
                                        }),
                                        dropdownIndicator: () => ({
                                          color: "red",
                                        }),
                                      }}
                                    />
                                  </div>
                                  <div className="md:col-span-3">
                                    <label for="address">Prénoms</label>
                                    <input
                                      type="text"
                                      name="firstname"
                                      value={firstname}
                                      onChange={(e) =>
                                        setFirstname(e.target.value)
                                      }
                                      className="h-10 border mt-1 rounded px-4 w-full bg-gray-50"
                                      placeholder=""
                                      required
                                    />
                                  </div>

                                  <div className="md:col-span-3">
                                    <label for="city">Nom</label>
                                    <input
                                      type="text"
                                      required
                                      name="lastname"
                                      value={lastname}
                                      onChange={(e) =>
                                        setLastname(e.target.value)
                                      }
                                      className="h-10 border mt-1 rounded px-4 w-full bg-gray-50"
                                      placeholder=""
                                    />
                                  </div>

                                  <div className="md:col-span-3">
                                    <label for="address">Email</label>
                                    <input
                                      type="email"
                                      name="email"
                                      required
                                      value={email}
                                      onChange={(e) => setEmail(e.target.value)}
                                      className="h-10 border mt-1 rounded px-4 w-full bg-gray-50"
                                      placeholder=""
                                    />
                                  </div>
                                  <div className="md:col-span-3">
                                    <label for="address">N° Téléphone</label>
                                    <input
                                      type="text"
                                      name="telephone"
                                      required
                                      value={telephone}
                                      onChange={(e) =>
                                        setTelephone(e.target.value)
                                      }
                                      className="h-10 border mt-1 rounded px-4 w-full bg-gray-50"
                                      placeholder="N° de téléphone"
                                    />
                                  </div>

                                  <div className="md:col-span-3">
                                    <label for="password">Mot de passe</label>
                                    <input
                                      type="password"
                                      name="password"
                                      required
                                      value={password}
                                      onChange={(e) =>
                                        setPassword(e.target.value)
                                      }
                                      className="h-10 border mt-1 rounded px-4 w-full bg-gray-50"
                                      placeholder="Mot de passe"
                                    />
                                  </div>
                                  <div className="md:col-span-3 mt-6">
                                    <button className="bg-white hover:bg-green-700 text-black font-bold py-2 px-4 rounded w-full">
                                      Sauvegarder
                                    </button>
                                  </div>
                                </div>
                              </form>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </Tab.Panel>
                  {/* Cashier creation */}
                  <Tab.Panel>
                    <div className="container max-w-screen-lg mx-auto">
                      <div>
                        <div className="bg-white rounded shadow-lg p-4 px-4 md:p-8 mb-6">
                          <div className="grid gap-4 gap-y-2 text-sm grid-cols-1 lg:grid-cols-3">
                            <div className="text-gray-600">
                              <p className="font-medium text-lg">
                                Information Personnelles du ou de la
                                receptionniste
                              </p>
                              <p>Remplissez ce formulaire, s'il vout plait.</p>
                            </div>
                            <div className="lg:col-span-2">
                              <form onSubmit={CreateCashierUser}>
                                <div className="grid gap-4 gap-y-2 text-sm grid-cols-1 md:grid-cols-6">
                                  <div className="md:col-span-3">
                                    <label for="Status"> Sex</label>
                                    <Select
                                      required
                                      placeholder="Assignez le sex"
                                      options={options}
                                      onChange={handleSelectChange}
                                      isSearchable
                                      autoFocus
                                      noOptionsMessage={() =>
                                        "No gender found..."
                                      }
                                      styles={{
                                        placeholder: (baseStyles, state) => ({
                                          ...baseStyles,
                                          color: "red",
                                        }),
                                        dropdownIndicator: () => ({
                                          color: "red",
                                        }),
                                      }}
                                    />
                                  </div>
                                  <div className="md:col-span-3">
                                    <label for="address">Prénoms</label>
                                    <input
                                      type="text"
                                      name="firstname"
                                      value={firstname}
                                      onChange={(e) =>
                                        setFirstname(e.target.value)
                                      }
                                      className="h-10 border mt-1 rounded px-4 w-full bg-gray-50"
                                      placeholder=""
                                      required
                                    />
                                  </div>

                                  <div className="md:col-span-3">
                                    <label for="city">Nom</label>
                                    <input
                                      type="text"
                                      required
                                      name="lastname"
                                      value={lastname}
                                      onChange={(e) =>
                                        setLastname(e.target.value)
                                      }
                                      className="h-10 border mt-1 rounded px-4 w-full bg-gray-50"
                                      placeholder=""
                                    />
                                  </div>

                                  <div className="md:col-span-3">
                                    <label for="address">Email</label>
                                    <input
                                      type="email"
                                      name="email"
                                      required
                                      value={email}
                                      onChange={(e) => setEmail(e.target.value)}
                                      className="h-10 border mt-1 rounded px-4 w-full bg-gray-50"
                                      placeholder=""
                                    />
                                  </div>
                                  <div className="md:col-span-3">
                                    <label for="address">N° Téléphone</label>
                                    <input
                                      type="text"
                                      name="telephone"
                                      required
                                      value={telephone}
                                      onChange={(e) =>
                                        setTelephone(e.target.value)
                                      }
                                      className="h-10 border mt-1 rounded px-4 w-full bg-gray-50"
                                      placeholder="N° de téléphone"
                                    />
                                  </div>

                                  <div className="md:col-span-3">
                                    <label for="password">Mot de passe</label>
                                    <input
                                      type="password"
                                      name="password"
                                      required
                                      value={password}
                                      onChange={(e) =>
                                        setPassword(e.target.value)
                                      }
                                      className="h-10 border mt-1 rounded px-4 w-full bg-gray-50"
                                      placeholder="Mot de passe"
                                    />
                                  </div>
                                  <div className="md:col-span-3 mt-6">
                                    <button className="bg-white hover:bg-green-700 text-black font-bold py-2 px-4 rounded w-full">
                                      Sauvegarder
                                    </button>
                                  </div>
                                </div>
                              </form>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </Tab.Panel>
                  {/* Accountant creation */}
                  <Tab.Panel>
                    <div className="container max-w-screen-lg mx-auto">
                      <div>
                        <div className="bg-white rounded shadow-lg p-4 px-4 md:p-8 mb-6">
                          <div className="grid gap-4 gap-y-2 text-sm grid-cols-1 lg:grid-cols-3">
                            <div className="text-gray-600">
                              <p className="font-medium text-lg">
                                Information Personnelles du ou de la Comptable
                              </p>
                              <p>Remplissez ce formulaire, s'il vout plait.</p>
                            </div>

                            <div className="lg:col-span-2">
                              <form onSubmit={CreateAccountantUser}>
                                <div className="grid gap-4 gap-y-2 text-sm grid-cols-1 md:grid-cols-6">
                                  <div className="md:col-span-3">
                                    <label for="Status"> Sex</label>
                                    <Select
                                      required
                                      placeholder="Assignez le sex"
                                      options={options}
                                      onChange={handleSelectChange}
                                      isSearchable
                                      autoFocus
                                      noOptionsMessage={() =>
                                        "No gender found..."
                                      }
                                      styles={{
                                        placeholder: (baseStyles, state) => ({
                                          ...baseStyles,
                                          color: "red",
                                        }),
                                        dropdownIndicator: () => ({
                                          color: "red",
                                        }),
                                      }}
                                    />
                                  </div>
                                  <div className="md:col-span-3">
                                    <label for="address">Prénoms</label>
                                    <input
                                      type="text"
                                      name="firstname"
                                      value={firstname}
                                      onChange={(e) =>
                                        setFirstname(e.target.value)
                                      }
                                      className="h-10 border mt-1 rounded px-4 w-full bg-gray-50"
                                      placeholder=""
                                      required
                                    />
                                  </div>

                                  <div className="md:col-span-3">
                                    <label for="city">Nom</label>
                                    <input
                                      type="text"
                                      required
                                      name="lastname"
                                      value={lastname}
                                      onChange={(e) =>
                                        setLastname(e.target.value)
                                      }
                                      className="h-10 border mt-1 rounded px-4 w-full bg-gray-50"
                                      placeholder=""
                                    />
                                  </div>

                                  <div className="md:col-span-3">
                                    <label for="address">Email</label>
                                    <input
                                      type="email"
                                      name="email"
                                      required
                                      value={email}
                                      onChange={(e) => setEmail(e.target.value)}
                                      className="h-10 border mt-1 rounded px-4 w-full bg-gray-50"
                                      placeholder=""
                                    />
                                  </div>
                                  <div className="md:col-span-3">
                                    <label for="address">N° Téléphone</label>
                                    <input
                                      type="text"
                                      name="telephone"
                                      required
                                      value={telephone}
                                      onChange={(e) =>
                                        setTelephone(e.target.value)
                                      }
                                      className="h-10 border mt-1 rounded px-4 w-full bg-gray-50"
                                      placeholder="N° de téléphone"
                                    />
                                  </div>

                                  <div className="md:col-span-3">
                                    <label for="password">Mot de passe</label>
                                    <input
                                      type="password"
                                      name="password"
                                      required
                                      value={password}
                                      onChange={(e) =>
                                        setPassword(e.target.value)
                                      }
                                      className="h-10 border mt-1 rounded px-4 w-full bg-gray-50"
                                      placeholder="Mot de passe"
                                    />
                                  </div>
                                  <div className="md:col-span-3 mt-6">
                                    <button className="bg-white hover:bg-green-700 text-black font-bold py-2 px-4 rounded w-full">
                                      Sauvegarder
                                    </button>
                                  </div>
                                </div>
                              </form>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </Tab.Panel>
                  {/* Patient creation */}
                  <Tab.Panel>
                    <div className="container max-w-screen-lg mx-auto">
                      <div>
                        <div className="bg-white rounded shadow-lg p-4 px-4 md:p-8 mb-6">
                          <div className="grid gap-1 gap-y-2 text-sm grid-cols-1 lg:grid-cols-3">
                            <div className="text-gray-600">
                              <p className="font-medium text-lg">
                                CHOISIR LE PATIENT
                              </p>
                            </div>

                            <div className="lg:col-span-2">
                              <DataTable
                                className="-mt-2"
                                columns={columns}
                                data={record}
                                pagination
                                fixedHeader
                                fixedHeaderScrollHeight="450px"
                                selectableRowsHighlight
                                highlightOnHover
                                customStyles={tableHeaderstyle}
                                subHeader
                                subHeaderComponent={
                                  <input
                                    type="text"
                                    placeholder="search here..."
                                    className="w-[70%] h-[35px] float-left  rounded-md text-black 
            border border-[#0b1011] py-1 px-4 -mb-1 -ml-6 -mt-4 bg-slate-50"
                                    onChange={search}
                                  />
                                }
                                subHeaderAlign="left"
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </Tab.Panel>
                </Tab.Panels>
              </Tab.Group>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

import React, { useState } from "react";
import Header from "../../components/Header";
import SideBar2 from "../../components/SideBar2";
import { useNavigate } from "react-router-dom";
import * as cdiService from "../../services/cdiService";
import * as authService from "../../services/authService";
import jwt_decode from "jwt-decode";

export default function SendMessage() {
  const [currentUser] = useState(authService.getCurrentUser());
  const [user] = useState(jwt_decode(currentUser.access));
  const navigate = useNavigate();
  const [subject, setSubject] = useState("");
  const [message, setMessage] = useState("");

  const data = {
    user: user.id,
    subject,
    message,
  };


  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      cdiService
        .sendMessage(data)
        .then(
          (res) => {
            navigate("/appointment");
          },
          (error) => {
            console.log(error);
          }
        )
        .catch((error) => {
          console.log("ERROR", error.res);
        });
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <div>
      <div class="min-h-screen flex flex-col flex-auto flex-shrink-0 antialiased bg-white dark:bg-gray-700 text-black dark:text-white">
        <Header />
        <SideBar2 />
        <div className="h-full ml-14 mt-14 mb-10 md:ml-64">
          <div className="grid grid-cols-1 lg:grid-cols-1 p-4 gap-2">
            <div className="bg-white rounded shadow-lg p-4 px-4 md:p-8 mb-6">
              <div className="grid gap-4 gap-y-2 text-sm grid-cols-1 lg:grid-cols-3 mt-10">
                <div className="text-gray-600">
                  <p className="font-medium text-lg">Formulaire de message</p>
                  <p>Please fill out all the fields.</p>
                </div>
                <div className="lg:col-span-2">
                  <form
                  onSubmit={handleSubmit}
                  >
                    <div className="grid gap-4 gap-y-2 text-sm grid-cols-1 md:grid-cols-6">
                      <div className="md:col-span-3 -mt-1">
                        <label for="topic">Sujet</label>
                        <input
                          type="text"
                          required
                          onChange={(e) => setSubject(e.target.value)}
                          className=" flex items-center h-10 border border-black mt-1 rounded px-4 w-full"
                          placeholder="Suject..."
                        />
                      </div>
                      <div className="md:col-span-3"></div>

                      <div className="md:col-span-3 -mt-1">
                        <label for="message">Message</label>
                        <textarea
                          rows="4"
                          onChange={(e) => setMessage(e.target.value)}
                          class="block p-2.5 w-full text-sm text-gray-900 bg-gray-50 rounded-lg border border-black dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white"
                          placeholder="Votre message ici"
                        ></textarea>
                      </div>

                      <div className="md:col-span-3"></div>

                      <div className="md:col-span-3">
                        <button class="px-3 py-2 text-xs font-medium text-center inline-flex items-center text-white bg-black rounded-lg  focus:ring-4 focus:outline-none ">
                          <svg
                            class="w-3 h-3 text-white me-2"
                            aria-hidden="true"
                            xmlns="http://www.w3.org/2000/svg"
                            fill="currentColor"
                            viewBox="0 0 20 16"
                          >
                            <path d="m10.036 8.278 9.258-7.79A1.979 1.979 0 0 0 18 0H2A1.987 1.987 0 0 0 .641.541l9.395 7.737Z" />
                            <path d="M11.241 9.817c-.36.275-.801.425-1.255.427-.428 0-.845-.138-1.187-.395L0 2.6V14a2 2 0 0 0 2 2h16a2 2 0 0 0 2-2V2.5l-8.759 7.317Z" />
                          </svg>
                          Envoyer
                        </button>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

import React, { useEffect, useState } from "react";
import * as cdiService from "../../services/cdiService";
import { useNavigate, useParams } from "react-router-dom";
import Header from "../../components/Header";
import SideBar2 from "../../components/SideBar2";

export default function UpdateInsurances() {
  const navigate = useNavigate();

  const id = useParams();
  console.log("PARAMS", id.id);
  const [insurancename] = useState("");
  const [address] = useState("");
  const [telephone] = useState("");
  const [email] = useState("");

  const loadInsuranceInfo = () => {
    cdiService.loadSingleInsurance(id.id).then((res) => {
      // console.log("DATA INSURANCE", res.data)
      setData(res.data);
    });
  };

  useEffect(() => {
    loadInsuranceInfo();
  }, []);

  const [data, setData] = useState({
    insurancename,
    address,
    email,
    telephone,
  });

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      cdiService
        .updateInsurance(id.id, data)
        .then(
          (res) => {
            console.log("CHECKING DATA", res.data);
            navigate("/insurance");
          },
          (error) => {
            console.log(error);
          }
        )
        .catch((error) => {
          console.log("ERROR", error.res);
        });
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <div class="min-h-screen flex flex-col flex-auto flex-shrink-0 antialiased bg-white dark:bg-gray-700 text-black dark:text-white">
      <Header />
      <SideBar2 />
      <div className="h-full ml-14 mt-14 mb-10 md:ml-64">
        <div className="grid grid-cols-1 lg:grid-cols-1 p-4 gap-2">
          <div className="bg-white rounded shadow-lg p-4 px-4 md:p-8 mb-6">
            <div className="grid gap-4 gap-y-2 text-sm grid-cols-1 lg:grid-cols-3">
              <div className="text-gray-600">
                <p className="font-medium text-lg">
                  Information Personnelles de l'assurance
                </p>
                <p>Remplissez ce formulaire, s'il vout plait.</p>
              </div>

              <div className="lg:col-span-2">
                <form onSubmit={handleSubmit}>
                  <div className="grid gap-4 gap-y-2 text-sm grid-cols-1 md:grid-cols-6">
                    <div className="md:col-span-3">
                      <label for="address">Assurance</label>
                      <input
                        type="text"
                        disabled
                        name="insurancename"
                        value={data.insurancename}
                        onChange={(e) =>
                          setData({
                            ...data,
                            insurancename: e.target.value,
                          })
                        }
                        className="h-10 border mt-1 rounded px-4 w-full bg-gray-50"
                        placeholder=""
                        required
                      />
                    </div>
                    <div className="md:col-span-3">
                      <label for="email">Email</label>
                      <input
                        type="email"
                        name="email"
                        value={data.email}
                        onChange={(e) =>
                          setData({
                            ...data,
                            email: e.target.value,
                          })
                        }
                        className="h-10 border mt-1 rounded px-4 w-full bg-gray-50"
                        placeholder=""
                      />
                    </div>
                    <div className="md:col-span-6">
                      <label for="city">Adresse</label>
                      <textarea
                        type="text"
                        required
                        rows={4}
                        name="address"
                        value={data.address}
                        onChange={(e) =>
                          setData({
                            ...data,
                            address: e.target.value,
                          })
                        }
                        class="block p-2.5 w-full text-sm text-gray-900 bg-gray-50 rounded-sm border border-gray-300 focus:ring-black focus:border-black dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-black dark:focus:border-black"
                        placeholder="Adresse de l'assurance"
                      ></textarea>
                    </div>

                    <div className="md:col-span-3">
                      <label for="telephone">N° Téléphone</label>
                      <input
                        type="text"
                        name="telephone"
                        required
                        value={data.telephone}
                        onChange={(e) =>
                          setData({
                            ...data,
                            telephone: e.target.value,
                          })
                        }
                        className="h-10 border mt-1 rounded px-4 w-full bg-gray-50"
                        placeholder=""
                      />
                    </div>
                    <div className="md:col-span-3">
                    <button className="bg-white text-black font-bold mt-8 ml-4 px-4 rounded w-full">
                      Sauvegarder
                    </button>
                    </div>
                  </div>

              
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

import React, { useEffect, useState } from "react";
import DashboardData from "./DashboardData";
import SideBar2 from "../components/SideBar2";
import Header from "../components/Header";
import * as cdiService from "../services/cdiService";

import * as authService from "../services/authService";
import jwt_decode from "jwt-decode";

export default function Dashboard() {
  const [currentUser] = useState(authService.getCurrentUser());
  const [user] = useState(jwt_decode(currentUser.access));

  const [patientrecord, setPatientRecord] = useState([]);
  const [insurances, setInsurances] = useState([]);
  const [upcomingrvdrecord, setUpcomingRDVRecord] = useState([]);
  const [treatmentrecord, setTreatmentRecord] = useState([]);

  const allPatients = () => {
    cdiService.patientList().then((res) => {
      setPatientRecord(res.data.length);
    });
  };

  const ListInsurances = () => {
    cdiService.insuranceList().then((res) => {
      let insurances = res.data.filter((x) => x.insurancename !== "NA").length;
      console.log("TOTAL INSURED", insurances);
      setInsurances(insurances);
    });
  };

  const allAppointments = () => {
    cdiService.allAppointments().then((res) => {
      let allrdv = res.data.filter((x) => x.status !== "terminé").length;
      console.log("APT", allrdv);
      setUpcomingRDVRecord(allrdv);
    });
  };

  const allTreatments = () => {
    cdiService.allTreatments().then((res) => {
      console.log("GETTING", res.data);
      let treatments = res.data;
      setTreatmentRecord(treatments);
    });
  };

  useEffect(() => {
    allPatients();
    allAppointments();
    allTreatments();
    ListInsurances();
  }, []);

  console.log("TREATMENTS RECORD:", treatmentrecord);

  let total = 0;
  let totalpatient = 0;
  let totalinsurance = 0;
  treatmentrecord.forEach((treatment) => {
    total += treatment.treatmentamount;
    totalpatient += treatment.partpatient;
    totalinsurance += treatment.partinsurance;
  });

  console.log("TOTAL = ", total);

  return (
    <div class="min-h-screen flex flex-col flex-auto flex-shrink-0 antialiased bg-white dark:bg-gray-700 text-black dark:text-white">
      <Header />
      <SideBar2 />
      <div class="h-full ml-14 mt-14 mb-10 md:ml-64">
        {/* <!-- Statistics Cards --> */}
        <div class="grid grid-cols-1 sm:grid-cols-3 lg:grid-cols-3 p-4 gap-4">
       
          {currentUser ? (
            user.role === "ADMIN" ? (
              <>
                <div class="bg-black dark:bg-gray-800 shadow-lg rounded-md flex items-center justify-between p-3 border-b-4 border-green-600 dark:border-gray-600 text-white font-medium group">
                  <div class="flex justify-center items-center w-14 h-14 bg-white rounded-full transition-all duration-300 transform group-hover:rotate-12">
                    <svg
                      width="30"
                      height="30"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke="currentColor"
                      class="stroke-current text-green-600 dark:text-gray-800 transform transition-transform duration-500 ease-in-out"
                    >
                      <path
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        stroke-width="2"
                        d="M17 20h5v-2a3 3 0 00-5.356-1.857M17 20H7m10 0v-2c0-.656-.126-1.283-.356-1.857M7 20H2v-2a3 3 0 015.356-1.857M7 20v-2c0-.656.126-1.283.356-1.857m0 0a5.002 5.002 0 019.288 0M15 7a3 3 0 11-6 0 3 3 0 016 0zm6 3a2 2 0 11-4 0 2 2 0 014 0zM7 10a2 2 0 11-4 0 2 2 0 014 0z"
                      ></path>
                    </svg>
                  </div>
                  <div class="text-right">
                    <p class="text-2xl">{patientrecord}</p>
                    <p>Patients Enregistrés</p>
                  </div>
                </div>
                <div class="bg-black dark:bg-gray-800 shadow-lg rounded-md flex items-center justify-between p-3 border-b-4 border-green-600 dark:border-gray-600 text-white font-medium group">
                  <div class="flex justify-center items-center w-14 h-14 bg-white rounded-full transition-all duration-300 transform group-hover:rotate-12">
                    <svg
                      width="30"
                      height="30"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke="currentColor"
                      class="stroke-current text-green-800 dark:text-gray-800 transform transition-transform duration-500 ease-in-out"
                    >
                      <path
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        stroke-width="2"
                        d="M16 11V7a4 4 0 00-8 0v4M5 9h14l1 12H4L5 9z"
                      ></path>
                    </svg>
                  </div>
                  <div class="text-right">
                    <p class="text-2xl">{insurances}</p>
                    <p>Assurances Partners</p>
                  </div>
                </div>
                <div class="bg-black dark:bg-gray-800 shadow-lg rounded-md flex items-center justify-between p-3 border-b-4 border-green-600 dark:border-gray-600 text-white font-medium group">
                  <div class="flex justify-center items-center w-14 h-14 bg-white rounded-full transition-all duration-300 transform group-hover:rotate-12">
                    <svg
                      width="30"
                      height="30"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke="currentColor"
                      class="stroke-current text-green-800 dark:text-gray-800 transform transition-transform duration-500 ease-in-out"
                    >
                      <path
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        stroke-width="2"
                        d="M13 7h8m0 0v8m0-8l-8 8-4-4-6 6"
                      ></path>
                    </svg>
                  </div>
                  <div class="text-right">
                    <p class="text-2xl">{upcomingrvdrecord}</p>
                    <p>Rendezvous en attente</p>
                  </div>
                </div>
                <div class="bg-black dark:bg-gray-800 shadow-lg rounded-md flex items-center justify-between p-3 border-b-4 border-green-600 dark:border-gray-600 text-white font-medium group">
                  <div class="flex justify-center items-center w-14 h-14 bg-white rounded-full transition-all duration-300 transform group-hover:rotate-12">
                    <svg
                      width="30"
                      height="30"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke="currentColor"
                      class="stroke-current text-green-800 dark:text-gray-800 transform transition-transform duration-500 ease-in-out"
                    >
                      <path
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        stroke-width="2"
                        d="M12 8c-1.657 0-3 .895-3 2s1.343 2 3 2 3 .895 3 2-1.343 2-3 2m0-8c1.11 0 2.08.402 2.599 1M12 8V7m0 1v8m0 0v1m0-1c-1.11 0-2.08-.402-2.599-1M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
                      ></path>
                    </svg>
                  </div>
                  <div class="text-right">
                    <p class="text-2xl">{total} FCFA</p>
                    <p>Cout total des traiments</p>
                  </div>
                </div>
                <div class="bg-black dark:bg-gray-800 shadow-lg rounded-md flex items-center justify-between p-3 border-b-4 border-green-600 dark:border-gray-600 text-white font-medium group">
                  <div class="flex justify-center items-center w-14 h-14 bg-white rounded-full transition-all duration-300 transform group-hover:rotate-12">
                    <svg
                      width="30"
                      height="30"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke="currentColor"
                      class="stroke-current text-green-800 dark:text-gray-800 transform transition-transform duration-500 ease-in-out"
                    >
                      <path
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        stroke-width="2"
                        d="M12 8c-1.657 0-3 .895-3 2s1.343 2 3 2 3 .895 3 2-1.343 2-3 2m0-8c1.11 0 2.08.402 2.599 1M12 8V7m0 1v8m0 0v1m0-1c-1.11 0-2.08-.402-2.599-1M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
                      ></path>
                    </svg>
                  </div>
                  <div class="text-right">
                    <p class="text-2xl">{totalinsurance} FCFA</p>
                    <p>Recu des assurance</p>
                  </div>
                </div>
                <div class="bg-black dark:bg-gray-800 shadow-lg rounded-md flex items-center justify-between p-3 border-b-4 border-green-600 dark:border-gray-600 text-white font-medium group">
                  <div class="flex justify-center items-center w-14 h-14 bg-white rounded-full transition-all duration-300 transform group-hover:rotate-12">
                    <svg
                      width="30"
                      height="30"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke="currentColor"
                      class="stroke-current text-green-800 dark:text-gray-800 transform transition-transform duration-500 ease-in-out"
                    >
                      <path
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        stroke-width="2"
                        d="M12 8c-1.657 0-3 .895-3 2s1.343 2 3 2 3 .895 3 2-1.343 2-3 2m0-8c1.11 0 2.08.402 2.599 1M12 8V7m0 1v8m0 0v1m0-1c-1.11 0-2.08-.402-2.599-1M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
                      ></path>
                    </svg>
                  </div>
                  <div class="text-right">
                    <p class="text-2xl">{totalpatient} FCFA</p>
                    <p>Recu des patients</p>
                  </div>
                </div>
              </>
            ) : user.role === "CASHIER" || user.role === "DOCTOR" ? (
              <>
                <div class="bg-black dark:bg-gray-800 shadow-lg rounded-md flex items-center justify-between p-3 border-b-4 border-green-600 dark:border-gray-600 text-white font-medium group">
                  <div class="flex justify-center items-center w-14 h-14 bg-white rounded-full transition-all duration-300 transform group-hover:rotate-12">
                    <svg
                      width="30"
                      height="30"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke="currentColor"
                      class="stroke-current text-green-600 dark:text-gray-800 transform transition-transform duration-500 ease-in-out"
                    >
                      <path
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        stroke-width="2"
                        d="M17 20h5v-2a3 3 0 00-5.356-1.857M17 20H7m10 0v-2c0-.656-.126-1.283-.356-1.857M7 20H2v-2a3 3 0 015.356-1.857M7 20v-2c0-.656.126-1.283.356-1.857m0 0a5.002 5.002 0 019.288 0M15 7a3 3 0 11-6 0 3 3 0 016 0zm6 3a2 2 0 11-4 0 2 2 0 014 0zM7 10a2 2 0 11-4 0 2 2 0 014 0z"
                      ></path>
                    </svg>
                  </div>
                  <div class="text-right">
                    <p class="text-2xl">{patientrecord}</p>
                    <p>Patients Enregistrés</p>
                  </div>
                </div>
                <div class="bg-black dark:bg-gray-800 shadow-lg rounded-md flex items-center justify-between p-3 border-b-4 border-green-600 dark:border-gray-600 text-white font-medium group">
                  <div class="flex justify-center items-center w-14 h-14 bg-white rounded-full transition-all duration-300 transform group-hover:rotate-12">
                    <svg
                      width="30"
                      height="30"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke="currentColor"
                      class="stroke-current text-green-800 dark:text-gray-800 transform transition-transform duration-500 ease-in-out"
                    >
                      <path
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        stroke-width="2"
                        d="M16 11V7a4 4 0 00-8 0v4M5 9h14l1 12H4L5 9z"
                      ></path>
                    </svg>
                  </div>
                  <div class="text-right">
                    <p class="text-2xl">{insurances}</p>
                    <p>Assurances Partners</p>
                  </div>
                </div>
                <div class="bg-black dark:bg-gray-800 shadow-lg rounded-md flex items-center justify-between p-3 border-b-4 border-green-600 dark:border-gray-600 text-white font-medium group">
                  <div class="flex justify-center items-center w-14 h-14 bg-white rounded-full transition-all duration-300 transform group-hover:rotate-12">
                    <svg
                      width="30"
                      height="30"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke="currentColor"
                      class="stroke-current text-green-800 dark:text-gray-800 transform transition-transform duration-500 ease-in-out"
                    >
                      <path
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        stroke-width="2"
                        d="M13 7h8m0 0v8m0-8l-8 8-4-4-6 6"
                      ></path>
                    </svg>
                  </div>
                  <div class="text-right">
                    <p class="text-2xl">{upcomingrvdrecord}</p>
                    <p>Rendezvous en attente</p>
                  </div>
                </div>
              </>
            ) : (
              ""
            )
          ) : (
            ""
          )}
        </div>
        {/* <!-- ./Statistics Cards --> */}

        <div class="grid grid-cols-1 lg:grid-cols-2 p-4 gap-4">
          {/* <!-- Social Traffic --> */}
          <DashboardData />
          {/* <!-- ./Recent Activities --> */}
        </div>
      </div>
    </div>
  );
}

import React, { useEffect, useState } from "react";
import * as cdiService from "../../services/cdiService";
import * as authService from "../../services/authService";
import jwt_decode from "jwt-decode";
import { Link } from "react-router-dom";
import { MdDelete } from "react-icons/md";
import { BsActivity } from "react-icons/bs";
import { AiOutlineEye } from "react-icons/ai";
import DataTable from "react-data-table-component";
import Swal from "sweetalert2";

export default function TreatmentList() {
  const [currentUser] = useState(authService.getCurrentUser());
  const [user] = useState(jwt_decode(currentUser.access));

  const [doctorsTreatmentRecord, setDoctorsTreatmentRecord] = useState([]);
  const [doctorFilterrecords, setDoctorFilterRecords] = useState([]);
  const [adminCashierrecords, setAdminCashierRecords] = useState();
  const [adminCashierfilterrecords, setAdminCashierFilterRecords] = useState();
  const [myTreatments, setMyTreatments] = useState([]);

  const handleDelete = (row) => {
    Swal.fire({
      title: "Es-tu sûr de cette opération?",
      text: "Vous ne pourrez pas annuler cette opération!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Oui, supprime!",
    }).then((result) => {
      if (result.isConfirmed) {
        cdiService
          .deleteTreatment(row)
          .then((res) => {
            allTreatments();
          })
          .catch((e) => console.log("erreur supprimer", e));
        Swal.fire("supprimé!", "Le traitement est supprimé.", "success");
      }
    });
  };

  const columns = [
    {
      name: "#",
      selector: (row) => row.id,
      sortable: true,
    },
    {
      name: "Docteur",
      selector: (row) => row.user.firstname + ' ' +  row.user.lastname,
      sortable: true,
    },
    {
      name: "Interventions",
      selector: (row) => row.interventions,
      sortable: true,
    },
    {
      name: "Montant",
      selector: (row) => row.treatmentamount,
      sortable: true,
    },
    {
      name: "Status",
      selector: (row) => row.treatmentstatus,
      sortable: true,
    },
    {
      name: "Date",
      selector: (row) => row.registered_on.substring(0, 16),
      sortable: true,
    },
    {
      name: "Detail",
      cell: (row) => (
        <Link to={`/treatment/${row.id}`}>
          <AiOutlineEye size={20} className="text-green-700 mx-auto" />
        </Link>
      ),
    },
    {
      name: "Supprimer",
      cell: (row) => (
        <MdDelete
          size={20}
          className="text-red-700"
          onClick={() => handleDelete(row.id)}
        />
      ),
    },

    {
      name: "# Patient",
      selector: (row) => row.patient.id,
      sortable: true,
    },
    {
      name: "Nom Patient",
      selector: (row) => row.patient.firstname + ' ' +  row.patient.lastname,
      sortable: true,
    },
  ];


  const columnspatient = [
    {
      name: "#",
      selector: (row) => row.id,
      sortable: true,
    },
    {
      name: "Docteur",
      selector: (row) => row.user.firstname + ' ' +  row.user.lastname,
      sortable: true,
    },
    {
      name: "Interventions",
      selector: (row) => row.interventions,
      sortable: true,
    },
    {
      name: "Montant",
      selector: (row) => row.treatmentamount,
      sortable: true,
    },
    {
      name: "My part",
      selector: (row) => row.partpatient,
      sortable: true,
    },
    {
      name: "Assurance",
      selector: (row) => row.partinsurance,
      sortable: true,
    },

    {
      name: "Detail",
      cell: (row) => (
        <Link to={`/treatment/${row.id}`}>
          <AiOutlineEye size={20} className="text-green-700 mx-auto" />
        </Link>
      ),
    },
    {
      name: "Status",
      selector: (row) => row.treatmentstatus,
      sortable: true,
    },
    {
      name: "Date",
      selector: (row) => row.registered_on.substring(0, 16),
      sortable: true,
    },

  ];

  const doctorSearch = (event) => {
    const newData = doctorFilterrecords.filter(
      (row) =>
        (row.user.firstname + " " + row.user.lastname)
          .toLowerCase()
          .includes(event.target.value.toLowerCase()) ||
        row.treatmentstatus
          .toLowerCase()
          .includes(event.target.value.toLowerCase()) ||
        row.id.toString().includes(event.target.value.toLowerCase())
        ||
        row.patient.id.toString().includes(event.target.value.toLowerCase())
        ||
        (row.patient.firstname + " " + row.patient.lastname).toLowerCase().includes(event.target.value.toLowerCase())
    );
    setDoctorsTreatmentRecord(newData);
  };

  const adminCashierSearch = (event) => {
    const newData = adminCashierfilterrecords.filter(
      (row) =>
        (row.user.firstname + " " + row.user.lastname)
          .toLowerCase()
          .includes(event.target.value.toLowerCase()) ||
        row.treatmentstatus
          .toLowerCase()
          .includes(event.target.value.toLowerCase()) ||
        row.id.toString().includes(event.target.value.toLowerCase())
        ||
        row.patient.id.toString().includes(event.target.value.toLowerCase())
        ||
        (row.patient.firstname + " " + row.patient.lastname).toLowerCase().includes(event.target.value.toLowerCase())
    );
    setAdminCashierRecords(newData);
  };

  const tableHeaderstyle = {
    headCells: {
      style: {
        fontWeight: "bold",
        fontSize: "14px",
        backgroundColor: "black",
        color: "white",
      },
    },
  };

  const allTreatments = () => {
    cdiService.allTreatments().then((res) => {
      console.log("TREATMENT", res.data)
      let doctorTreatments = res.data.filter(
        (x) => x.user.id === user.id
      );

      let myTreatments = res.data.filter(
        (x) => x.patient.id === user.patient_id
      );
      setMyTreatments(myTreatments);
      setDoctorsTreatmentRecord(doctorTreatments);
      setDoctorFilterRecords(doctorTreatments);
      setAdminCashierRecords(res.data);
      setAdminCashierFilterRecords(res.data);
    });
  };
  //
  console.log("My treatment", myTreatments)
  useEffect(() => {
    allTreatments();
  }, []);

  return (
    <div class="grid grid-cols-1 lg:grid-cols-1 p-4 gap-4">
     
        <div className="md:col-span-2">
          {currentUser ? (
            user.role.toString() === "DOCTOR" ||
            user.role.toString() === "ADMIN" ? (
              <>
                <Link
                   className=" text-white bg-black font-bold uppercase text-sm px-2 py-2  shadow hover:shadow-lg outline-none focus:outline-none mr-1 ml-4 ease-linear transition-all duration-150 rounded-md  w-[200px] h-[34px] -mb-4 float-left "
                  variant="outlined"
                  Link
                  to={"get-patient"}
                >
                  <span className="ml-8">Assignez patient{" "}</span>
                  
                  <BsActivity className="text-white font-extrabold text-2xl -mt-5 mr-2" />
                </Link>
              </>
            ) : (
              ""
            )
          ) : (
            ""
          )}
        </div>
      <div class="grid grid-cols-1 lg:grid-cols-1 p-4 gap-4">
        <div className="md:col-span-10">
          {currentUser ? (
            user.role === "DOCTOR" ? (
              <>
                <DataTable
                  className="-mt-2"
                  columns={columns}
                  data={doctorsTreatmentRecord}
                  pagination
                  fixedHeader
                  fixedHeaderScrollHeight="450px"
                  selectableRowsHighlight
                  highlightOnHover
                  customStyles={tableHeaderstyle}
                  subHeader
                  subHeaderComponent={
                    <input
                      type="text"
                      placeholder="search here..."
                      className="w-[70%] h-[35px] float-left  rounded-md text-black 
                      border border-[#0b1011] py-1 px-4 mb-4 -ml-6  bg-slate-50 mt-0"
                      onChange={doctorSearch}
                    />
                  }
                  subHeaderAlign="left"
                />
              </>
            ) : user.role === "CASHIER" ||
            user.role === "ADMIN" ? (
              <>
                <DataTable
                  className="-mt-2"
                  columns={columns}
                  data={adminCashierrecords}
                  pagination
                  fixedHeader
                  fixedHeaderScrollHeight="450px"
                  selectableRowsHighlight
                  highlightOnHover
                  customStyles={tableHeaderstyle}
                  subHeader
                  subHeaderComponent={
                    <input
                      type="text"
                      placeholder="search here..."
                      className="w-[70%] h-[35px] float-left  rounded-md text-black 
                      border border-[#0b1011] py-1 px-4 mb-4 -ml-6  bg-slate-50 mt-0"
                      onChange={adminCashierSearch}
                    />
                  }
                  subHeaderAlign="left"
                />
              </>
            ) : user.role.toString() === "PATIENT" ? (
              <>
                <DataTable
                  className="-mt-2"
                  columns={columnspatient}
                  data={myTreatments}
                  pagination
                  fixedHeader
                  fixedHeaderScrollHeight="450px"
                  selectableRowsHighlight
                  highlightOnHover
                  customStyles={tableHeaderstyle}
                  subHeader
                
                  subHeaderAlign="left"
                />
              </>
            ) : (
              ""
            )
          ) : (
            ""
          )}
        </div>
      </div>
    </div>
  );
}

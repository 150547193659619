import axios from "axios";
import { API_URL } from "../utils/config";
import { AuthHeader } from "../utils/authHeader";

const head = {
  "Content-Type": "application/json",
};

export const clinicSettings = (data) => {
  return axios.post(API_URL + "/clinic/", data, {
    headers: AuthHeader(),
  });
};

export const clinicInfo = () => {
  return axios.get(API_URL + "/clinic/", {
    headers: AuthHeader(),
  });
};

export const getClinicInfo = (id) => {
  return axios.get(API_URL + `/clinic/${id}`, {
    headers: AuthHeader(),
  });
};

export function UpdateClinicSettings(id, data) {
  return axios.put(API_URL + "/clinic/update/" + id, data, {
    headers: AuthHeader(),
  });
}

export const patientList = () => {
  return axios.get(API_URL + "/patient/", {
    headers: AuthHeader(),
    head,
  });
};

export const ListSignlepatient = (id) => {
  return axios.get(API_URL + `/patient/get/${id}`, {
    headers: AuthHeader(),
  });
};

export const addPatient = (data) => {
  return axios.post(API_URL + "/patient/", data, {
    headers: AuthHeader(),
  });
};

export function updatePatient(id, data) {
  return axios.put(API_URL + "/patient/get/" + id, data, {
    headers: AuthHeader(),
  });
}

export function deletePatient(id) {
  return axios.delete(API_URL + "/patient/" + id, {
    headers: AuthHeader(),
  });
}

export const addTreatment = (data) => {
  return axios.post(API_URL + "/treatment/", data, {
    headers: AuthHeader(),
  });
};

export const allTreatments = () => {
  return axios.get(API_URL + "/treatment/", {
    headers: AuthHeader(),
  });
};

export const listSignleTreatment = (id) => {
  return axios.get(API_URL + `/treatment/${id}`, {
    headers: AuthHeader(),
  });
};

export function updateTreatmentPaymentStatus(id) {
  return axios.put(API_URL + "/treatment/updatepaymentstatus/" + id, {
    headers: AuthHeader(),
  });
}

export function updateTreatmentStatus(id) {
  return axios.put(API_URL + "/treatment/updatestatus/" + id, {
    headers: AuthHeader(),
  });
}

export function updateTreatment(id, data) {
  return axios.put(API_URL + "/treatment/update/" + id, data, {
    headers: AuthHeader(),
  });
}

export function deleteTreatment(id) {
  return axios.delete(API_URL + "/treatment/" + id, {
    headers: AuthHeader(),
  });
}

export const addAppointment = (data) => {
  return axios.post(API_URL + "/appointment/", data, {
    headers: AuthHeader(),
  });
};

export const allAppointments = (data) => {
  return axios.get(API_URL + "/appointment/", {
    headers: AuthHeader(),
  });
};

export function getAppointment(id) {
  return axios.get(API_URL + "/appointment/" + id, {
    headers: AuthHeader(),
  });
}

export function updateAppointment(id, data) {
  return axios.put(API_URL + "/appointment/" + id, data, {
    headers: AuthHeader(),
  });
}

export function deleteAppointment(id) {
  return axios.delete(API_URL + "/appointment/" + id, {
    headers: AuthHeader(),
  });
}

export const addInsurance = (data) => {
  return axios.post(API_URL + "/insurance/", data, {
    headers: AuthHeader(),
  });
};

export const insuranceList = () => {
  return axios.get(API_URL + "/insurance/", {
    headers: AuthHeader(),
  });
};

export const loadSingleInsurance = (insurancename) => {
  return axios.get(API_URL + "/insurance/" + insurancename, {
    headers: AuthHeader(),
  });
};

export const updateInsurance = (insurancename, data) => {
  return axios.put(API_URL + "/insurance/update/" + insurancename, data, {
    headers: AuthHeader(),
  });
};

export function deleteInsurance(insurancename) {
  return axios.delete(API_URL + "/insurance/delete/" + insurancename, {
    headers: AuthHeader(),
  });
}

export const publicIntervention = () => {
  return axios.get(API_URL + "/treatment/interventions/", {});
};
export const insurancePublic = () => {
  return axios.get(API_URL + "/insurance/public", {});
};
export const clinicPublic = () => {
  return axios.get(API_URL + "/clinic/public/", {});
};

export const interventionList = () => {
  return axios.get(API_URL + "/treatment/intervention/", {
    headers: AuthHeader(),
  });
};
export const addIntervention = (data) => {
  return axios.post(API_URL + "/treatment/intervention/", data, {
    headers: AuthHeader(),
  });
};

export const loadSingleIntervention = (name) => {
  return axios.get(API_URL + "/treatment/intervention/" + name, {
    headers: AuthHeader(),
  });
};

export const UpdateIntervention = (name, data) => {
  return axios.put(API_URL + "/treatment/intervention/update/" + name, data, {
    headers: AuthHeader(),
  });
};

export function deleteIntervention(name) {
  return axios.delete(API_URL + "/treatment/intervention/delete/" + name, {
    headers: AuthHeader(),
  });
}

export const singleIntervention = (name) => {
  return axios.get(API_URL + "/treatment/intervention/" + name, {
    headers: AuthHeader(),
  });
};

export const allBills = () => {
  return axios.get(API_URL + "/payment/", {
    headers: AuthHeader(),
  });
};

export const addPayment = (data) => {
  return axios.post(API_URL + "/payment/", data, {
    headers: AuthHeader(),
  });
};

export function deleteBill(id) {
  return axios.delete(API_URL + "/payment/delete/" + id, {
    headers: AuthHeader(),
  });
}

// USER MANAGEMENT
export const usersList = () => {
  return axios.get(API_URL + "/account/listUsers", {
    headers: AuthHeader(),
  });
};
// Admins management
export const signupAdmin = (data) => {
  return axios.post(API_URL + "/account/adminSignup", data, {
    headers: AuthHeader(),
  });
};
export const admin = () => {
  return axios.get(API_URL + "/account/admin", {
    headers: AuthHeader(),
  });
};

export const adminDetailsUser = (id) => {
  return axios.get(API_URL + `/account/admin/${id}`, {
    headers: AuthHeader(),
  });
};

export function updateAdmins(id, data) {
  return axios.put(API_URL + "/account/admin/" + id, data, {
    headers: AuthHeader(),
  });
}
// Doctors management
export const signupDoctor = (data) => {
  return axios.post(API_URL + "/account/doctorSignup", data, {
    headers: AuthHeader(),
  });
};

export const doctor = () => {
  return axios.get(API_URL + "/account/doctor", {
    headers: AuthHeader(),
  });
};

export const doctorDetailsUser = (id) => {
  return axios.get(API_URL + `/account/doctor/${id}`, {
    headers: AuthHeader(),
  });
};

export function updateDoctor(id, data) {
  return axios.put(API_URL + "/account/doctor/" + id, data, {
    headers: AuthHeader(),
  });
}

// Patient as user

export const signupPatient = (data) => {
  return axios.post(API_URL + "/account/patientSignup", data, {
    headers: AuthHeader(),
  });
};

export const listInsurancePatient = (id) => {
  return axios.get(API_URL + `/patient/getpatient/${id}`, {
    headers: AuthHeader(),
  });
};

export const insuranceAndPatientList = () => {
  return axios.get(API_URL + "/patient/", {
    headers: AuthHeader(),
  });
};



// Cashier as user
export const signupCahier = (data) => {
  return axios.post(API_URL + "/account/cashierSignup", data, {
    headers: AuthHeader(),
  });
};

export const getCahier = (id) => {
  return axios.get(API_URL + `/account/cashier/${id}`, {
    headers: AuthHeader(),
  });
};

export const cashiersList = () => {
  return axios.get(API_URL + "/account/cashier", {
    headers: AuthHeader(),
  });
};


// Cashier as accountant
export const signupAccountant = (data) => {
  return axios.post(API_URL + "/account/accountantSignup", data, {
    headers: AuthHeader(),
  });
};

export const getAccountant = (id) => {
  return axios.get(API_URL + `/account/accountant/${id}`, {
    headers: AuthHeader(),
  });
};

export const AccountantsList = () => {
  return axios.get(API_URL + "/account/accountant", {
    headers: AuthHeader(),
  });
};
//
export const insurancePatientList = () => {
  return axios.get(API_URL + "/reports/patientByInsurance", {
    headers: AuthHeader(),
  });
};
/**/

// EXPENSES AND TYPES MANAGEMENT
export const addExpenseType = (data) => {
  return axios.post(API_URL + "/expenses/get/types", data, {
    headers: AuthHeader(),
  });
};

export const updateExpenseType = (id, data) => {
  return axios.put(API_URL + "/expenses/get/type/" + id, data, {
    headers: AuthHeader(),
  });
};


export function deleteExpenseType(id) {
  return axios.delete(API_URL + "/expenses/get/type/" + id, {
    headers: AuthHeader(),
  });
}


export function loadExpenseType(id) {
  return axios.get(API_URL + "/expenses/get/type/" + id, {
    headers: AuthHeader(),
  });
}

export const allExpenseType = (data) => {
  return axios.get(API_URL + "/expenses/get/types", data, {
    headers: AuthHeader(),
  });
};




export const newExpense = (data) => {
  return axios.post(API_URL + "/expenses/", data, {
    headers: AuthHeader(),
  });
};

export function loadExpense(id) {
  return axios.get(API_URL + "/expenses/" + id, {
    headers: AuthHeader(),
  });
}


export const allExpenses = (data) => {
  return axios.get(API_URL + "/expenses/", data, {
    headers: AuthHeader(),
  });
};

export function deleteExpense(id) {
  return axios.delete(API_URL + "/expenses/" + id, {
    headers: AuthHeader(),
  });
}


export function updateExpense(id, data) {
  return axios.put(API_URL + "/expenses/" + id, data, {
    headers: AuthHeader(),
  });
}
//  SENDING EXCUSE MESSAGES BY PATIENT
export const sendMessage = (data) => {
  return axios.post(API_URL + "/patient/view/message", data, {
    headers: AuthHeader(),
  });
};

export const viewMessages = (data) => {
  return axios.get(API_URL + "/patient/view/message", data, {
    headers: AuthHeader(),
  });
};
export const viewMessage = (id) => {
  return axios.get(API_URL + "/patient/view/message/" + id, {
    headers: AuthHeader(),
  });
};
import React, { useEffect, useState } from "react";
import * as cdiService from "../../services/cdiService";
import { Chart as ChartJs, BarElement } from "chart.js/auto";
import { Line, Pie } from "react-chartjs-2";
import Header from "../../components/Header";
import SideBar2 from "../../components/SideBar2";
import ReportWelcome from "./ReportWelcome";

ChartJs.register(BarElement);

var options = {
  responsive: true,
  // animations: {
  //   tension: {
  //     duration: 1000,
  //     easing: "linear",
  //     from: 1,
  //     to: 0,
  //     loop: true,
  //   },
  // },
  scales: {
    y: {
      beginAtZero: true,
    },
  },
};

export default function ReportDataExpenses() {
  const [expense, setExpense] = useState([]);

  const ListExpenses = () => {
    cdiService.allExpenses().then((res) => {
      setExpense(res.data);
    });
  };


  useEffect(() => {
    ListExpenses();
  }, []);

  var result = [];
  expense.reduce(function (res, amt) {
    if (!res[amt.expense.name]) {
      res[amt.expense.name] = {
        DEPENSE: amt.expense.name,
        amount: 0,
      };
      result.push(res[amt.expense.name]);
    }
    res[amt.expense.name].amount += amt.amount;
    return res;
  }, {});




  var info = {
    labels: result.map((x) => x.DEPENSE),
    datasets: [
      {
        label: "# Repartition des depenses",
        data: result.map((x) => x.amount),
        backgroundColor: [
          "rgb(255, 0, 0)",
          "rgb(148,0,211)",
          "rgb(0,0,255)",
          "rgb(255,255,0)",
          "rgb(255,165,0)",
          "rgb(0,128,0)",
          "rgb(255,225,107)",
          "rgb(250,51,105)",
        ],
        borderColor: ["rgb(0, 0, 0)"],
        borderWidth: 1,
      },
    ],
  };

  
  return (
    <div className="min-h-screen flex flex-col flex-auto flex-shrink-0 antialiased bg-white dark:bg-gray-700 text-black dark:text-white">
      <Header />
      <SideBar2 />
      <div className="h-full ml-14 mt-14 mb-10 md:ml-64">
        <ReportWelcome />

        <div className="grid grid-cols-1 lg:grid-cols-1 p-4 gap-2 mt-10">
          <div className="">
            <Line data={info}  options={options} />
          </div>
          <div className="grid grid-cols-2 gap-4">
            {/* <div className="">
              <Line data={info1} height={100} options={options} />
            </div>
            <div className="">
              <Line data={info1} height={100} options={options} />
            </div> */}
          </div>
        </div>
      </div>
    </div>
  );
}

import React, { useEffect, useState } from "react";
import * as cdiService from "../../services/cdiService";
import { Link } from "react-router-dom";
import { MdSick } from "react-icons/md";
import { BsActivity } from "react-icons/bs";
import DataTable from "react-data-table-component";

export default function GetPatientForAppointment() {
  const [record, setRecord] = useState([]);
  const [filterrecords, setFilterRecords] = useState([]);
  const columns = [
    {
      name: "#",
      selector: (row) => row.id,
      sortable: true,
    },
    {
      name: " Prénoms & Nom",
      selector: (row) => row.firstname + " " + row.lastname,
      sortable: true,
    },
    {
      name: "Assurance",
      selector: (row) => row.insurance,
      sortable: true,
    },
    {
      name: "Assigner",
      cell: (row) => (
        <Link Link to={`/appointment/new/${row.id}`}>
          <MdSick size={20} className="text-green-700" />
        </Link>
      ),
    },
  ];

  const allClients = () => {
    cdiService.patientList().then((res) => {
      setRecord(res.data);
      setFilterRecords(res.data);
    });
  };

  useEffect(() => {
    allClients();
  }, []);

  const search = (event) => {
    const newData = filterrecords.filter((row) =>
    (row.firstname + " " + row.lastname).toLowerCase().includes(event.target.value.toLowerCase()) ||
    (row.insurance).toLowerCase().includes(event.target.value.toLowerCase()) ||
      row.id.toString().includes(event.target.value.toLowerCase())
    );
    setRecord(newData);
  };

  const tableHeaderstyle = {
    headCells: {
      style: {
        fontWeight: "bold",
        fontSize: "14px",
        backgroundColor: "black",
        color: "white",
      },
    },
  };

  return (
    
    <div class="grid grid-cols-1 lg:grid-cols-1 p-4 gap-4">
      <Link
        className=" text-white bg-black font-bold uppercase text-sm px-2 py-2  shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150 rounded-sm float-right w-[180px] h-[34px]"
        variant="outlined"
        Link
        to={"/patient/new"}
      >
        <span className="ml-6">Ajouter patients</span>
        <BsActivity className="text-white font-extrabold -mt-4 " />
      </Link>

      <DataTable
        className="-mt-2"
        columns={columns}
        data={record}
        pagination
        fixedHeader
        fixedHeaderScrollHeight="450px"
        selectableRowsHighlight
        highlightOnHover
        customStyles={tableHeaderstyle}
        subHeader
        subHeaderComponent={
          <input
            type="text"
            placeholder="search here..."
            className="w-[70%] h-[35px] float-left  rounded-md text-black 
            border border-[#0b1011] py-1 px-4 -mb-1 -ml-6 -mt-4 bg-slate-50"
            onChange={search}
          />
        }
        subHeaderAlign="left"
      />
    </div>
  );
}

import React, { useState } from "react";
import * as cdiService from "../../services/cdiService";

import { useNavigate } from "react-router-dom";
import Header from "../../components/Header";
import SideBar2 from "../../components/SideBar2";

export default function ClinicSettings() {
  const navigate = useNavigate();

  const [name, setName] = useState("");
  const [address, setAddress] = useState("");
  const [about, setAbout] = useState("");

  const [telephone, setTelephone] = useState("");
  const [telephonemobile, setTelephonemobile] = useState("");
  const [telephonemobile2, setTelephonemobile2] = useState("");
  const [email, setEmail] = useState("");
  const [vision, setVision] = useState("");
  const [mission, setMission] = useState("");
  const [faceBook, setFaceBook] = useState("");
  const [youTube, setYoutube] = useState("");
  const [linkedIn, setLinkedIn] = useState("");
  const [twitter, setTwitter] = useState("");

  const data = {
    name,
    address,
    about,
    email,
    telephone,
    faceBook,
    linkedIn,
    twitter,
    youTube,
    vision,
    mission,
    telephonemobile,
    telephonemobile2,
  };

  console.log("DATA + ", data);

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      cdiService
        .clinicSettings(data)
        .then(
          (res) => {
            console.log("CHECKING DATA", res.data);
            navigate("/clinic");
          },
          (error) => {
            console.log(error);
          }
        )
        .catch((error) => {
          console.log("ERROR", error.res);
        });
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <div class="min-h-screen flex flex-col flex-auto flex-shrink-0 antialiased bg-white dark:bg-gray-700 text-black dark:text-white">
      <Header />
      <SideBar2 />
      <div className="h-full ml-14 mt-14 mb-10 md:ml-64">
        <div className="grid grid-cols-1 lg:grid-cols-1 p-4 gap-2">
          <div className="bg-white rounded shadow-lg p-4 px-4 md:p-8 mb-6">
            <div className="grid gap-4 gap-y-2 text-sm grid-cols-1 lg:grid-cols-3">
              <div className="text-gray-600">
                <p className="font-medium text-lg">
                  Information de la clinique
                </p>
                <p>Remplissez ce formulaire, s'il vout plait.</p>
              </div>

              <div className="lg:col-span-2">
                <form class="w-full" onSubmit={handleSubmit}>
                  <div class="flex flex-wrap -mx-3 mb-1">
                    <div class="w-full px-3">
                      <label
                        class="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                        for="grid-password"
                      >
                        Nom de la clinique
                      </label>
                      <input
                        class="appearance-none block w-full text-gray-700 border border-gray-200 rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                        type="text"
                        name="name"
                        value={name}
                        onChange={(e) => setName(e.target.value)}
                        placeholder="Nom de la clinique"
                      />
                    </div>
                  </div>

                  <div class="flex flex-wrap -mx-3 mb-1">
                    <div class="w-full md:w-1/2 px-3 mb-6 md:mb-0">
                      <label class="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2">
                        N° Téléphone Fixe
                      </label>
                      <input
                        class="appearance-none block w-full  border rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white"
                        type="text"
                        required
                        value={telephone}
                        onChange={(e) => setTelephone(e.target.value)}
                        placeholder="+225"
                      />{" "}
                    </div>

                    <div class="w-full md:w-1/2 px-3">
                      <label class="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2">
                        N° Téléphone Mobile 1
                      </label>
                      <input
                        className="appearance-none block w-full   border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                        type="text"
                        required
                        value={telephonemobile}
                        onChange={(e) => setTelephonemobile(e.target.value)}
                        placeholder="+225"
                      />
                    </div>
                  </div>
                  <div className="flex flex-wrap -mx-3 mb-1">
                    <div className="w-full md:w-1/2 px-3 mb-6 md:mb-0">
                      <label className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2">
                        N° Téléphone Mobile 2
                      </label>
                      <input
                        className="appearance-none block w-full  border rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white"
                        type="text"
                        required
                        value={telephonemobile2}
                        onChange={(e) => setTelephonemobile2(e.target.value)}
                        placeholder="+225"
                      />{" "}
                    </div>

                    <div className="w-full md:w-1/2 px-3 mb-6 md:mb-0">
                      <label className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2">
                        Email Addresse
                      </label>
                      <input
                        className="appearance-none block w-full   border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                        type="email"
                        name="email"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                        placeholder="email...@..com"
                      />
                    </div>
                  </div>

                  <div className="flex flex-wrap -mx-3 mb-1">
                    <div className="w-full px-3">
                      <label className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2">
                        Addresse
                      </label>
                      <textarea
                        className="appearance-none block w-full  text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                        type="text"
                        name="address"
                        value={address}
                        onChange={(e) => setAddress(e.target.value)}
                        placeholder="Addresse"
                      />
                    </div>
                  </div>
                  <div className="flex flex-wrap -mx-3 mb-1">
                    <div className="w-full px-3">
                      <label className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2">
                        A propos de nous
                      </label>
                      <textarea
                        class="appearance-none block w-full  text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                        type="text"
                        name="about"
                        value={about}
                        onChange={(e) => setAbout(e.target.value) }
                        placeholder="A propos de nous"
                      />
                    </div>
                  </div>
                  <div className="flex flex-wrap -mx-3 mb-1">
                    <div className="w-full md:w-1/2 px-3 mb-6 md:mb-0">
                      <label className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2">
                        Vision
                      </label>
                      <textarea
                        className="appearance-none block w-full  text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                        type="text"
                        required
                        name="vision"
                        value={vision}
                        onChange={(e) => setVision(e.target.value)}
                        placeholder="Vision"
                      />
                    </div>
                    <div className="w-full md:w-1/2 px-3 mb-6 md:mb-0">
                      <label className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2">
                        Mission
                      </label>
                      <textarea
                        className="appearance-none block w-full  text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                        type="text"
                        required
                        name="mission"
                        value={mission}
                        onChange={(e) => setMission(e.target.value)}
                        placeholder="Mission"
                      />
                    </div>
                  </div>

                  <div className="flex flex-wrap -mx-3 mb-1">
                    <div className="w-full md:w-1/2 px-3 mb-6 md:mb-0">
                      <label className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2">
                        Lien FaceBook
                      </label>
                      <input
                        className="appearance-none block w-full text-gray-700 border border-gray-200 rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                        type="text"
                        name="faceBook"
                        required
                        value={faceBook}
                        onChange={(e) => setFaceBook(e.target.value)}
                        placeholder="Lien FaceBook"
                      />
                    </div>
                    <div className="w-full md:w-1/2 px-3 mb-6 md:mb-0">
                      <label className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2">
                        Lien YouTube
                      </label>
                      <input
                        className="appearance-none block w-full text-gray-700 border border-gray-200 rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                        type="text"
                        name="youTube"
                        required
                        value={youTube}
                        onChange={(e) => setYoutube(e.target.value)}
                        placeholder="Lien YouTube"
                      />
                    </div>
                  </div>
             
                  <div className="flex flex-wrap -mx-3 mb-1">
                    <div className="w-full md:w-1/2 px-3 mb-6 md:mb-0">
                      <label className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2">
                        Lien Twitter
                      </label>
                      <input
                        className="appearance-none block w-full text-gray-700 border border-gray-200 rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                        type="text"
                        name="twitter"
                        required
                        value={twitter}
                        onChange={(e) => setTwitter(e.target.value)}
                        placeholder="Lien Twitter"
                      />
                    </div>

                    <div className="w-full md:w-1/2 px-3 mb-6 md:mb-0">
                      <label className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2">
                        Lien LinkedIn
                      </label>
                      <input
                        className="appearance-none block w-full text-gray-700 border border-gray-200 rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                        type="text"
                        placeholder="Lien LinkedIn"
                        name="linkedIn"
                        required
                        value={linkedIn}
                        onChange={(e) => setLinkedIn(e.target.value)}
                      />
                    </div>
                  </div>
               

                  <div className="flex flex-wrap -mx-3 mb-1">
                    <div className="w-full px-3">
                      <button className="bg-white  text-black font-bold py-2 px-4 rounded w-full">
                        Sauvegarder
                      </button>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

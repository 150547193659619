import React, { useEffect, useState } from "react";
import * as cdiService from "../../services/cdiService";

import { Chart as ChartJs, BarElement } from "chart.js/auto";
import { Line } from "react-chartjs-2";
import Header from "../../components/Header";
import SideBar2 from "../../components/SideBar2";
import ReportWelcome from "./ReportWelcome";

ChartJs.register(BarElement);

var options = {
  responsive: true,
  // animations: {
  //   tension: {
  //     duration: 1000,
  //     easing: "linear",
  //     from: 1,
  //     to: 0,
  //     loop: true,
  //   },
  // },
  scales: {
    y: {
      beginAtZero: true,
    },
  },
};

export default function ReportDataPatient() {
  const [patients, setPatients] = useState([]);

  const ListInsuranceAndPat = () => {
    cdiService.insuranceAndPatientList().then((res) => {
      setPatients(res.data);
    });
  };

let arr = patients;

 

  useEffect(() => {
    ListInsuranceAndPat();
  }, []);


  function findGenderOcc(arr, key1) {
    let arr2 = [];
    arr.forEach((x) => {
      if (
        arr2.some((val) => {
          return val[key1] === x[key1];
        })
      ) {
        arr2.forEach((k) => {
          if (k[key1] === x[key1]) {
            k["occurrence"]++;
          }
        });
      } else {
        let a = {};
        a[key1] = x[key1];
        a["occurrence"] = 1;
        arr2.push(a);
      }
    });
    return arr2;
  }

  let key1 = "gender";

  var info1 = {
    labels: findGenderOcc(arr, key1).map((x) => x.gender),
    datasets: [
      {
        label: "# Repartition des patients par genre",
        data: findGenderOcc(arr, key1).map((x) => x.occurrence),
        backgroundColor: [
          "rgb(255, 0, 0)",
          "rgb(148,0,211)",
          "rgb(0,0,255)",
          "rgb(255,255,0)",
          "rgb(255,165,0)",
          "rgb(0,128,0)",
          "rgb(255,225,107)",
          "rgb(250,51,105)",
        ],
        borderColor: ["rgb(0, 0, 0)"],
        borderWidth: 1,
      },
    ],
  };

  let key2 = "profession";
  function findProfessionOcc(arr, key2) {
    let arr2 = [];
    arr.forEach((x) => {
      if (
        arr2.some((val) => {
          return val[key2] === x[key2];
        })
      ) {
        arr2.forEach((k) => {
          if (k[key2] === x[key2]) {
            k["occurrence"]++;
          }
        });
      } else {
        let a = {};
        a[key2] = x[key2];
        a["occurrence"] = 1;
        arr2.push(a);
      }
    });
    return arr2;
  }


  var info2 = {
    labels: findProfessionOcc(arr, key2).map((x) => x.profession),
    datasets: [
      {
        label: "# Repartition des patients par genre",
        data: findProfessionOcc(arr, key2).map((x) => x.occurrence),
        backgroundColor: [
          "rgb(255, 0, 0)",
          "rgb(148,0,211)",
          "rgb(0,0,255)",
          "rgb(255,255,0)",
          "rgb(255,165,0)",
          "rgb(0,128,0)",
          "rgb(255,225,107)",
          "rgb(250,51,105)",
        ],
        borderColor: ["rgb(0, 0, 0)"],
        borderWidth: 1,
      },
    ],
  };
  
  return (
    <div className="min-h-screen flex flex-col flex-auto flex-shrink-0 antialiased bg-white dark:bg-gray-700 text-black dark:text-white">
      <Header />
      <SideBar2 />
      <div className="h-full ml-14 mt-14 mb-10 md:ml-64">
        <ReportWelcome />

        <div className="grid grid-cols-1 lg:grid-cols-1 p-4 gap-2 mt-10">
          <div className="">
            <Line data={info2} height={100} options={options} />
          </div>
          <div className="grid grid-cols-2 gap-4">
            <div className="">
              <Line data={info1} height={100} options={options} />
            </div>
            <div className="">
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

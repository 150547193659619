import React from "react";
import InsuranceList from './InsuranceList'
import Header from "../../components/Header";
import SideBar2 from "../../components/SideBar2";
export default function Insurances() {
  return (

    <div class="min-h-screen flex flex-col flex-auto flex-shrink-0 antialiased bg-white dark:bg-gray-700 text-black dark:text-white">
    <Header />
    <SideBar2 />
    <div class="h-full ml-14 mt-14 mb-10 md:ml-64">
      <div class="grid grid-cols-1 lg:grid-cols-1 p-4 gap-2">
      <InsuranceList />
       </div>
    </div>
  </div>
  );
}

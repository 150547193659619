import React, { useState } from "react";
import SideBar2 from "../components/SideBar2";
import Header from "../components/Header";
import Calendar from "react-calendar";
import "react-calendar/dist/Calendar.css";

export default function DashboardWelcome() {
  const [date, setDate] = useState(new Date());
  let time = new Date().toLocaleTimeString();

  const [ctime, setTime] = useState(time);
  const UpdateTime = () => {
    time = new Date().toLocaleTimeString();
    setTime(time);
  };
  setInterval(UpdateTime);

  return (
    <div class="min-h-screen flex flex-col flex-auto flex-shrink-0 antialiased bg-white dark:bg-gray-700 text-black dark:text-white">
      <Header />
      <SideBar2 />
      <div class="h-full ml-14 mt-14 mb-10 md:ml-64 flex">
        <div class="grid grid-cols-1 lg:grid-cols-2 p-4 gap-2 w-full">
          <>
            <div class="bg-white dark:bg-gray-800 shadow-lg rounded-md flex items-center justify-between p-3 border-b-4 border-green-600 dark:border-gray-600 text-black font-medium group">
              <Calendar onChange={setDate} value={date} />
              <span className="bold mr-4">{date.toDateString()}</span>
            </div>

            <div class="bg-white dark:bg-gray-800 shadow-lg rounded-md flex items-center justify-between p-3 border-b-4 border-green-600 dark:border-gray-600 text-black font-medium group">
              <p className="text-4xl -mt40">{ctime}</p>
            </div>
          </>
        </div>
      </div>
    </div>
  );
}

import React from "react";
import Header from "../../components/Header";
import SideBar2 from "../../components/SideBar2";
import ExpenseTypeList from "./ExpenseTypeList";
import ExpensesList from "./ExpensesList";

export default function ExpensesHome() {
  return (
    <div class="min-h-screen flex flex-col flex-auto flex-shrink-0 antialiased bg-white dark:bg-gray-700 text-black dark:text-white">
      <Header />
      <SideBar2 />

      <div class="h-full ml-14 mt-14 mb-10 md:ml-64 flex">
        <div class="grid grid-cols-1 lg:grid-cols-2 p-4 gap-2 w-full">
          <div class="bg-white dark:bg-gray-800 shadow-lg rounded-md flex items-center justify-between p-3 border-b-4 border-green-600 dark:border-gray-600 text-black font-medium group max-w-full">
            <ExpenseTypeList />
          </div>

          <div class="bg-white dark:bg-gray-800 shadow-lg rounded-md flex items-center justify-between p-3 border-b-4 border-green-600 dark:border-gray-600 text-black font-medium group text-center">
            <ExpensesList />
          </div>
        </div>
      </div>
    </div>
  );
}

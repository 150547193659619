import React, { useEffect, useState } from "react";
import * as cdiService from "../../services/cdiService";
import * as authService from "../../services/authService";
import jwt_decode from "jwt-decode";

import { useNavigate, useParams } from "react-router-dom";
import Select from "react-select";
import Header from "../../components/Header";
import SideBar2 from "../../components/SideBar2";

export default function UpdateAppointment() {
  const navigate = useNavigate();
  const [currentUser] = useState(authService.getCurrentUser());
  const [user] = useState(jwt_decode(currentUser.access));

  const { id } = useParams();
  const [commentaire] = useState("");
  const [rendezvousdate] = useState("");
  const [rendezvoustime] = useState("");
  const [status] = useState("En-cours");
  console.log("ID", id);
  const options = [
    // { value: "encours", label: "encours" },
    { value: "Terminé", label: "Terminé" },
  ];

  console.log("USER", user);

  const [data, setData] = useState({
    status,
    commentaire,
    rendezvousdate,
    rendezvoustime,
  });

  const loadAppointmentInfo = () => {
    cdiService.getAppointment(id).then((res) => {
      setData(res.data);
    });
  };

  useEffect(() => {
    loadAppointmentInfo();
  }, []);

  console.log("DATA + ", data);

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      cdiService
        .updateAppointment(id, data)
        .then(
          (res) => {
            navigate("/appointment");
          },
          (error) => {
            console.log(error);
          }
        )
        .catch((error) => {
          console.log("ERROR", error.res);
        });
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <div class="min-h-screen flex flex-col flex-auto flex-shrink-0 antialiased bg-white dark:bg-gray-700 text-black dark:text-white">
      <Header />
      <SideBar2 />
      <div className="h-full ml-14 mt-14 mb-10 md:ml-64">
        <div className="grid grid-cols-1 lg:grid-cols-1 p-4 gap-2">
          <div className="bg-white rounded shadow-lg p-4 px-4 md:p-8 mb-6">
            <div className="grid gap-4 gap-y-2 text-sm grid-cols-1 lg:grid-cols-3">
              <div className="text-gray-600">
                <p className="font-medium text-lg">Details du Rendez-vous</p>
                <p>Please fill out all the fields.</p>
              </div>

              <div className="lg:col-span-2">
                <form onSubmit={handleSubmit}>
                  <div className="grid gap-4 gap-y-2 text-sm grid-cols-1 md:grid-cols-6">
                    <div className="md:col-span-2">
                      <label
                        for="date"
                        class="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                      >
                        Date
                      </label>
                      <input
                        type="date"
                        class="block p-2.5 w-full text-sm text-gray-900 bg-gray-50 rounded-sm border border-black focus:border-black"
                        value={data.rendezvousdate}
                        onChange={(e) =>
                          setData({
                            ...data,
                            rendezvousdate: e.target.value,
                          })
                        }
                      />
                    </div>
                    <div className="md:col-span-2">
                      <label
                        for="time"
                        class="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                      >
                        Heure
                      </label>

                      <input
                        type="time"
                        class="block p-2.5 w-full text-sm text-gray-900 bg-gray-50 rounded-sm border border-black focus:border-black"
                        value={data.rendezvoustime}
                        onChange={(e) =>
                          setData({
                            ...data,
                            rendezvoustime: e.target.value,
                          })
                        }
                      />
                    </div>
                    <div className="md:col-span-2 mt-0 ">
                      <label
                        for="Status"
                        class="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                      >
                        {" "}
                        Status
                      </label>
                      <Select
                        // required
                        placeholder="Changez le status"
                        options={options}
                        isSearchable
                        onChange={(e) =>
                          setData({
                            ...data,
                            status: e.value,
                          })
                        }
                        noOptionsMessage={() => "No Status found..."}
                        styles={{
                          placeholder: (baseStyles, state) => ({
                            ...baseStyles,
                            color: "red",
                          }),
                          dropdownIndicator: () => ({
                            color: "red",
                          }),
                        }}
                      />
                    </div>

                    <div className="md:col-span-6">
                      <label
                        for="message"
                        class="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                      >
                        Raison du rendez-vous
                      </label>
                      <textarea
                        id="message"
                        rows="4"
                        name="commentaire"
                        value={data.commentaire}
                        onChange={(e) =>
                          setData({
                            ...data,
                            commentaire: e.target.value,
                          })
                        }
                        class="block p-2.5 w-full text-sm text-gray-900 bg-gray-50 rounded-sm border border-gray-300 focus:ring-black focus:border-black dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-black dark:focus:border-black"
                        placeholder="Raison du rendez-vous..."
                      ></textarea>
                    </div>

                    <div className="md:col-span-6">
                      <div className="w-full ">
                        <button className="bg-white text-black font-bold py-2 px-4 rounded w-full">
                          Sauvegarder
                        </button>
                      </div>
                    </div>
                  </div>

                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

import React, { useState } from "react";
import Header from "../components/Header";
import SideBar2 from "../components/SideBar2";
// import Single from '../assets/single.png'
// import Double from './assets/double.png'
// import Triple from '/../public/assets/triple.png'
// import useLocalStorage from "use-local-storage";

export default function Welcome() {
  let time = new Date().toLocaleTimeString();
  const [currentTime, setcurrentTime] = useState(time)
  // console.log(currentTime);

  const updateTime = () => {
    let time = new Date().toLocaleTimeString();
    setcurrentTime(time)
  }

  setInterval(updateTime, 1000);

  return (
  

    <div class="min-h-screen flex flex-col flex-auto flex-shrink-0 antialiased bg-white dark:bg-gray-700 text-black dark:text-white">
    <Header/>
    <SideBar2 />
    <div class="h-full ml-14 mt-14 mb-10 md:ml-64">
 
      <div class="grid grid-cols-1 lg:grid-cols-2 p-4 gap-4">
   
         <div className='w-full shadow-xl flex flex-col p-4 my-4 rounded-lg hover:scale-105 duration-300'>
            
              <h2 className='text-2xl font-bold text-center py-8'>Traitements et Rendez-vous</h2>
             {/* <p className='text-center text-4xl font-bold'>   {currentTime}</p> */}
              <div className='text-center font-medium'>
                  <p className='py-2 border-b mx-8 mt-8'>500 Traitements</p>
                <p className='py-2 border-b mx-8'> 11 Rendez-vous</p>
                   {/* <p className='py-2 border-b mx-8'>Send up to 2 GB</p> */}
              </div>
             <button className='bg-[#00df9a] w-[200px] rounded-md font-medium my-6 mx-auto px-6 py-3'>Voir le graph</button>
           </div>
         <div className='w-full shadow-xl bg-gray-100 flex flex-col p-4 md:my-0 my-6 rounded-lg hover:scale-105 duration-300'>
               {/* <img className='w-20 mx-auto mt-[-3rem] bg-transparent' src={Double} alt="/" /> */}
               <h2 className='text-2xl font-bold text-center py-6'>Statistique des patients</h2>
              <p className='text-center text-4xl font-bold'>{currentTime}</p>
               <div className='text-center font-medium'>
                   <p className='py-2 border-b mx-8 mt-8'>500 GB Storage</p>
                   <p className='py-2 border-b mx-8'>1 Granted User</p>
                   {/* <p className='py-2 border-b mx-8'>Send up to 2 GB</p> */}
               </div>
               <button className='bg-black text-[#00df9a] w-[200px] rounded-md font-medium my-6 mx-auto px-6 py-3'>Voir le graph</button>
           </div>
           <div className='w-full shadow-xl flex flex-col p-4 my-4 rounded-lg hover:scale-105 duration-300'>
               {/* <img className='w-20 mx-auto mt-[-3rem] bg-white' src={Triple} alt="/" /> */}
              <h2 className='text-2xl font-bold text-center py-8'>Status des assurances</h2>
              {/* <p className='text-center text-4xl font-bold'>$149</p> */}
              <div className='text-center font-medium'>
                  <p className='py-2 border-b mx-8 mt-8'>500 GB Storage</p>
                  <p className='py-2 border-b mx-8'>1 Granted User</p>
                   {/* <p className='py-2 border-b mx-8'>Send up to 2 GB</p> */}
              </div>
             <button className='bg-[#00df9a] w-[200px] rounded-md font-medium my-6 mx-auto px-6 py-3'>Voir le graph</button>
           </div>
       </div>
        {/* <!-- ./Recent Activities --> */}
    </div>
  </div>
  );
}

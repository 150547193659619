import React, { useEffect, useState } from "react";
import * as cdiService from "../../services/cdiService";
import { Link } from "react-router-dom";
import { AiFillInsurance } from "react-icons/ai";

import { MdDelete, MdEdit } from "react-icons/md";
import DataTable from "react-data-table-component";
import Swal from "sweetalert2";

export default function UsersList() {
  const [record, setRecord] = useState([]);
  const [filterrecords, setFilterRecords] = useState([]);

  const columns = [
  
    {
      name: "ASSURANCE",
      selector: (row) => row.insurancename,
      sortable: true,
    },

    {
      name: "Telephone",
      selector: (row) => row.telephone,
      sortable: true,
    },
    {
      name: "email",
      selector: (row) => row.email,
      sortable: true,
    },

    {
      name: "Modifier",
      cell: (row) => (
        <Link to={`/insurance/${row.insurancename}`}>
          <MdEdit size={20} className="text-green-700" />
        </Link>
      ),
    },

    {
      name: "Supprimer",
      cell: (row) => (
        <MdDelete
          size={20}
          className="text-red-700"
          onClick={() => handleDelete(row.insurancename)}
        />
      ),
    },
  ];

  const ListInsurances = () => {
    cdiService.insuranceList().then((res) => {
      let insurance = res.data.filter(
        (x) => x.insurancename !== "NA" 
      );
      setRecord(insurance);
      setFilterRecords(insurance);
    });
  };

  const handleDelete = (row) => {
    Swal.fire({
      title: "Es-tu sûr de cette opération?",
      text: "Vous ne pourrez pas annuler cette opération!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Oui, supprimez!",
    }).then((result) => {
      if (result.isConfirmed) {
        cdiService
          .deleteInsurance(row)
          .then((res) => {
            ListInsurances();
          })
          .catch((e) => console.log("erreur supprimer", e));
        Swal.fire("supprimé!", "L'assurance est supprimée.", "success");
      }
    });
  };

  useEffect(() => {
    ListInsurances();
  }, []);

  const search = (event) => {
    const newData = filterrecords.filter(
      (row) =>
        row.insurancename
          .toLowerCase()
          .includes(event.target.value.toLowerCase()) ||
        row.email.toLowerCase().includes(event.target.value.toLowerCase()) ||
        row.telephone
          .toLowerCase()
          .includes(event.target.value.toLowerCase()) 
    );
    setRecord(newData);
  };

  const tableHeaderstyle = {
    headCells: {
      style: {
        fontWeight: "bold",
        fontSize: "14px",
        backgroundColor: "black",
        color: "white",
      },
    },
  };

  return (
    <div class="grid grid-cols-1 lg:grid-cols-1 p-4 gap-4">
      <Link
            className=" text-white bg-black font-bold uppercase text-sm px-2 py-2  shadow hover:shadow-lg outline-none focus:outline-none mr-1  ease-linear transition-all duration-150 rounded-md float-right w-[100px] h-[34px] mb-0"
           variant="outlined"
        Link
        to={"/insurance/new"}
      >
        <span className="ml-4">Ajouter </span>
        <AiFillInsurance className="text-white font-extrabold -mt-4" />
      </Link>
      <DataTable
        className="-mt-2"
        columns={columns}
        data={record}
        pagination
        fixedHeader
        fixedHeaderScrollHeight="450px"
        selectableRowsHighlight
        highlightOnHover
        customStyles={tableHeaderstyle}
        subHeader
        subHeaderComponent={
          <input
            type="text"
            placeholder="search here..."
            className="w-[70%] h-[35px] float-left  rounded-md text-black 
            border border-[#0b1011] py-1 px-4 mb-0 -ml-6  bg-slate-50 mt-0"
            onChange={search}
          />
        }
        subHeaderAlign="left"
      />
      ​
    </div>
  );
}

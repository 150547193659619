import React, { useState } from "react";
import * as cdiService from "../../services/cdiService";
import * as authService from "../../services/authService";
import jwt_decode from "jwt-decode";

import { useNavigate, useParams } from "react-router-dom";
import Header from "../../components/Header";
import SideBar2 from "../../components/SideBar2";

export default function NewTreatment() {
  const [currentUser] = useState(authService.getCurrentUser());
  const [user] = useState(jwt_decode(currentUser.access));

  const navigate = useNavigate();
  const [amountpaid, setAmountpaid] = useState("");

  const {
    id,
    partpatient,
    treatmentamount,
    partinsurance,
  } = useParams();

  const data = {
    treatment: id ,
    user: user.id,

    patient: 
       id,
    treatmentamount,
    partinsurance,
    partpatient,
    amountpaid,
  };

  console.log("Amount", data)
  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      cdiService
        .addPayment(data)
        .then(
          (res) => {
            if (res.data.length !== 0) {
              cdiService.updateTreatmentPaymentStatus(id);
              navigate("/bill");
            }
          },
          (error) => {
            console.log(error);
          }
        )
        .catch((error) => {
          console.log("ERROR", error.res);
        });
    } catch (err) {
      console.log(err);
    }
  };
  return (
    <div class="min-h-screen flex flex-col flex-auto flex-shrink-0 antialiased bg-white dark:bg-gray-700 text-black dark:text-white">
    <Header />
    <SideBar2 />
    <div className="h-full ml-14 mt-14 mb-10 md:ml-64">
      <div className="grid grid-cols-1 lg:grid-cols-1 p-4 gap-2">
        <div className="bg-white rounded shadow-lg p-4 px-4 md:p-8 mb-6">
          <div className="grid gap-4 gap-y-2 text-sm grid-cols-1 lg:grid-cols-3">
            <div className="text-gray-600">
                <p className="font-medium text-lg">Details tu traitement</p>
                <table className="min-w-full text-left text-sm font-light">
                  <tbody>
                    <tr className="border-b transition duration-300 ease-in-out hover:bg-neutral-100 dark:border-neutral-500 dark:hover:bg-neutral-600">
                      <td className="whitespace-nowrap px-6 py-4 font-medium text-xl">
                        N° du Patient : {data.patient}
                      </td>
                    </tr>
                    <tr className="border-b transition duration-300 ease-in-out hover:bg-neutral-100 dark:border-neutral-500 dark:hover:bg-neutral-600">
                      <td className="whitespace-nowrap px-6 py-4 font-medium text-xl">
                        N° du traitement : {data.treatment}
                      </td>
                    </tr>
                    <tr className="border-b transition duration-300 ease-in-out hover:bg-neutral-100 dark:border-neutral-500 dark:hover:bg-neutral-600">
                      <td className="whitespace-nowrap px-6 py-4 font-medium text-xl">
                        Montant total : {treatmentamount}
                      </td>
                    </tr>
                    <tr className="border-b transition duration-300 ease-in-out hover:bg-neutral-100 dark:border-neutral-500 dark:hover:bg-neutral-600">
                      <td className="whitespace-nowrap px-6 py-4 font-medium text-xl">
                        Part assurance : {partinsurance}
                      </td>
                    </tr>
                    <tr className="border-b transition duration-300 ease-in-out hover:bg-neutral-100 dark:border-neutral-500 dark:hover:bg-neutral-600">
                      <td className="whitespace-nowrap px-6 py-4 font-medium bg-black text-white text-2xl">
                        Part patient : {partpatient}
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>

              <div className="lg:col-span-2">
                <form onSubmit={handleSubmit}>
                  <div className="grid gap-4 gap-y-2 text-sm grid-cols-1 md:grid-cols-5">
                    <div className="md:col-span-3"></div>
                    <div className="md:col-span-2 -mt-1">
                      <input
                        type="number"
                        required
                        onChange={(e) => setAmountpaid(e.target.value)}
                        className=" flex items-center h-10 border border-black mt-1 rounded px-4 w-full"
                        placeholder="Montant du traitement"
                      />
                    </div>
                  </div>
                  <div className="grid gap-4 gap-y-2 text-sm grid-cols-1 md:grid-cols-5 mt-6">
                    <div className="md:col-span-3"></div>
                    <div className="md:col-span-2 -mt-1">
                      <button
                        type="submit"
                        className="dark:active:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.2),0_4px_18px_0_rgba(59,113,202,0.1)]] inline-block  rounded bg-primary px-6 pb-2 pt-2.5 text-xs font-medium uppercase leading-normal text-black shadow-[0_4px_9px_-4px_#3b71ca] transition duration-150 ease-in-out hover:bg-primary-600 hover:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] focus:bg-primary-600 focus:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] focus:outline-none focus:ring-0 active:bg-primary-700 active:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] dark:shadow-[0_4px_9px_-4px_rgba(59,113,202,0.5)] dark:hover:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.2),0_4px_18px_0_rgba(59,113,202,0.1)] dark:focus:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.2),0_4px_18px_0_rgba(59,113,202,0.1)] w-full"
                        onClick={handleSubmit}
                      >
                        Sauvegarder
                      </button>
                    </div>
                  </div>

                  <div className=" gap-4 gap-y-2 text-sm grid-cols-1 md:grid-cols-5 mt-8 hidden">
                    <span className="p-4 mt-1">Status du payment</span>
                    <input
                      type="radio"
                      id="Impayé"
                      name="choosepayment"
                      value="Impayé"
                     
                    />
                    <label htmlFor="Impayé" className="mr-1">
                      Impayé
                    </label>

                    <input
                      type="radio"
                      id="Payé"
                      name="choosepayment"
                      value="Payé"
                      disabled 
                    />
                    <label htmlFor="Payé" className="mr-1">
                      Payé
                    </label>
                  </div>

                
                </form>
              </div>
            </div>
          </div>
        </div>
        </div>
      </div>
  
  );
}

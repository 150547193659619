import React, { useState, useEffect } from "react";
import Navbar from "../components/HomeNavBar";

import Footer from "../components/Footer";
import ImageSlider from "../components/ImageSlider";
import PublicIntervention from "./PublicInterventions";
import PublicInsurances from "./PublicInsurances";
import * as cdiService from "../services/cdiService";
import {  MdOutlineAttachEmail } from "react-icons/md";

export default function Home() {
  const [record, setRecord] = useState([]);

  const ClinicInfo = () => {
    cdiService.clinicPublic().then((res) => {
      console.log("DATA", res.data);
      setRecord(res.data);
    });
  };

  useEffect(() => {
    ClinicInfo();
  }, []);
  return (
    <div className="">
      {/* Navbar here */}
      <Navbar />

      {/* Main content Start here */}
      <section className="mx-auto">
        <div className="grid grid-cols-12">
          {/* Main content */}
          <div className="col-span-12  mx-auto mt-0 p-4 overflow-auto items-center h-screen bg-gradient-to-b ">
            {/* Mission */}
            <div className="grid grid-cols-1 mt-9 md:grid-cols-2 lg:grid-cols-2 from-white to-gray-700 ">
              {record.map((menu, index) => (
                <div
                  className="w-full bg-white text-2xl text-left 
              pt-0 font-light md:text-xl  ml-4"
                  key={index}
                >
                  <p
                    id="about"
                    className="text-4xl m-4 pr-10 
              justify-center text-left font-extrabold"
                  >
                    <svg
                      class="animate-spin h-5 w-5 mr-3 ..."
                      viewBox="0 0 24 24"
                    ></svg>
                    About us
                  </p>

                  <p
                    className="text-2xl text-left 
                pl-0 pr-4 font-light md:text-xl ml-6"
                  >
                    <svg
                      class="inline-block fill-current mr-2"
                      xmlns="http://www.w3.org/2000/svg"
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                    >
                      <path d="M12 0c-4.198 0-8 3.403-8 7.602 0 4.198 3.469 9.21 8 16.398 4.531-7.188 8-12.2 8-16.398 0-4.199-3.801-7.602-8-7.602zm0 11c-1.657 0-3-1.343-3-3s1.343-3 3-3 3 1.343 3 3-1.343 3-3 3z" />
                    </svg>
                    {menu.address}
                  </p>

                  <p className="flex   ml-5">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="36"
                      height="24"
                      fill="currentColor"
                      class="bi bi-megaphone mt-6"
                      viewBox="0 0 16 16"
                    >
                      {" "}
                      <path d="M13 2.5a1.5 1.5 0 0 1 3 0v11a1.5 1.5 0 0 1-3 0v-.214c-2.162-1.241-4.49-1.843-6.912-2.083l.405 2.712A1 1 0 0 1 5.51 15.1h-.548a1 1 0 0 1-.916-.599l-1.85-3.49a68.14 68.14 0 0 0-.202-.003A2.014 2.014 0 0 1 0 9V7a2.02 2.02 0 0 1 1.992-2.013 74.663 74.663 0 0 0 2.483-.075c3.043-.154 6.148-.849 8.525-2.199V2.5zm1 0v11a.5.5 0 0 0 1 0v-11a.5.5 0 0 0-1 0zm-1 1.35c-2.344 1.205-5.209 1.842-8 2.033v4.233c.18.01.359.022.537.036 2.568.189 5.093.744 7.463 1.993V3.85zm-9 6.215v-4.13a95.09 95.09 0 0 1-1.992.052A1.02 1.02 0 0 0 1 7v2c0 .55.448 1.002 1.006 1.009A60.49 60.49 0 0 1 4 10.065zm-.657.975 1.609 3.037.01.024h.548l-.002-.014-.443-2.966a68.019 68.019 0 0 0-1.722-.082z" />{" "}
                    </svg>
                    <span className="ml-4 py-4"> {menu.about}</span>
                  </p>
                  <div className="grid grid-cols-1 mt-2 md:grid-cols-1 lg:grid-cols-1 from-white to-gray-700  ">
                    <ul className="list-disc list-inside mb-4 -ml-5">
                   
                      <li className="flex items-center space-x-4 ml-10 my-2">
                        <svg
                          class="inline-block fill-current mr-2"
                          xmlns="http://www.w3.org/2000/svg"
                          width="24"
                          height="24"
                          viewBox="0 0 24 24"
                        >
                          <path d="M20 22.621l-3.521-6.795c-.008.004-1.974.97-2.064 1.011-2.24 1.086-6.799-7.82-4.609-8.994l2.083-1.026-3.493-6.817-2.106 1.039c-7.202 3.755 4.233 25.982 11.6 22.615.121-.055 2.102-1.029 2.11-1.033z" />
                        </svg>
                        {menu.telephone} / {menu.telephonemobile} / {menu.telephonemobile2}
                      </li>
                   
                      <li className="flex items-center space-x-4 ml-10 my-2">
                        <span className="flex ml-0  mt-6"> <MdOutlineAttachEmail /></span>
                     <span className="mt-4">

                        {menu.email}
                     </span>
                      </li>
                    </ul>
                  </div>
                  <p className="flex   ml-5">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="16"
                      height="16"
                      fill="currentColor"
                      class="bi bi-emoji-sunglasses-fill mt-2"
                      viewBox="0 0 16 16"
                    >
                      {" "}
                      <path d="M8 16A8 8 0 1 0 8 0a8 8 0 0 0 0 16zM2.31 5.243A1 1 0 0 1 3.28 4H6a1 1 0 0 1 1 1v.116A4.22 4.22 0 0 1 8 5c.35 0 .69.04 1 .116V5a1 1 0 0 1 1-1h2.72a1 1 0 0 1 .97 1.243l-.311 1.242A2 2 0 0 1 11.439 8H11a2 2 0 0 1-1.994-1.839A2.99 2.99 0 0 0 8 6c-.393 0-.74.064-1.006.161A2 2 0 0 1 5 8h-.438a2 2 0 0 1-1.94-1.515L2.31 5.243zM4.969 9.75A3.498 3.498 0 0 0 8 11.5a3.498 3.498 0 0 0 3.032-1.75.5.5 0 1 1 .866.5A4.498 4.498 0 0 1 8 12.5a4.498 4.498 0 0 1-3.898-2.25.5.5 0 0 1 .866-.5z" />{" "}
                    </svg>
                    <span className="ml-4">{menu.vision} {menu.mission} </span>
                  </p>
               
                </div>
              ))}

              <div className="w-full  bg-white ">
                <ImageSlider />
              </div>
              <div className="w-full  bg-white shadow-xl flex flex-col p-4  my-4 rounded-lg ">
                <p
                  id="contact"
                  className="text-4xl m-4 pr-10 
                justify-center  text-left font-extrabold"
                >
                  Assurances
                </p>
                <PublicInsurances />
              </div>
              <div className="w-full bg-white shadow-xl flex flex-col p-4 my-4 rounded-lg  ">
                <p
                  id="services"
                  className="text-4xl m-4 pr-10 mt-0
                justify-center  text-left font-extrabold"
                >
                  Nos Services{" "}
                </p>
                <PublicIntervention />
              </div>
            </div>
          </div>
        </div>
      </section>

      <Footer />
    </div>
  );
}

import React, { useEffect, useState } from "react";
import * as cdiService from "../services/cdiService";

export default function PublicIntervention() {
  const [record, setRecord] = useState([]);

  const ListInterventions = () => {
    cdiService.publicIntervention().then((res) => {
      console.log("SERVICE", res.data);
      setRecord(res.data);
    });
  };

  useEffect(() => {
    ListInterventions();
  }, []);

  return (
    <div className="container">
      <ul class="space-y-4 text-left">
        {record.map((menu, index) => (
          <li
            class="flex items-center space-x-3 ml-6"
            key={index}
          >
            <svg
              class="flex-shrink-0 w-3.5 h-3.5 text-green-500 dark:text-green-400 animate-spin"
              aria-hidden="true"
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 16 12"
            >
              <path
                stroke="currentColor"
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="2"
                d="M1 5.917 5.724 10.5 15 1.5"
              />
            </svg>
            <span className="">{menu.name}</span>
          </li>
        ))}
      </ul>
    </div>
  );
}

import React, { useEffect, useState } from "react";
import * as cdiService from "../../services/cdiService";
import { Link } from "react-router-dom";

import { MdEdit } from "react-icons/md";
import DataTable from "react-data-table-component";
import { BsFillHospitalFill } from "react-icons/bs";

export default function ClinicDetails() {
  const [record, setRecord] = useState([]);

  const columns = [

    {
      name: "Clinique",
      selector: (row) => row.name,
      sortable: true,
    },

    {
      name: "Telephone",
      selector: (row) => row.telephone,
      sortable: true,
    },
    {
      name: "email",
      selector: (row) => row.email,
      sortable: true,
    },

    {
      name: "Modifier",
      cell: (row) => (
        <Link to={`/clinic/${row.id}`}>
          <MdEdit size={20} className="text-green-700" />
        </Link>
      ),
    },
  ];

  const clinicSettings = () => {
    cdiService.clinicInfo().then((res) => {
      setRecord(res.data);
    });
  };

  // const handleDelete = (row) => {
  //   Swal.fire({
  //     title: "Es-tu sûr de cette opération?",
  //     text: "Vous ne pourrez pas annuler cette opération!",
  //     icon: "warning",
  //     showCancelButton: true,
  //     confirmButtonColor: "#3085d6",
  //     cancelButtonColor: "#d33",
  //     confirmButtonText: "Oui, supprimez!",
  //   }).then((result) => {
  //     if (result.isConfirmed) {
  //       cdiService
  //         .deleteInsurance(row)
  //         .then((res) => {
  //           ListInsurances();
  //         })
  //         .catch((e) => console.log("erreur supprimer", e));
  //       Swal.fire("supprimé!", "L'assurance est supprimée.", "success");
  //     }
  //   });
  // };

  useEffect(() => {
    clinicSettings();
  }, []);

  const tableHeaderstyle = {
    headCells: {
      style: {
        fontWeight: "bold",
        fontSize: "14px",
        backgroundColor: "black",
        color: "white",
      },
    },
  };

  return (
    <div className="container">

        {
          record.length === 0 ? (
            <>
            <Link
            className=" text-white bg-black font-bold uppercase text-sm px-2 py-1  shadow hover:shadow-lg outline-none focus:outline-none mr-1  mb-1 ease-linear transition-all duration-150 rounded-md float-right w-[30px] h-[34px]"
            variant="outlined"
            Link
            to={"/clinic/add"}
          >
            <BsFillHospitalFill className="text-white font-extrabold mt-2" />
          </Link>
          </> 
          ):
       
        ''
        }
   

      <DataTable
        className="-mt-2"
        columns={columns}
        data={record}
        pagination
        fixedHeader
        fixedHeaderScrollHeight="450px"
        selectableRowsHighlight
        highlightOnHover
        customStyles={tableHeaderstyle}
        subHeader
        subHeaderAlign="left"
      />
      ​
    </div>
  );
}

import React, { useEffect, useState } from "react";
import * as cdiService from "../../services/cdiService";

import Select from "react-select";
import { useNavigate } from "react-router-dom";
import Header from "../../components/Header";
import SideBar2 from "../../components/SideBar2";

export default function NewPatient() {
  const navigate = useNavigate();
  const [profession, setProfession] = useState("");
  const [firstname, setFirstname] = useState("");
  const [lastname, setLastname] = useState("");
  const [email, setEmail] = useState("");
  const [telephone, setTelephone] = useState("");
  const [dob, setDob] = useState("");
  const [insurance_pourcentage, setInsurancePourcentage] = useState("");
  const [insurance_matricule, setInsuranceMatricule] = useState("");
  const [record, setRecord] = useState([]);
  const [insurance, setInsurance] = useState();
  const [gender, setGender] = useState("MALE");

  const allInsurances = () => {
    cdiService.insuranceList().then((res) => {
      setRecord(res.data);
    });
  };

  useEffect(() => {
    allInsurances();
  }, []);

  const assurances = [
    record.map((insurance) => {
      return {
        value: insurance.insurancename,
        label: insurance.insurancename,
      };
    }),
  ];

  const handleItem = (item) => {
    setInsurance(item.value);
  };

  const options = [
    { value: "MALE", label: "Male" },
    { value: "FEMELE", label: "Femele" },
    { value: "OTHER", label: "Autre" },
  ];

  const handleSelectChange = ({ value }) => {
    console.log("SELECTED GENDER ", value);
    setGender(value);
  };

  const handleInputChange = (e) => {
    const regex = /^[0-9\b]+$/;
    if (e.target.value === "" || regex.test(e.target.value)) {
      setInsurancePourcentage(e.target.value);
    }
  };

  const data = {
    firstname,
    lastname,
    dob,
    profession,
    email,
    gender,
    telephone,
    insurance,
    insurance_pourcentage,
    insurance_matricule,
  };

  console.log("PATIENT DATA + ", data);

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      cdiService
        .addPatient(data)
        .then(
          (res) => {
            navigate("/patient");
          },
          (error) => {
            console.log(error);
          }
        )
        .catch((error) => {
          console.log("ERROR", error.res);
        });
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <div class="min-h-screen flex flex-col flex-auto flex-shrink-0 antialiased bg-white dark:bg-gray-700 text-black dark:text-white">
      <Header />
      <SideBar2 />
      <div className="h-full ml-14 mt-14 mb-10 md:ml-64">
        <div className="grid grid-cols-1 lg:grid-cols-1 p-4 gap-2">
          <div className="bg-white rounded shadow-lg p-4 px-4 md:p-8 mb-6">
            <div className="grid gap-4 gap-y-2 text-sm grid-cols-1 lg:grid-cols-3">
              <div className="text-gray-600">
                <p className="font-medium text-lg">
                  Information Personnelles du patient
                </p>
                <p>Remplissez ce formulaire, s'il vout plait.</p>
              </div>

              <div className="lg:col-span-2">
                <form onSubmit={handleSubmit}>
                  <div className="grid gap-4 gap-y-2 text-sm grid-cols-1 md:grid-cols-5">
                    <div className="md:col-span-2 mt-2">
                      <label for="Status"> Sex</label>
                      <Select
                        required
                        placeholder="Assignez le sex"
                        options={options}
                        onChange={handleSelectChange}
                        isSearchable
                        autoFocus
                        noOptionsMessage={() => "No gender found..."}
                        styles={{
                          placeholder: (baseStyles, state) => ({
                            ...baseStyles,
                            color: "red",
                          }),
                          dropdownIndicator: () => ({
                            color: "red",
                          }),
                        }}
                      />
                    </div>

                    <div className="md:col-span-3">
                      <label for="address">Prénoms</label>
                      <input
                        type="text"
                        name="firstname"
                        value={firstname}
                        onChange={(e) => setFirstname(e.target.value)}
                        className="h-10 border mt-1 rounded px-4 w-full bg-gray-50"
                        placeholder=""
                        required
                      />
                    </div>

                    <div className="md:col-span-2">
                      <label for="city">Nom</label>
                      <input
                        type="text"
                        required
                        name="lastname"
                        value={lastname}
                        onChange={(e) => setLastname(e.target.value)}
                        className="h-10 border mt-1 rounded px-4 w-full bg-gray-50"
                        placeholder=""
                      />
                    </div>

                    <div className="md:col-span-3">
                      <label for="address">Email</label>
                      <input
                        type="email"
                        name="email"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                        className="h-10 border mt-1 rounded px-4 w-full bg-gray-50"
                        placeholder=""
                      />
                    </div>

                    <div className="md:col-span-2">
                      <label for="city">Profession</label>
                      <input
                        type="text"
                        name="lastname"
                        required
                        value={profession}
                        onChange={(e) => setProfession(e.target.value)}
                        className="h-10 border mt-1 rounded px-4 w-full bg-gray-50"
                        placeholder=""
                      />
                    </div>

                    <div className="md:col-span-3">
                      <label for="address">N° Téléphone</label>
                      <input
                        type="text"
                        name="telephone"
                        required
                        value={telephone}
                        onChange={(e) => setTelephone(e.target.value)}
                        className="h-10 border mt-1 rounded px-4 w-full bg-gray-50"
                        placeholder=""
                      />
                    </div>

                    <div className="md:col-span-2">
                      <label for="city"> Date de naissance</label>
                      <input
                        type="date"
                        required
                        value={dob}
                        onChange={(e) => setDob(e.target.value)}
                        className="h-10 border mt-1 rounded px-4 w-full bg-gray-50"
                        placeholder=""
                      />
                    </div>
                    <div className="md:col-span-3">
                      <label for="Assurance"> Assurance</label>
                      <Select
                        required
                        placeholder="Assignez l'assurance"
                        options={assurances[0]}
                        onChange={(item) => {
                          handleItem(item);
                        }}
                        noOptionsMessage={() => "No Insurance found..."}
                        styles={{
                          placeholder: (baseStyles, state) => ({
                            ...baseStyles,
                            color: "red",
                          }),
                          dropdownIndicator: () => ({
                            color: "red",
                          }),
                        }}
                      />
                    </div>

                    <div className="md:col-span-2 mt-1">
                      <label for="city">Couverture assurance en chiffre</label>
                      <input
                        type="number"
                        required
                        value={insurance_pourcentage}
                        onChange={handleInputChange}
                        className="h-10 border  rounded px-4 w-full bg-gray-50 mt-1"
                        placeholder="Couverture en chiffre "
                      />
                    </div>

                    <div className="md:col-span-3">
                      <label for="insuranceMatricule">
                        {" "}
                        Matricule du patient
                      </label>
                      <input
                        type="text"
                        required
                        value={insurance_matricule}
                        onChange={(e) => setInsuranceMatricule(e.target.value)}
                        className="transition-all flex items-center h-10 border mt-1 rounded px-4 w-full bg-gray-50"
                        placeholder=""
                      />
                    </div>
                  </div>
                  <div className="md:col-span-5 mt-6">
                    <button className="bg-white  text-black font-bold py-2 px-4 rounded w-full">
                      Sauvegarder
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import * as authService from "../services/authService";
import { AiOutlineEye, AiOutlineEyeInvisible } from "react-icons/ai";
import Swal from "sweetalert2";

const Login = () => {
  const [openToggle, setOpenToggle] = useState(false);
  // 
  const navigate = useNavigate();
  const [username, setEmail] = useState("");
  const [password, setPassword] = useState("");

  const Alert = () => {
    Swal.fire({
      icon: "error",
      title: "Error",
      text: "Something went wrong!",
      footer: "Invalid credential(s)",
      width: "400px",
      confirmButtonColor: "#000000",
      allowOutsideClick: false,
    });
  };

  const handleLogin = async (e) => {
    e.preventDefault();
    try {
      await authService.login(username.toLowerCase(), password.toLowerCase()).then(
        () => {
          navigate("/dashboardwelcome");
        },
        (error) => {
          console.log(error);
          Alert();
        }
      );
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <div className="">
      {/* component */}
      <div className="flex items-center justify-center h-screen">
        {/* Login Container */}

        <div className=" flex-col shadow-black w-[370px] h-[550px] bg-white text-green-500 px-6 py-14 shadow-xl rounded-2xl ">
          <div className="mb-8 flex justify-center">
            <img
              className="w-[370px] h-[200px] -mt-10 -mb-1"
              src="../../assets/logo.jpg"
              alt=""
            />
          </div>

          {/* <div className="mb-8 flex justify-center">
          </div> */}
          <div className="flex flex-col text-sm rounded-md">
            <input
              className="mb-5 rounded-[4px] border text-black p-3 hover:outline-none focus:outline-none hover:border-black "
              type="text"
              placeholder="Username"
              id="email"
              name="email"
              required
              value={username}
              onChange={(e) => setEmail(e.target.value)}
            />
            <input
              className="border text-black rounded-[4px] p-3 hover:outline-none focus:outline-none hover:border-black"
              type={openToggle ? "text" : "password"}
              placeholder="Password"
              required
              id="password"
              name="password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
            />

            <div
              className="text-2xl text-black absolute p-12 mt-8 ml-60"
              onClick={() => setOpenToggle(!openToggle)}
            >
              {openToggle ? <AiOutlineEye /> : <AiOutlineEyeInvisible />}
            </div>
          </div>

          <button
            className="mt-5 ml-2 w-[305px] border p-2 bg-white text-black rounded-[4px] font-bold scale-105 duration-300"
            type="submit"
            onClick={handleLogin}
          >
            Connexion
          </button>
          <div className="mt-5 flex justify-between text-md text-black font-semibold">
            <a href="!">Forgot password?</a>
            {/* <a href="!">Sign up</a> */}
          </div>
        </div>
      </div>

      {/* footer */}
    </div>
  );
};

export default Login;

import React from "react";
import * as cdiService from "../services/cdiService";

import {
  FaFacebookF,
  FaLinkedinIn,
  FaTwitterSquare,
  FaYoutubeSquare,
} from "react-icons/fa";
import { useEffect } from "react";
import { useState } from "react";
import { Link } from "react-router-dom";

export default function Footer() {
  const [record, setRecord] = useState([]);

  const ClinicInfo = () => {
    cdiService.clinicPublic().then((res) => {
      console.log("DATA", res.data);
      setRecord(res.data);
    });
  };

  useEffect(() => {
    ClinicInfo();
  }, []);
  return (
    <div>
      <section
        id="cta"
        className="bg-black text-white  font-bold px-4 py-2 transition"
      >
        {/* <!-- Flex Container --> */}
        {record.map((menu, index) => (
          <div
            className="container flex flex-col items-center justify-between px-6 py-2 mx-auto  md:flex-row md:space-y-0"
            key={index}
          >
            {/* <!-- Heading --> */}
            <p className="text-xl font-bold text-center text-white md:text-xl md:max-w-xl md:text-left">
              ©{" "}
              <span id="currentYear">
                {new Date().getUTCFullYear()}, {menu.name}.
              </span>
            </p>

            <div className="flex justify-between items-center">
              <Link to={`${menu.faceBook}`} target="_blank" className="m-6">
                <FaFacebookF size={30} />
              </Link>
              <Link to={`${menu.twitter}`} target="_blank" className="m-6">
                {" "}
                <FaTwitterSquare size={30} />{" "}
              </Link>
              <Link to={`${menu.linkedIn}`} target="_blank" className="m-6">
                <FaLinkedinIn size={30} />{" "}
              </Link>
              <Link to={`${menu.youTube}`} target="_blank" className="m-6">
                <FaYoutubeSquare size={30} />
              </Link>
             
            </div>
          </div>
        ))}
      </section>
    </div>
  );
}

import React, { useState } from "react";
import { MdOutlineDashboard } from "react-icons/md";
import { NavLink } from "react-router-dom";
import { BsArrowLeftShort } from "react-icons/bs";
import * as authService from "../services/authService";
import { admin, doc, cas, acc, patient } from "./Data/NewSidebarData";
import jwt_decode from "jwt-decode";

function SideBar2() {
  const [currentUser] = useState(authService.getCurrentUser());
  // With Spring boot we don't use the following userstate
  const [user] = useState(jwt_decode(currentUser.access));

  const [open, setOpen] = useState(true);

  return (
    <>
      {}

      <div class="fixed flex flex-col top-14 left-0 w-14 hover:w-64 md:w-64 dark:bg-gray-900 h-full bg-black text-white transition-all duration-300 border-none z-10 sidebar shadow-green-700 shadow-xl">
        <div class="overflow-y-auto overflow-x-hidden flex flex-col justify-between flex-grow">
          <BsArrowLeftShort
            className={`bg-white text-black text-2xl rounded-full absolute -right-3 top-9 border border-black cursor-pointer duration-300 ${
              !open && "rotate-180"
            }`}
            onClick={() => setOpen(!open)}
          />
          {currentUser ? (
            // Here we use currentUser and not user like this: currentUser.roles.toString()
            user.role === "ADMIN" ? (
              <>
                <ul className="mt-18 pt-6">
                  {admin.map((menu, index) => (
                    <li
                      key={index}
                      className={`text-white text-sm flex items-center gap-x-2 cursor-pointer pt-0  ml-4
                                rounded-md ${menu.spacing ? "mt-2" : "mt-2"} `}
                    >
                      <NavLink to={menu.path}>
                        <span className="text-2xl block float-left  text-white">
                          {/* <img src={`../../assets/${menu.src}.png`} alt="" /> */}
                          {menu.icon ? menu.icon : <MdOutlineDashboard />}
                        </span>
                        <span
                          className={`text-base font-semibold flex-1 duration-200 ml-4  text-white  ${
                            !open && "hidden"
                          }`}
                        >
                          {menu.title}
                        </span>
                      </NavLink>
                    </li>
                  ))}
                </ul>
              </>
            ) : // Here we user currentUser and not user like this: currentUser.roles.toString()

            user.role === "DOCTOR" ? (
              <>
                <ul className="pt-2 ">
                  {doc.map((menu, index) => (
                    <>
                      <li
                        key={index}
                        className={`text-white text-sm flex items-center gap-x-2 cursor-pointer p-2
            rounded-md ${menu.spacing ? "mt-9" : "mt-2"} `}
                      >
                        <NavLink to={menu.path}>
                          <span className="text-2xl block float-left text-white ">
                            {menu.icon ? (
                              menu.icon
                            ) : (
                              <MdOutlineDashboard className="bg-red-500" />
                            )}
                          </span>
                          <span
                            className={`text-base font-semibold flex-1 duration-200 ml-4 text-white  ${
                              !open && "hidden"
                            }`}
                          >
                            {menu.title}
                          </span>
                        </NavLink>
                      </li>
                    </>
                  ))}
                </ul>
              </>
            ) : user.role === "CASHIER" ? (
              <>
                <ul className="pt-2 ">
                  {cas.map((menu, index) => (
                    <>
                      <li
                        key={index}
                        className={`text-white text-sm flex items-center gap-x-2 cursor-pointer p-2
            rounded-md ${menu.spacing ? "mt-9" : "mt-2"} `}
                      >
                        <NavLink to={menu.path}>
                          <span className="text-2xl block float-left text-white ">
                            {menu.icon ? (
                              menu.icon
                            ) : (
                              <MdOutlineDashboard className="bg-red-500" />
                            )}
                          </span>
                          <span
                            className={`text-base font-semibold flex-1 duration-200 ml-4 text-white  ${
                              !open && "hidden"
                            }`}
                          >
                            {menu.title}
                          </span>
                        </NavLink>
                      </li>
                    </>
                  ))}
                </ul>
              </>
            ) : user.role === "ACCOUNTANT" ? (
              <>
                <ul className="pt-2 ">
                  {acc.map((menu, index) => (
                    <>
                      <li
                        key={index}
                        className={`text-white text-sm flex items-center gap-x-2 cursor-pointer p-2
            rounded-md ${menu.spacing ? "mt-4" : "mt-0"} `}
                      >
                        <NavLink to={menu.path}>
                          <span className="text-2xl block float-left text-white ">
                            {menu.icon ? (
                              menu.icon
                            ) : (
                              <MdOutlineDashboard className="bg-red-500" />
                            )}
                          </span>
                          <span
                            className={`text-base font-semibold flex-1 duration-200 ml-4 text-white  ${
                              !open && "hidden"
                            }`}
                          >
                            {menu.title}
                          </span>
                        </NavLink>
                      </li>
                    </>
                  ))}
                </ul>
              </>
            ) : user.role === "PATIENT" ? (
              <>
                <ul className="pt-2 ">
                  {patient.map((menu, index) => (
                    <>
                      <li
                        key={index}
                        className={`text-white text-sm flex items-center gap-x-2 cursor-pointer p-2
            rounded-md ${menu.spacing ? "mt-4" : "mt-0"} `}
                      >
                        <NavLink to={menu.path}>
                          <span className="text-2xl block float-left text-white ">
                            {menu.icon ? (
                              menu.icon
                            ) : (
                              <MdOutlineDashboard className="bg-red-500" />
                            )}
                          </span>
                          <span
                            className={`text-base font-semibold flex-1 duration-200 ml-4 text-white  ${
                              !open && "hidden"
                            }`}
                          >
                            {menu.title}
                          </span>
                        </NavLink>
                      </li>
                    </>
                  ))}
                </ul>
              </>
            ) : (
              ""
            )
          ) : (
            // <Redirect />
            ""
            
          )}

          <p class="mb-14 px-5 py-3 hidden md:block text-center text-xs">
            Copyright @2023
          </p>
        </div>
      </div>
    </>
  );
}

export default SideBar2;

import React, { useEffect, useState } from "react";
// import * as cdiService from "../../services/cdiService";
import { updateAdmins, adminDetailsUser } from "../../services/cdiService";
import Select from "react-select";
import { useNavigate, useParams } from "react-router-dom";
import Swal from "sweetalert2";
import Header from "../../components/Header";
import SideBar2 from "../../components/SideBar2";

export default function UpdateAdmin() {
  const { id } = useParams();
  console.log("User", id);
  

  const navigate = useNavigate();

  const [firstname] = useState("");
  const [lastname] = useState("");
  const [email] = useState("");
  const [telephone] = useState("");
  const [gender, setGender] = useState("");
  // const [role, setRole] = useState("");
  const [password] = useState("");

 

  const loadUserInfo = () => {
    adminDetailsUser(id).then((res) => {
      console.log("USER INFO", res.data);
      setData(res.data);
    });
  };

  useEffect(() => {
    loadUserInfo();
  }, []);

  const [data, setData] = useState({
    firstname,
    lastname,
    email,
    telephone,
    gender,
    // role,
    password
  });

  console.log("USER DATA: ", data);

  const options = [
    { value: "MALE", label: "Male" },
    { value: "FEMELE", label: "Femele" },
    { value: "OTHER", label: "Autre" },
  ];

  const handleSelectChange = ({ value }) => {
    console.log("SELECTED GENDER ", value);
    setGender(value);
  };

  const updateUser = async (e) => {
    e.preventDefault();
    try {   
    Swal.fire({
      title: "Es-tu sûr de cette opération?",
      text: "Vous ne pourrez pas annuler cette opération!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Oui, mettre à jour!",
    }).then((result) => {
      if (result.isConfirmed) {
          updateAdmins(id, data)
          .then((res) => {
            navigate("/user/admin");
          })
          .catch((e) => console.log("erreur de la mise à jour", e));
        Swal.fire(
          "mettre à jour!",
          "Les informations ont été mises à jour.",
          "success"
        );
      }
    })
        .catch((error) => {
          console.log("ERROR", error.res);
        });
    } catch (err) {
      console.log(err);
    }

  };

  return (
    <div class="min-h-screen flex flex-col flex-auto flex-shrink-0 antialiased bg-white dark:bg-gray-700 text-black dark:text-white">
      <Header />
      <SideBar2 />
      <div className="h-full ml-14 mt-14 mb-10 md:ml-64">
        <div className="grid grid-cols-1 lg:grid-cols-1 p-4 gap-2">
          <div className="bg-white rounded shadow-lg p-4 px-4 md:p-8 mb-6">
            <div className="grid gap-4 gap-y-2 text-sm grid-cols-1 lg:grid-cols-3">
              <div className="text-gray-600">
                <p className="font-medium text-lg">
                  Information Personnelles du patient
                </p>
                <p>Remplissez ce formulaire, s'il vout plait.</p>
              </div>

              <div className="lg:col-span-2">
                <form
                onSubmit={updateUser}
                >
                  <div className="grid gap-4 gap-y-2 text-sm grid-cols-1 md:grid-cols-6">
                    <div className="md:col-span-3">
                      <label for="Status"> Sex</label>
                      <Select
                        placeholder="Assignez le sex"
                        options={options}
                        value={data.gender}
                        onChange={(handleSelectChange) =>
                          setData({
                            ...data,
                            gender: handleSelectChange.value,
                          })
                        }
                        isSearchable
                        autoFocus
                        noOptionsMessage={() => "No gender found..."}
                        styles={{
                          placeholder: (baseStyles, state) => ({
                            ...baseStyles,
                            color: "red",
                          }),
                          dropdownIndicator: () => ({
                            color: "red",
                          }),
                        }}
                      />
                    </div>
                   
                    <div className="md:col-span-3">
                      <label for="address">Prénoms</label>
                      <input
                        type="text"
                        name="firstname"
                        value={data.firstname}
                        onChange={(e) =>
                          setData({
                            ...data,
                            firstname: e.target.value,
                          })
                        }
                        className="h-10 border mt-1 rounded px-4 w-full bg-gray-50"
                        placeholder=""
                        required
                      />
                    </div>

                    <div className="md:col-span-3">
                      <label for="city">Nom</label>
                      <input
                        type="text"
                        required
                        name="lastname"
                        value={data.lastname}
                        onChange={(e) =>
                          setData({
                            ...data,
                            lastname: e.target.value,
                          })
                        }
                        className="h-10 border mt-1 rounded px-4 w-full bg-gray-50"
                        placeholder=""
                      />
                    </div>

                    <div className="md:col-span-3">
                      <label for="address">Email</label>
                      <input
                        type="email"
                        name="email"
                        required
                        value={data.email}
                        onChange={(e) =>
                          setData({
                            ...data,
                            email: e.target.value,
                          })
                        }
                        className="h-10 border mt-1 rounded px-4 w-full bg-gray-50"
                        placeholder=""
                      />
                    </div>

                    <div className="md:col-span-3">
                      <label for="address">N° Téléphone</label>
                      <input
                        type="text"
                        name="telephone"
                        required
                        value={data.telephone}
                        onChange={(e) =>
                          setData({
                            ...data,
                            telephone: e.target.value,
                          })
                        }
                        className="h-10 border mt-1 rounded px-4 w-full bg-gray-50"
                        placeholder="N° de téléphone"
                      />
                    </div>
             
                    <div className="md:col-span-3 mt-6">
                      <button className="bg-black hover:bg-green-700 text-white font-bold py-2 px-4 rounded w-full">
                      Mettre à jour
                      </button>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

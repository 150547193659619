import React, { useEffect, useState } from "react";
import * as cdiService from "../../services/cdiService";
import { Link } from "react-router-dom";
import { BsActivity } from "react-icons/bs";
import { MdDelete, MdEdit } from "react-icons/md";
import DataTable from "react-data-table-component";
import Swal from "sweetalert2";

export default function PatientList() {
  const [record, setRecord] = useState([]);
  const [filterrecords, setFilterRecords] = useState([]);

  const columns = [
    {
      name: "#",
      selector: (row) => row.id,
      sortable: true,
    },

    {
      name: " Prénoms & Nom",
      selector: (row) => row.firstname + " " + row.lastname,
      sortable: true,
    },

    {
      name: "Assurance",
      selector: (row) => row?.insurance,
      sortable: true,
    },
    // {
    //   name: "email",
    //   selector: (row) => row.email,
    //   sortable: true,
    // },
    {
      name: " N° Telephone",
      selector: (row) => row.telephone,
      sortable: true,
    },

    {
      name: "Modifier",
      cell: (row) => (
        <Link to={`/patient/update/${row.id}`}>
          <MdEdit size={20} className="text-green-700" />
        </Link>
      ),
    },
    {
      name: "Supprimer",
      cell: (row) => (
        <MdDelete
          size={20}
          className="text-red-700"
          onClick={() => handleDelete(row.id)}
        />
      ),
    },
  ];

  const allClients = () => {
    cdiService.patientList().then((res) => {
      console.log("ALL PATIENT", res.data);
      setRecord(res.data);
      setFilterRecords(res.data);
    });
  };

  const handleDelete = (row) => {
    Swal.fire({
      title: "Es-tu sûr?",
      text: "Vous ne pourrez pas annuler cette opération!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Oui, supprimez!",
    }).then((result) => {
      if (result.isConfirmed) {
        cdiService
          .deletePatient(row)
          .then((res) => {
            allClients();
          })
          .catch((e) => console.log("erreur supprimer", e));
        Swal.fire("supprimé!", "Le patient est supprimé.", "success");
      }
    });
  };

  useEffect(() => {
    allClients();
  }, []);

  const search = (event) => {
    const newData = filterrecords.filter(
      (row) =>
        (row.firstname + " " + row.lastname)
          .toLowerCase()
          .includes(event.target.value.toLowerCase()) ||
        row.email.toLowerCase().includes(event.target.value.toLowerCase()) ||
        row.telephone
          .toLowerCase()
          .includes(event.target.value.toLowerCase()) ||
        row.id.toString().includes(event.target.value.toLowerCase()) ||
        row.insurance
          .toLowerCase()
          .includes(event.target.value.toLowerCase())
    );
    setRecord(newData);
  };

  const tableHeaderstyle = {
    headCells: {
      style: {
        fontWeight: "bold",
        fontSize: "14px",
        backgroundColor: "black",
        color: "white",
      },
    },
  };

  return (
    <div class="grid grid-cols-1 lg:grid-cols-1 p-4 gap-4">
      <Link
        className=" text-white bg-black font-bold uppercase text-sm px-2 py-2  shadow hover:shadow-lg outline-none focus:outline-none mr-1  ease-linear transition-all duration-150 rounded-md float-right w-[120px] h-[34px] mb-0"
        variant="outlined"
        Link
        to={"/patient/new"}
      >
        Nouveau <BsActivity className="text-white font-extrabold -mt-4" />
      </Link>

      <DataTable
        className="-mt-5"
        columns={columns}
        data={record}
        pagination
        fixedHeader
        fixedHeaderScrollHeight="450px"
        selectableRowsHighlight
        highlightOnHover
        customStyles={tableHeaderstyle}
        subHeader
        subHeaderComponent={
          <input
            type="text"
            placeholder="search here..."
            className="w-[70%] h-[35px] float-left  rounded-md text-black 
            border border-[#0b1011] py-1 px-4 mb-4 -ml-6  bg-slate-50 mt-0"
            onChange={search}
          />
        }
        subHeaderAlign="left"
      />
 
    </div>
  );
}

import React, { useEffect, useState } from "react";
import * as cdiService from "../../services/cdiService";
import * as authService from "../../services/authService";
import jwt_decode from "jwt-decode";

import { Link, useNavigate, useParams } from "react-router-dom";
import {
  Card,
  CardHeader,
  CardBody,
  Typography,
} from "@material-tailwind/react";
import Header from "../../components/Header";
import SideBar2 from "../../components/SideBar2";
import { IoReturnUpBackSharp } from "react-icons/io5";

export default function ViewTreatment() {
  const [currentUser] = useState(authService.getCurrentUser());
  const [user] = useState(jwt_decode(currentUser.access));

  const navigate = useNavigate();

  const [selectedInterventions] = useState("");
  const [partinsurance] = useState("");
  const [partpatient] = useState("");
  const [newtreatmentstatus, setNewTreatmentStatus] = useState("Terminé");
  const [statuspayment] = useState("Impayé");
  const [treatmentamount] = useState("");
  const { id } = useParams();

  const loadTreatmentInfo = () => {
    cdiService.listSignleTreatment(id).then((res) => {
      console.log("TREATMENT INFO:", res.data);
      setData(res.data);
    });
  };

  useEffect(() => {
    loadTreatmentInfo();
  }, []);

  const [data, setData] = useState({
    interventions: selectedInterventions.toString(),
    user: user,
    patient: id,
    treatmentstatus: newtreatmentstatus,
    treatmentamount,
    statuspayment,
    partinsurance,
    partpatient,
  });

  console.log("GET ID", data.id);
  console.log("NEW TRET STATUS", newtreatmentstatus);
  const handleStatusChange = () => {
    setNewTreatmentStatus();
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      handleStatusChange();
      cdiService
        .updateTreatmentStatus(id)
        .then(
          (res) => {
            navigate("/treatment");
          },
          (error) => {
            console.log(error);
          }
        )
        .catch((error) => {
          console.log("ERROR", error.res);
        });
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <div class="min-h-screen flex flex-col flex-auto flex-shrink-0 antialiased bg-white dark:bg-gray-700 text-black dark:text-white">
      <Header />
      <SideBar2 />
      <div className="h-full ml-14 mt-14 mb-10 md:ml-64">
        <div className="grid grid-cols-1 lg:grid-cols-1 p-4 gap-2">
          <div className="bg-white rounded shadow-lg p-4 px-4 md:p-8 mb-6">
            <div className="grid gap-4 gap-y-2 text-sm grid-cols-1 lg:grid-cols-3">
              <div className="text-gray-600">
                <p className="font-medium text-lg">
                  N° du Traiment : {data.id}
                </p>
                <p className="font-medium text-lg ">
                  Statut du Traiment:{" "}
                  <span className="text-green-600">
                    {" "}
                    {data.treatmentstatus}
                  </span>
                </p>
                <p className="font-medium text-lg">
                  Montant du Traitement: {data.treatmentamount}
                </p>
                <p className="font-medium text-lg">
                  Couverture Assurance: {data.partinsurance}
                </p>
                <p className="font-medium text-lg">
                  Payé par le Patient: {data.partpatient}
                </p>
                <p className="font-medium text-lg">
                  Date: {data.registered_on}
                </p>

                {/*  .substring(0, 16)*/}

                {currentUser ? (
                  user.role.toString() === "CASHIER" ||
                  user.role.toString() === "DOCTOR" ||
                  user.role.toString() === "ADMIN" ? (
                    <>
                      <button
                        className="flex items-center gap-2 bg-black text-white font-bold py-2 px-4 rounded w-md align-middle uppercase mb-6 mt-6"
                        onClick={handleSubmit}
                      >
                        Terminé
                      </button>
                    </>
                  ) : user.role.toString() === "PATIENT" ? (
                    <></>
                  ) : (
                    ""
                  )
                ) : (
                  ""
                )}

                {/*  */}
              </div>

              <div className="lg:col-span-2 text-left">
                <Card className="w-full max-w-[48rem] flex-row">
                  <CardHeader
                    shadow={false}
                    floated={false}
                    className="m-0 w-2/5 shrink-0 rounded-r-none"
                  ></CardHeader>
                  <CardBody>
                    <Typography variant="h6" color="gray" className="mb-1">
                      <p className="text-left">
                        Dr. {data.user.firstname + " " + data.user.lastname}
                      </p>

                      <p className="text-left">
                        N° du Patient : {data.patient.id}
                      </p>
                    </Typography>
                    <Typography variant="h4" color="blue-gray" className="mb-2">
                      {data.patient.firstname + " " + data.patient.lastname}
                    </Typography>
                    <Typography color="gray" className="mb-8 font-normal">
                      {data.interventions}
                    </Typography>
                    <Link
                      variant="text"
                      className="flex items-center gap-2 bg-white text-black font-bold py-2 px-4 rounded w-full uppercase mb-6"
                      to={`/treatment`}
                    >
                      Traiments
                     <IoReturnUpBackSharp/>
                    </Link>
                  </CardBody>
                </Card>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

import React, { useState } from "react";
import { Navigate, Outlet } from "react-router-dom";
import * as authService from "../services/authService";

export const ProtectedRoutes = () => {
  const [currentUser] = useState(authService.getCurrentUser());
  
  return(
    currentUser ? <Outlet/> : <Navigate to="/login"/>
  )
};

import React from "react";

import { useState } from "react";
import { Link } from "react-router-dom";
import { useEffect } from "react";
import * as cdiService from "../../services/cdiService";
import DataTable from "react-data-table-component";
import { MdDelete, MdEdit } from "react-icons/md";
import * as authService from "../../services/authService";
import jwt_decode from "jwt-decode";
import { GiReceiveMoney } from "react-icons/gi";
import Swal from "sweetalert2";

export default function ExpensesList() {
  const [currentUser] = useState(authService.getCurrentUser());
  const [user] = useState(jwt_decode(currentUser.access));
  const [record, setRecord] = useState([]);
  const [filterrecords, setFilterRecords] = useState([]);

  const columns = [
    {
      name: "#",
      selector: (row) => row.id,
      sortable: true,
    },
    {
      name: "Depense",
      selector: (row) => row?.expense.name,
      sortable: true,
    },
    {
      name: "montant",
      selector: (row) => row?.amount,
      sortable: true,
    },
    {
      name: "Date",
      selector: (row) => row.created_on.substring(0, 10),
      sortable: true,
    },
    {
      name: "Modifier",
      cell: (row) => (
        <Link to={`/expense/${row.id}`}>
          <MdEdit size={20} className="text-green-700" />
        </Link>
      ),
    },
    {
      name: "Supprimer",
      cell: (row) => (
        <MdDelete
          size={20}
          className="text-red-700"
          onClick={() => handleDelete(row.id)}
        />
      ),
    },
  ];

  const handleDelete = (row) => {
    Swal.fire({
      title: "Es-tu sûr de cette opération?",
      text: "Vous ne pourrez pas annuler cette opération!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Oui, supprimez!",
    }).then((result) => {
      if (result.isConfirmed) {
        cdiService
          .deleteExpense(row)
          .then((res) => {
            ListExpenses();
          })
          .catch((e) => console.log("erreur de suppression", e));
        Swal.fire("supprimez!", "Le type de depense est supprimé.", "success");
      }
    });
  };

  const tableHeaderstyle = {
    headCells: {
      style: {
        fontWeight: "bold",
        fontSize: "14px",
        backgroundColor: "black",
        color: "white",
      },
    },
  };

  const ListExpenses = () => {
    cdiService.allExpenses().then((res) => {
      console.log("EXPENSES ", res.data);
      setRecord(res.data);
      setFilterRecords(res.data);
    });
  };

  useEffect(() => {
    ListExpenses();
  }, []);

  const search = (event) => {
    const newData = filterrecords.filter(
      (row) =>
        row.name.toLowerCase().includes(event.target.value.toLowerCase()) ||
        row.created_on
          .toLowerCase()
          .includes(event.target.value.toLowerCase()) ||
        row.id.toString().includes(event.target.value.toLowerCase())
    );
    setRecord(newData);
  };

  return (
    <div class="min-h-screen flex flex-col flex-auto flex-shrink-0 antialiased bg-white dark:bg-gray-700 text-black dark:text-white">
      <div className="md:col-span-2 mb-4">
        {currentUser ? (
          user.role.toString() === "DOCTOR" ||
          user.role.toString() === "ADMIN" ? (
            <>
              <Link
                className=" text-white bg-black font-bold uppercase text-sm px-2 py-2  shadow hover:shadow-lg outline-none focus:outline-none mr-1 ml-0 ease-linear transition-all duration-150 rounded-md  w-[140px] h-[34px] mb-4 float-left  "
                variant="outlined"
                Link
                to={"/new-expense"}
              >
                <span className="ml-8">Ajouter </span>
                <GiReceiveMoney className="text-white font-extrabold text-2xl -mt-5 mr-2" />
              </Link>
              <div className="w-full">
                <DataTable
                  className="-mt-2"
                  columns={columns}
                  data={record}
                  pagination
                  fixedHeader
                  fixedHeaderScrollHeight="450px"
                  selectableRowsHighlight
                  highlightOnHover
                  customStyles={tableHeaderstyle}
                  subHeader
                  subHeaderComponent={
                    <input
                      type="text"
                      placeholder="search here..."
                      className="w-[70%] h-[35px] float-left  rounded-md text-black
                border border-[#0b1011] py-1 px-4 mb-4 -ml-6  bg-slate-50 mt-0"
                      onChange={search}
                    />
                  }
                  subHeaderAlign="left"
                />
              </div>
            </>
          ) : (
            <Link
            className=" text-white bg-black font-bold uppercase text-sm px-2 py-2  shadow hover:shadow-lg outline-none focus:outline-none mr-1 ml-0 ease-linear transition-all duration-150 rounded-md  w-[200px] h-[34px] -mb-4 float-left  "
            variant="outlined"
            Link
            to={"/new-expense"}
          >

            <span className="ml-8">Ajouter </span>
            <GiReceiveMoney className="text-white font-extrabold text-2xl -mt-5 mr-2" />
            <p className="uppercase text-black">Nouvelle Depense</p>
          </Link>
          )
        ) : (
          ""
        )}
      </div>
    </div>
  );
}

import React, { useEffect, useState } from "react";
import * as cdiService from "../../services/cdiService";
import * as authService from "../../services/authService";

import { useNavigate, useParams } from "react-router-dom";
import Select from "react-select";
import jwt_decode from "jwt-decode";
import Header from "../../components/Header";
import SideBar2 from "../../components/SideBar2";

export default function NewTreatment() {
  const navigate = useNavigate();
  const [currentUser] = useState(authService.getCurrentUser());
  const [user] = useState(jwt_decode(currentUser.access));
  const [selectedInterventions, setSelectedInterventions] = useState("");
  const [record, setRecord] = useState([]);
  const [treatmentstatus, setStatus] = useState("Terminé");
  const [statuspayment, setStatusPayment] = useState("Impayé");
  const [treatmentamount, setTreatmentAmount] = useState("");
  const { id, insurance_percentage } = useParams();

  const allInterventions = () => {
    cdiService.interventionList().then((res) => {
      setRecord(res.data);
    });
  };
  console.log("USER", parseInt(user.id));
  console.log("PATIENT", parseInt(id));

  useEffect(() => {
    allInterventions();
  }, []);

  const data = {
    interventions: selectedInterventions.toString(),
    user: user.id,
    patient: parseInt(id),
    treatmentstatus,
    treatmentamount,
    statuspayment,
    partinsurance: treatmentamount * 0.01 * insurance_percentage,
    partpatient: Math.ceil(treatmentamount * (1 - 0.01 * insurance_percentage)),
  };

  const handleSelectChange = ({ value }) => {
    // console.log("SELECTED  TREATMENT STATUS ", value);
    setStatus(value);
  };

  const handlepaymentChange = (event) => {
    console.log(event.target.value);
    setStatusPayment(event.target.value);
  };

  console.log("DATA + ", data);

  const interventions = [
    record.map((intervention) => {
      return {
        value: intervention.price,
        label: intervention.name,
      };
    }),
  ];


  const handleSelect = (item) => {
    var array = [];
    var name = [];
    item.map((i) => {
      array.push(i.value);
      name.push(i.label);
      let sum = 0;
      for(let i = 0; i < array.length; i++)
      {
        sum += array[i]
      }
      console.log("TOTAL", sum)
      setTreatmentAmount(sum);
      setSelectedInterventions(name);
    }
    
    );
  };

  const options = [
    { value: "En-cours", label: "En-cours" },
    { value: "Terminé", label: "Terminé" },
  ];

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      cdiService
        .addTreatment(data)
        .then(
          (res) => {
            // console.log("CHECKING DATA", res.data);
            navigate("/treatment");
          },
          (error) => {
            console.log(error);
          }
        )
        .catch((error) => {
          console.log("ERROR", error.res);
        });
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <div class="min-h-screen flex flex-col flex-auto flex-shrink-0 antialiased bg-white dark:bg-gray-700 text-black dark:text-white">
      <Header />
      <SideBar2 />
      <div className="h-full ml-14 mt-14 mb-10 md:ml-64">
        <div className="grid grid-cols-1 lg:grid-cols-1 p-4 gap-2">
          <div className="bg-white rounded shadow-lg p-4 px-4 md:p-8 mb-6">
            <div className="grid gap-4 gap-y-2 text-sm grid-cols-1 lg:grid-cols-3 mt-10">
              <div className="text-gray-600">
                <p className="font-medium text-lg">Details tu traitement</p>
                <p>Please fill out all the fields.</p>
              </div>
              <div className="lg:col-span-2">
                <form onSubmit={handleSubmit}>
                  <div className="grid gap-4 gap-y-2 text-sm grid-cols-1 md:grid-cols-6">
                    <div className="md:col-span-3">
                      <Select
                        placeholder="Assignez le ou les soins"
                        isMulti
                        // required
                        options={interventions[0]}
                        onChange={(item) => {
                          handleSelect(item);
                        }}
                        noOptionsMessage={() => "Soin introuvable..."}
                        styles={{
                          placeholder: (baseStyles, state) => ({
                            ...baseStyles,
                            color: "red",
                          }),
                          dropdownIndicator: () => ({
                            color: "red",
                          }),
                        }}
                      />
                    </div>

                    <div className="md:col-span-2 -mt-6">
                    <div className="md:col-span-3 mt-2">
                      <label for="Status"> Statu du traitment</label>
                      <Select
                        required
                        placeholder="Assignez le statut du traiment"
                        options={options}
                        onChange={handleSelectChange}
                        isSearchable
                        autoFocus
                        noOptionsMessage={() => "Statut instrouvable..."}
                        styles={{
                          placeholder: (baseStyles, state) => ({
                            ...baseStyles,
                            color: "red",
                          }),
                          dropdownIndicator: () => ({
                            color: "red",
                          }),
                        }}
                      />
                    </div>

                    </div>
                  </div>

                  <div className="grid gap-4 gap-y-2 text-sm grid-cols-1 md:grid-cols-5">
                  
                    <div className="md:col-span-2 mt-7">
                      <button className="bg-white text-black font-bold py-2 px-4 rounded w-full">
                        Sauvegarder
                      </button>
                    </div>
                  </div>
                  <div className=" gap-4 gap-y-2 text-sm grid-cols-1 md:grid-cols-5 mt-8 hidden">
                    <span className="p-4 mt-1">Status du payment</span>
                    <input
                      type="radio"
                      id="Impayé"
                      name="choosepayment"
                      value="Impayé"
                      checked={statuspayment === "Impayé"}
                      onChange={handlepaymentChange}
                    />
                    <label htmlFor="Impayé" className="mr-1">
                      Impayé
                    </label>
                    <input
                      type="radio"
                      id="Payé"
                      name="choosepayment"
                      value="Payé"
                      disabled
                      onChange={handlepaymentChange}
                      checked={statuspayment === "Payé"}
                    />
                    <label htmlFor="Payé" className="mr-1">
                      Payé
                    </label>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

import React, { useEffect, useState } from "react";
import * as cdiService from "../../services/cdiService";
import * as authService from "../../services/authService";
import jwt_decode from "jwt-decode";
import { IoReturnUpBackSharp } from "react-icons/io5";
import { Link, useNavigate, useParams } from "react-router-dom";
import {
  Card,
  CardHeader,
  CardBody,
  Typography,
} from "@material-tailwind/react";
import Header from "../../components/Header";
import SideBar2 from "../../components/SideBar2";

export default function ViewMessage() {
  const [currentUser] = useState(authService.getCurrentUser());
  const [user] = useState(jwt_decode(currentUser.access));

  const navigate = useNavigate();

  const [selectedInterventions] = useState("");
  const [partinsurance] = useState("");
  const [partpatient] = useState("");
  const [newtreatmentstatus, setNewTreatmentStatus] = useState("Terminé");
  const [statuspayment] = useState("Impayé");
  const [treatmentamount] = useState("");
  const { id } = useParams();

  const loadMessage = () => {
    cdiService.viewMessage(id).then((res) => {
      console.log("Message INFO:", res.data);
      setData(res.data);
    });
  };

  useEffect(() => {
    loadMessage();
  }, []);

  const [data, setData] = useState({
    interventions: selectedInterventions.toString(),
    user: user,
    patient: id,
    treatmentstatus: newtreatmentstatus,
    treatmentamount,
    statuspayment,
    partinsurance,
    partpatient,
  });

  console.log("GET ID", data.id);
  console.log("NEW TRET STATUS", newtreatmentstatus);
  const handleStatusChange = () => {
    setNewTreatmentStatus();
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      handleStatusChange();
      cdiService
        .updateTreatmentStatus(id)
        .then(
          (res) => {
            navigate("/treatment");
          },
          (error) => {
            console.log(error);
          }
        )
        .catch((error) => {
          console.log("ERROR", error.res);
        });
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <div class="min-h-screen flex flex-col flex-auto flex-shrink-0 antialiased bg-white dark:bg-gray-700 text-black dark:text-white">
      <Header />
      <SideBar2 />
      <div className="h-full ml-14 mt-14 mb-10 md:ml-64">
        <div className="grid grid-cols-1 lg:grid-cols-1 p-4 gap-2">
          <div className="bg-white rounded shadow-lg p-4 px-4 md:p-8 mb-6">
            <div className="grid gap-4 gap-y-2 text-sm grid-cols-1 lg:grid-cols-3">
              <div className="text-gray-600">
                <p className="font-medium text-lg">N° du message : {data.id}</p>
                <p className="text-left">
                  {data.user.firstname + " " + data.user.lastname}
                </p>

                <p className="text-left">N° du Patient : {data.user.id}</p>

                <Link
                      variant="text"
                      className="flex items-center gap-2 bg-white  text-black font-bold py-2 px-4 rounded w-full uppercase mb-6"
                      to={`/message`}
                    >
                      Messages
                     <IoReturnUpBackSharp/>
                    </Link>
              </div>

              <div className="lg:col-span-2 text-left">
                <Card className="w-full max-w-[48rem] flex-row">
                  <CardHeader
                    shadow={false}
                    floated={false}
                    className="m-0 w-2/5 shrink-0 rounded-r-none"
                  ></CardHeader>
                  <CardBody>
                    <Typography
                      variant="h6"
                      color="gray"
                      className="mb-1"
                    ></Typography>
                    <Typography variant="h4" color="blue-gray" className="mb-2">
                      {data.message}
                    </Typography>
                    <Typography color="gray" className="mb-8 font-normal">
                      {data.interventions}
                    </Typography>
                 
                  </CardBody>
                </Card>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

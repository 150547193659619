import React, { useEffect, useState } from "react";
import * as cdiService from "../services/cdiService";

export default function DashboardData() {
  const [userrecord, setURecord] = useState([]);
  const [adminrecord, setAdminRecord] = useState([]);
  const [accrecord, setAccRecord] = useState([]);
  const [docrecord, setDocRecord] = useState([]);
  const [patientUsers, setPatientUsers] = useState([]);
  const [casrecord, setCashRecord] = useState([]);
  const [patientrecord, setPatientRecord] = useState([]);
  const [insuredPatients, setInsuredPatients] = useState([]);
  const [uninsuredPatients, setUnInsuredPatients] = useState([]);
  const [treatmentrecord, setTreatmentRecord] = useState([]);
  const [completeTreatment, setCompleteTreatment] = useState([]);
  const [uncompleteTreatment, setUnCompleteTreatment] = useState([]);
  const [bills, setBills] = useState([]);

  const allUsers = () => {
    cdiService.usersList().then((res) => {
      let doctors = res.data.filter((x) => x.role === "DOCTOR").length;
      let accountants = res.data.filter((x) => x.role === "ACCOUNTANT").length;
      let cashiers = res.data.filter((x) => x.role === "CASHIER").length;
      let patients = res.data.filter((x) => x.role === "PATIENT").length;

      let adminnistrators = res.data.filter((x) => x.role === "ADMIN").length;
      setURecord(res.data.length);
      setAdminRecord(adminnistrators);
      setAccRecord(accountants);
      setCashRecord(cashiers);
      setDocRecord(doctors);
      setPatientUsers(patients);
    });
  };

  const allPatients = () => {
    cdiService.patientList().then((res) => {
      let insured = res.data.filter((x) => x.insurance !== "NA").length;
      let uninsured = res.data.filter((x) => x.insurance === "NA").length;
      setPatientRecord(res.data.length);
      setInsuredPatients(insured);
      setUnInsuredPatients(uninsured);
    });
  };

  const allTreatments = () => {
    cdiService.allTreatments().then((res) => {
      let completedTreatments = res.data.filter(
        (x) => x.treatmentstatus === "Terminé"
      ).length;
      let uncompletedTreatments = res.data.filter(
        (x) => x.treatmentstatus !== "Terminé"
      ).length;
      let treatments = res.data.length;
      setTreatmentRecord(treatments);
      setCompleteTreatment(completedTreatments);
      setUnCompleteTreatment(uncompletedTreatments);
    });
  };

  const ListPayments = () => {
    cdiService.allBills().then((res) => {
      setBills(res.data.length);
    });
  };

  useEffect(() => {
    allUsers();
    allPatients();
    allTreatments();
    ListPayments();
  }, []);

  return (
    <>
      <div class="bg-black dark:bg-gray-800 shadow-lg rounded-md flex items-center justify-between p-3 border-b-4 border-green-600 dark:border-gray-600 text-white font-medium group">
        <div class="flex justify-center items-center w-14 h-14 bg-white rounded-full transition-all duration-300 transform group-hover:rotate-12">
          <svg
            width="30"
            height="30"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
            class="stroke-current text-green-800 dark:text-gray-800 transform transition-transform duration-500 ease-in-out"
          >
            <path
              stroke-linecap="round"
              stroke-linejoin="round"
              stroke-width="2"
              d="M13 7h8m0 0v8m0-8l-8 8-4-4-6 6"
            ></path>
          </svg>
        </div>
        <p className="text-2xl">USERS</p>
        <div class="text-right">
          <div class="p-0">
            <ul class="flex flex-col gap-4">
              <li class="flex items-center gap-4">
                <span class="rounded-full border border-white/20 bg-white/20 p-1">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke-width="2"
                    stroke="currentColor"
                    aria-hidden="true"
                    class="h-3 w-3"
                  >
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      d="M4.5 12.75l6 6 9-13.5"
                    ></path>
                  </svg>
                </span>

                <span class="-ml-4 mt-1 pl-2"> {userrecord} Active users</span>
              </li>
              <li class="flex items-center gap-4">
                <span class="rounded-full border border-white/20 bg-white/20 p-1">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke-width="2"
                    stroke="currentColor"
                    aria-hidden="true"
                    class="h-3 w-3"
                  >
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      d="M4.5 12.75l6 6 9-13.5"
                    ></path>
                  </svg>
                </span>

                <span class="-ml-4 mt-1 pl-2">
                  {adminrecord} Administrateur(s)
                </span>
              </li>

              <li class="flex items-center gap-4">
                <span class="rounded-full border border-white/20 bg-white/20 p-1">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke-width="2"
                    stroke="currentColor"
                    aria-hidden="true"
                    class="h-3 w-3"
                  >
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      d="M4.5 12.75l6 6 9-13.5"
                    ></path>
                  </svg>
                </span>

                <span class="-ml-4 mt-1 pl-2">{accrecord} Contable(s)</span>
              </li>
              <li class="flex items-center gap-4">
                <span class="rounded-full border border-white/20 bg-white/20 p-1">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke-width="2"
                    stroke="currentColor"
                    aria-hidden="true"
                    class="h-3 w-3"
                  >
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      d="M4.5 12.75l6 6 9-13.5"
                    ></path>
                  </svg>
                </span>

                <span class="-ml-4 mt-1 pl-2">{docrecord} Docteur(s)</span>
              </li>
              <li class="flex items-center gap-4">
                <span class="rounded-full border border-white/20 bg-white/20 p-1">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke-width="2"
                    stroke="currentColor"
                    aria-hidden="true"
                    class="h-3 w-3"
                  >
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      d="M4.5 12.75l6 6 9-13.5"
                    ></path>
                  </svg>
                </span>

                <span class="-ml-4 mt-1 pl-2">{casrecord} Caissier(s)</span>
              </li>
              <li class="flex items-center gap-4">
                <span class="rounded-full border border-white/20 bg-white/20 p-1">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke-width="2"
                    stroke="currentColor"
                    aria-hidden="true"
                    class="h-3 w-3"
                  >
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      d="M4.5 12.75l6 6 9-13.5"
                    ></path>
                  </svg>
                </span>

                <span class="-ml-4 mt-1 pl-2">{patientUsers} Patients</span>
              </li>
            </ul>
          </div>
        </div>
      </div>

      <div class="bg-black dark:bg-gray-800 shadow-lg rounded-md flex items-center justify-between p-3 border-b-4 border-green-600 dark:border-gray-600 text-white font-medium group">
        <div class="flex justify-center items-center w-14 h-14 bg-white rounded-full transition-all duration-300 transform group-hover:rotate-12">
          <svg
            width="30"
            height="30"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
            class="stroke-current text-green-600 dark:text-gray-800 transform transition-transform duration-500 ease-in-out"
          >
            <path
              stroke-linecap="round"
              stroke-linejoin="round"
              stroke-width="2"
              d="M17 20h5v-2a3 3 0 00-5.356-1.857M17 20H7m10 0v-2c0-.656-.126-1.283-.356-1.857M7 20H2v-2a3 3 0 015.356-1.857M7 20v-2c0-.656.126-1.283.356-1.857m0 0a5.002 5.002 0 019.288 0M15 7a3 3 0 11-6 0 3 3 0 016 0zm6 3a2 2 0 11-4 0 2 2 0 014 0zM7 10a2 2 0 11-4 0 2 2 0 014 0z"
            ></path>
          </svg>
        </div>
        <p className="text-2xl">TRAITEMENTS</p>

        <div class="text-right">
          <div className="text-start">
           
            <div className="p-0">
              <ul className="flex flex-col gap-4 text-center">
                <li className="flex items-center gap-4">
                  <span className="rounded-full border border-white/20 bg-white/20 p-1">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke-width="2"
                      stroke="currentColor"
                      aria-hidden="true"
                      className="h-3 w-3"
                    >
                      <path
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        d="M4.5 12.75l6 6 9-13.5"
                      ></path>
                    </svg>
                  </span>

                  <span className="-ml-4 mt-0 pl-2">
                    {treatmentrecord} Traitement(s)
                  </span>
                </li>
                <li className="flex items-center gap-4">
                  <span className="rounded-full border border-white/20 bg-white/20 p-1">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke-width="2"
                      stroke="currentColor"
                      aria-hidden="true"
                      class="h-3 w-3"
                    >
                      <path
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        d="M4.5 12.75l6 6 9-13.5"
                      ></path>
                    </svg>
                  </span>

                  <span className="-ml-4 mt-0 pl-2">
                    {completeTreatment} Terminés
                  </span>
                </li>

                <li className="flex items-center gap-4">
                  <span className="rounded-full border border-white/20 bg-white/20 p-1">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke-width="2"
                      stroke="currentColor"
                      aria-hidden="true"
                      className="h-3 w-3"
                    >
                      <path
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        d="M4.5 12.75l6 6 9-13.5"
                      ></path>
                    </svg>
                  </span>

                  <span className="-ml-4 mt-1 pl-2">
                    {uncompleteTreatment} En cours
                  </span>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>

      <div class="bg-black dark:bg-gray-800 shadow-lg rounded-md flex items-center justify-between p-3 border-b-4 border-green-600 dark:border-gray-600 text-white font-medium group">
        <div class="flex justify-center items-center w-14 h-14 bg-white rounded-full transition-all duration-300 transform group-hover:rotate-12">
          <svg
            width="30"
            height="30"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
            class="stroke-current text-green-800 dark:text-gray-800 transform transition-transform duration-500 ease-in-out"
          >
            <path
              stroke-linecap="round"
              stroke-linejoin="round"
              stroke-width="2"
              d="M16 11V7a4 4 0 00-8 0v4M5 9h14l1 12H4L5 9z"
            ></path>
          </svg>
        </div>
        <p className="text-2xl">PATIENTS & FACTURES</p>
        <div class="text-right">
        
          <div class="p-0">
            <ul class="flex flex-col gap-4">
              <li class="flex items-center gap-4">
                <span class="rounded-full border border-white/20 bg-white/20 p-1">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke-width="2"
                    stroke="currentColor"
                    aria-hidden="true"
                    class="h-3 w-3"
                  >
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      d="M4.5 12.75l6 6 9-13.5"
                    ></path>
                  </svg>
                </span>

                <span class="-ml-4 mt-1 pl-2">{patientrecord} Patients </span>
              </li>
              <li class="flex items-center gap-4">
                <span class="rounded-full border border-white/20 bg-white/20 p-1">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke-width="2"
                    stroke="currentColor"
                    aria-hidden="true"
                    class="h-3 w-3"
                  >
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      d="M4.5 12.75l6 6 9-13.5"
                    ></path>
                  </svg>
                </span>

                <span class="-ml-4 mt-1 pl-2">{insuredPatients} Assurés</span>
              </li>

              <li class="flex items-center gap-4">
                <span class="rounded-full border border-white/20 bg-white/20 p-1">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke-width="2"
                    stroke="currentColor"
                    aria-hidden="true"
                    class="h-3 w-3"
                  >
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      d="M4.5 12.75l6 6 9-13.5"
                    ></path>
                  </svg>
                </span>

                <span class="-ml-4 mt-1 pl-2">
                  {uninsuredPatients} Non assurés
                </span>
              </li>

              <li class="flex items-center gap-4">
                <span class="rounded-full border border-white/20 bg-white/20 p-1">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke-width="2"
                    stroke="currentColor"
                    aria-hidden="true"
                    class="h-3 w-3"
                  >
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      d="M4.5 12.75l6 6 9-13.5"
                    ></path>
                  </svg>
                </span>

                <span class="-ml-4 mt-1 pl-2">{bills} Factures réglée(s)</span>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </>
  );
}

import React, { useEffect, useState } from "react";
import * as cdiService from "../../services/cdiService";

import { Chart as ChartJs, BarElement } from "chart.js/auto";
import { Bar, Line } from "react-chartjs-2";
import Header from "../../components/Header";
import SideBar2 from "../../components/SideBar2";
import ReportWelcome from "./ReportWelcome";

ChartJs.register(BarElement);

var options = {
  responsive: true,

  animations: {
    tension: {
      duration: 1000,
      easing: "linear",
      from: 1,
      to: 0,
      loop: true,
    },
  },
  scales: {
    y: {
      beginAtZero: true,
    },
  },
};

export default function ReportDataTreatment() {
  const [rdv, setRdv] = useState([]);
  const [treatments, setMyTreatments] = useState([]);

  const allTreatments = () => {
    cdiService.allTreatments().then((res) => {
      setMyTreatments(res.data);
    });
  };

  const ListRdv = () => {
    cdiService.allAppointments().then((res) => {
      // console.log("RDV DATA", res.data);
      setRdv(res.data);
    });
  };
  let rdvarr = rdv;

  useEffect(() => {
    ListRdv();
    allTreatments();
  }, []);

  function findOcc(arr, key) {
    let arr2 = [];
    arr.forEach((x) => {
      // Checking if there is any object in arr2
      // which contains the key value
      if (
        arr2.some((val) => {
          return val[key] === x[key];
        })
      ) {
        // If yes! then increase the occurrence by 1
        arr2.forEach((k) => {
          if (k[key] === x[key]) {
            k["occurrence"]++;
          }
        });
      } else {
        // If not! Then create a new object initialize
        // it with the present iteration key's value and
        // set the occurrence to 1
        let a = {};
        a[key] = x[key];
        a["occurrence"] = 1;
        arr2.push(a);
      }
    });
    return arr2;
  }

  function findRdvOcc(rdvarr, rdvkey) {
    let arr2 = [];
    rdvarr.forEach((x) => {
      if (
        arr2.some((val) => {
          return val[rdvkey] === x[rdvkey];
        })
      ) {
        arr2.forEach((k) => {
          if (k[rdvkey] === x[rdvkey]) {
            k["occurrence"]++;
          }
        });
      } else {
        let a = {};
        a[rdvkey] = x[rdvkey];
        a["occurrence"] = 1;
        arr2.push(a);
      }
    });
    return arr2;
  }

  let rdvkey = "status";
  console.log(
    "PRINTING OCCURENCES OF EACH EACH RDV ",
    findRdvOcc(rdvarr, rdvkey)
  );

  var infordv = {
    labels: findOcc(rdvarr, rdvkey).map((x) => x.status),
    datasets: [
      {
        label: "# Repartition des rendez-vous",
        data: findOcc(rdvarr, rdvkey).map((x) => x.occurrence),
        backgroundColor: [
          "rgb(255, 0, 0)",
          "rgb(148,0,211)",
          "rgb(0,0,255)",
          "rgb(255,255,0)",
          "rgb(255,165,0)",
          "rgb(0,128,0)",
          "rgb(255,225,107)",
          "rgb(250,51,105)",
        ],
        borderColor: ["rgb(0, 0, 0)"],
        borderWidth: 1,
      },
    ],
  };

  console.log("ALL TREATMENTS", treatments);

  function findTreatStatusOcc(treatments, key1) {
    let arr2 = [];
    treatments.forEach((x) => {
      if (
        arr2.some((val) => {
          return val[key1] === x[key1];
        })
      ) {
        arr2.forEach((k) => {
          if (k[key1] === x[key1]) {
            k["occurrence"]++;
          }
        });
      } else {
        let a = {};
        a[key1] = x[key1];
        a["occurrence"] = 1;
        arr2.push(a);
      }
    });
    return arr2;
  }
  function findTreatPayStatusOcc(treatments, key2) {
    let arr2 = [];
    treatments.forEach((x) => {
      if (
        arr2.some((val) => {
          return val[key2] === x[key2];
        })
      ) {
        arr2.forEach((k) => {
          if (k[key2] === x[key2]) {
            k["occurrence"]++;
          }
        });
      } else {
        let a = {};
        a[key2] = x[key2];
        a["occurrence"] = 1;
        arr2.push(a);
      }
    });
    return arr2;
  }

  let key1 = "treatmentstatus";

  let key2 = "statuspayment"

  console.log(
    "OCCURENCES OF EACH TREATMENT ",
    findTreatStatusOcc(treatments, key1)
  );

  console.log(
    "OCCURENCES OF EACH TREATMENT STATUS",
    findTreatStatusOcc(treatments, key2)
  );

  var info1 = {
    labels: findTreatStatusOcc(treatments, key1).map((x) => x.treatmentstatus),
    datasets: [
      {
        label: "# Repartition des status des traitements",
        data: findTreatStatusOcc(treatments, key1).map((x) => x.occurrence),
        backgroundColor: [
          "rgb(255, 0, 0)",
          "rgb(148,0,211)",
          "rgb(0,0,255)",
          "rgb(255,255,0)",
          "rgb(255,165,0)",
          "rgb(0,128,0)",
          "rgb(255,225,107)",
          "rgb(250,51,105)",
        ],
        borderColor: ["rgb(0, 0, 0)"],
        borderWidth: 1,
      },
    ],
  };

  var paymentStatus = {
    labels: findTreatPayStatusOcc(treatments, key2).map((x) => x.statuspayment),
    datasets: [
      {
        label: "# Repartition des status des traitements",
        data: findTreatPayStatusOcc(treatments, key2).map((x) => x.occurrence),
        backgroundColor: [
          "rgb(255, 0, 0)",
          "rgb(148,0,211)",
          "rgb(0,0,255)",
          "rgb(255,255,0)",
          "rgb(255,165,0)",
          "rgb(0,128,0)",
          "rgb(255,225,107)",
          "rgb(250,51,105)",
        ],
        borderColor: ["rgb(0, 0, 0)"],
        borderWidth: 1,
      },
    ],
  };

  return (
    <div className="min-h-screen flex flex-col flex-auto flex-shrink-0 antialiased bg-white dark:bg-gray-700 text-black dark:text-white">
      <Header />
      <SideBar2 />
      <div className="h-full ml-14 mt-14 mb-10 md:ml-64">
        <ReportWelcome />

        <div className="grid grid-cols-1 lg:grid-cols-1 p-4 gap-2 mt-10">
          <div className="grid grid-cols-2 gap-4">
            <div className="">
              <Bar data={info1} height={100} options={options} />
            </div>
            <div className="">
              <Line data={paymentStatus} height={100} options={options} />
            </div>
            <div className="">
              <Bar data={infordv} height={100} options={options} />
            </div>
            
          </div>
        </div>
      </div>
    </div>
  );
}

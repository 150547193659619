import React, { useEffect, useState } from "react";
import * as cdiService from "../../services/cdiService";
import * as authService from "../../services/authService";
import { Link } from "react-router-dom";
import { BsActivity } from "react-icons/bs";
import { MdDelete, MdEdit } from "react-icons/md";
import { FaEnvelopeCircleCheck } from "react-icons/fa6";
import DataTable from "react-data-table-component";
import Swal from "sweetalert2";
import jwt_decode from "jwt-decode";
export default function AppointmentList() {
  const [currentUser] = useState(authService.getCurrentUser());
  const [user] = useState(jwt_decode(currentUser.access));

  const [record, setRecord] = useState([]);
  const [patrdv, setPatrdv] = useState([]);
  const [patientfilterrecords, setpatientfilterrecords] = useState([]);

  const [filterrecords, setFilterRecords] = useState([]);
  const [adminCashierRecord, setAdminCashierRecord] = useState([]);
  const [adminCashierfilterrecords, setAdminCashierFilterRecords] = useState(
    []
  );

  console.log("CURRENT USER ID", user.id);
  const handleDelete = (row) => {
    Swal.fire({
      title: "Es-tu sûr?",
      text: "Vous ne pourrez pas annuler cette opération!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Oui, supprime!",
    }).then((result) => {
      if (result.isConfirmed) {
        cdiService
          .deleteAppointment(row)
          .then((res) => {
            allAppointments();
          })
          .catch((e) => console.log("erreur supprimer", e));
        Swal.fire("supprimé!", "Le rendezvous est supprimé.", "success");
      }
    });
  };

  const columns = [
    {
      name: "# Rendez-vous",
      selector: (row) => row.id,
      sortable: true,
    },

    {
      name: " Prénoms & Nom",
      selector: (row) => row.patient.firstname + " " + row.patient.lastname,
      sortable: true,
    },
    {
      name: "Jour",
      selector: (row) => row.rendezvousdate,
      sortable: true,
    },
    {
      name: "Heure",
      selector: (row) => row.rendezvoustime.substring(0, 5),
      sortable: true,
    },
    {
      name: "Statut",
      selector: (row) => row.status,
      sortable: true,
    },

    {
      name: "Modifier",
      cell: (row) => (
        <Link to={`/appointment/update/${row.id}`}>
          <MdEdit size={20} className="text-green-700" />
        </Link>
      ),
    },

    {
      name: "Supprimer",
      cell: (row) => (
        <MdDelete
          size={20}
          className="text-red-700"
          onClick={() => handleDelete(row.id)}
        />
      ),
    },
  ];

  const patientcolumns = [
    {
      name: "# Du rendez-vous",
      selector: (row) => row.id,
      sortable: true,
    },

    {
      name: " Prénoms & Nom",
      selector: (row) => row.patient.firstname + " " + row.patient.lastname,
      sortable: true,
    },
    {
      name: "Jour",
      selector: (row) => row.rendezvousdate,
      sortable: true,
    },
    {
      name: "Heure",
      selector: (row) => row.rendezvoustime.substring(0, 5),
      sortable: true,
    },
    {
      name: "Statut",
      selector: (row) => row.status,
      sortable: true,
    },
  ];

  const allAppointments = () => {
    cdiService.allAppointments().then((res) => {
      console.log("TOTAL APPOINTMENTS", res.data);
      let rdv = res.data.filter(
        (x) => x.status !== "Terminé" && x.user.id === user.id
      );

      let patrdv = res.data.filter(
        (x) => x.status !== "Terminé" && x.patient.id === user.patient_id
      );
      let allrdv = res.data.filter((x) => x.status !== "Terminé");
      // console.log("TOTAL NUMBER OF APPOINTMENTS", rdv.length);
      console.log("CURRENT USER APPOINTMENT", patrdv);
      console.log("CURRENT USER PATIENT ID:", user.patient_id);

      setRecord(rdv);
      setPatrdv(patrdv);
      setFilterRecords(rdv);
      setAdminCashierRecord(allrdv);
      setAdminCashierFilterRecords(allrdv);
    });
  };

  useEffect(() => {
    allAppointments();
  }, []);

  const search = (event) => {
    const newData = filterrecords.filter(
      (row) =>
        (row.patient.firstname + " " + row.patient.lastname)
          .toLowerCase()
          .includes(event.target.value.toLowerCase()) ||
        row.commentaire
          .toLowerCase()
          .includes(event.target.value.toLowerCase()) ||
        row.rendezvousdate
          .toLowerCase()
          .includes(event.target.value.toLowerCase()) ||
        row.rendezvoustime
          .toLowerCase()
          .includes(event.target.value.toLowerCase()) ||
        row.id.toString().includes(event.target.value.toLowerCase())
    );
    setRecord(newData);
  };

  const search1 = (event) => {
    const newData = adminCashierfilterrecords.filter(
      (row) =>
        (row.patient.firstname + " " + row.patient.lastname)
          .toLowerCase()
          .includes(event.target.value.toLowerCase()) ||
        row.commentaire
          .toLowerCase()
          .includes(event.target.value.toLowerCase()) ||
        row.rendezvousdate
          .toLowerCase()
          .includes(event.target.value.toLowerCase()) ||
        row.rendezvoustime
          .toLowerCase()
          .includes(event.target.value.toLowerCase()) ||
        row.id.toString().includes(event.target.value.toLowerCase())
    );
    setAdminCashierRecord(newData);
  };

  const search2 = (event) => {
    const newData = patientfilterrecords.filter((row) =>
      row.patient.id.toLowerCase().match(event.target.value.toLowerCase())
    );
    setpatientfilterrecords(newData);
  };

  const tableHeaderstyle = {
    headCells: {
      style: {
        fontWeight: "bold",
        fontSize: "14px",
        backgroundColor: "black",
        color: "white",
      },
    },
  };

  return (
    <div class="grid grid-cols-1 lg:grid-cols-1 p-4 gap-4">
      <div className="md:col-span-2">
        {currentUser ? (
          user.role.toString() === "DOCTOR" ||
          user.role.toString() === "ADMIN" ? (
            <>
              <Link
                className="text-white bg-black font-bold uppercase text-sm px-2 py-2  shadow hover:shadow-lg outline-none focus:outline-none ml-4 -mb-6 ease-linear transition-all duration-150 rounded-md float-left w-[190px] h-[34px]"
                variant="outlined"
                Link
                to={"/appointment/get-patient"}
              >
                <span className="ml-8"> Voir les patients </span>

                <BsActivity className="text-white font-extrabold text-2xl -mt-5 " />
              </Link>
            </>
          ) 
          :  user.role.toString() === "PATIENT" ? (
            <>
             <Link
                className="text-white bg-black font-bold uppercase text-sm px-2 py-2  shadow hover:shadow-lg outline-none focus:outline-none ml-4 -mb-6 ease-linear transition-all duration-150 rounded-md float-left w-[120px] h-[34px]"
                variant="outlined"
                Link
                to={"/send-message"}
              >
                <span className="ml-8">Message</span>

                <FaEnvelopeCircleCheck className="text-white font-extrabold text-2xl -mt-5 " />
              </Link>
            </>
          ) : (
            ""
          )
        ) : (
          ""
        )}
      </div>
      <div class="grid grid-cols-1 lg:grid-cols-1 p-4 gap-4">
        {currentUser ? (
          user.role.toString() === "DOCTOR" ? (
            <>
              <DataTable
                className="-mt-2"
                columns={columns}
                data={record}
                pagination
                fixedHeader
                fixedHeaderScrollHeight="450px"
                selectableRowsHighlight
                highlightOnHover
                customStyles={tableHeaderstyle}
                subHeader
                subHeaderComponent={
                  <input
                    type="text"
                    placeholder="search here..."
                    className="w-[70%] h-[35px] float-left  rounded-md text-black 
            border border-[#0b1011] py-1 px-4 mb-0 -ml-6  bg-slate-50 mt-0"
                    onChange={search}
                  />
                }
                subHeaderAlign="left"
              />
            </>
          ) : user.role.toString() === "CASHIER" ||
            user.role.toString() === "ADMIN" ? (
            <>
              <DataTable
                className="-mt-2"
                columns={columns}
                data={adminCashierRecord}
                pagination
                fixedHeader
                fixedHeaderScrollHeight="450px"
                selectableRowsHighlight
                highlightOnHover
                customStyles={tableHeaderstyle}
                subHeader
                subHeaderComponent={
                  <input
                    type="text"
                    placeholder="search here..."
                    className="w-[70%] h-[35px] float-left  rounded-md text-black 
                  border border-[#0b1011] py-1 px-4 -mb-1 -ml-6 -mt-1 bg-slate-50 "
                    onChange={search1}
                  />
                }
                subHeaderAlign="left"
              />
            </>
          ) : user.role.toString() === "PATIENT" ? (
            <>
              <DataTable
                className="-mt-2"
                columns={patientcolumns}
                data={patrdv}
                pagination
                fixedHeader
                fixedHeaderScrollHeight="450px"
                selectableRowsHighlight
                highlightOnHover
                customStyles={tableHeaderstyle}
                subHeader
                subHeaderComponent={
                  <input
                    type="text"
                    placeholder="search here..."
                    className="w-[70%] h-[35px] float-left  rounded-md text-black 
                border border-[#0b1011] py-1 px-4 -mb-1 -ml-6 -mt-1 bg-slate-50 "
                    onChange={search2}
                  />
                }
                subHeaderAlign="left"
              />
            </>
          ) : (
            ""
          )
        ) : (
          ""
        )}
      </div>
    </div>
  );
}

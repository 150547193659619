import React, { useEffect, useState } from "react";
import * as cdiService from "../../services/cdiService";
import Select from "react-select";
import { useNavigate, useParams } from "react-router-dom";
import Header from "../../components/Header";
import SideBar2 from "../../components/SideBar2";

export default function UpdatePatient() {
  const { id } = useParams();

  const navigate = useNavigate();
  const [profession] = useState("");
  const [firstname] = useState("");
  const [lastname] = useState("");
  const [email] = useState("");
  const [telephone] = useState("");
  const [dob] = useState("");
  const [insurance_pourcentage] = useState("");
  const [insurance_matricule] = useState("");
  const [record, setRecord] = useState([]);
  const [insurance, setInsurances] = useState();
  const [gender, setGender] = useState("");

  const loadPatientInfo = () => {
    cdiService.ListSignlepatient(id).then((res) => {
      console.log("PATIENT INFO", res.data);
      setData(res.data);
    });
  };

  const allInsurances = () => {
    cdiService.insuranceList().then((res) => {
      setRecord(res.data);
    });
  };

  useEffect(() => {
    allInsurances();
    loadPatientInfo();
  }, []);

  const assurances = [
    record.map((insurance) => {
      return {
        value: insurance.insurancename,
        label: insurance.insurancename,
      };
    }),
  ];

  const handleInsuranceChange = (value) => {
    console.log("INSURANE CHANGE", value);
    setInsurances(value);
  };
  const handleSelectGenderChange = (value) => {
    console.log("GENDER CHANGE", value);
    setGender(value);
  };

  const options = [
    { value: "MALE", label: "Male" },
    { value: "FEMELE", label: "Femele" },
    { value: "OTHER", label: "Autre" },
  ];

  const [data, setData] = useState({
    firstname,
    lastname,
    dob,
    profession,
    email,
    gender,
    telephone,
    insurance,
    insurance_pourcentage,
    insurance_matricule,
  });

  console.log("CHECKING UPDATE DATA", data);

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      cdiService
        .updatePatient(id, data)
        .then(
          (res) => {
            navigate("/patient");
          },
          (error) => {
            console.log(error);
          }
        )
        .catch((error) => {
          console.log("ERROR", error.res);
        });
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <div class="min-h-screen flex flex-col flex-auto flex-shrink-0 antialiased bg-white dark:bg-gray-700 text-black dark:text-white">
      <Header />
      <SideBar2 />
      <div className="h-full ml-14 mt-14 mb-10 md:ml-64">
        <div className="grid grid-cols-1 lg:grid-cols-1 p-4 gap-2">
          <div className="bg-white rounded shadow-lg p-4 px-4 md:p-8 mb-6">
            <div className="grid gap-4 gap-y-2 text-sm grid-cols-1 lg:grid-cols-3">
              <div className="text-gray-600">
                <p className="font-medium text-lg">
                  Information Personnelles du patient
                </p>
                <p>Remplissez ce formulaire, s'il vout plait.</p>
              </div>

              <div className="lg:col-span-2">
                <form onSubmit={handleSubmit}>
                  <div className="grid gap-4 gap-y-2 text-sm grid-cols-1 md:grid-cols-5">
                    <div className="md:col-span-2 mt-2">
                      <label for="Status"> Sex</label>
                      <Select
                        placeholder="Assignez le sex"
                        options={options}
                        value={data.gender}
                        onChange={(handleSelectGenderChange) =>
                          setData({
                            ...data,
                            gender: handleSelectGenderChange.value,
                          })
                        }
                        isSearchable
                        autoFocus
                        noOptionsMessage={() => "No gender found..."}
                        styles={{
                          placeholder: (baseStyles, state) => ({
                            ...baseStyles,
                            color: "red",
                          }),
                          dropdownIndicator: () => ({
                            color: "red",
                          }),
                        }}
                      />
                    </div>
                    <div className="md:col-span-3">
                      <label for="address">Prénoms</label>
                      <input
                        type="text"
                        name="firstname"
                        value={data.firstname}
                        onChange={(e) =>
                          setData({
                            ...data,
                            firstname: e.target.value,
                          })
                        }
                        className="h-10 border mt-1 rounded px-4 w-full bg-gray-50"
                        placeholder=""
                        required
                      />
                    </div>

                    <div className="md:col-span-2">
                      <label for="city">Nom</label>
                      <input
                        type="text"
                        required
                        name="lastname"
                        value={data.lastname}
                        onChange={(e) =>
                          setData({
                            ...data,
                            lastname: e.target.value,
                          })
                        }
                        className="h-10 border mt-1 rounded px-4 w-full bg-gray-50"
                        placeholder=""
                      />
                    </div>

                    <div className="md:col-span-3">
                      <label for="address">Email</label>
                      <input
                        type="email"
                        name="email"
                        value={data.email}
                        onChange={(e) =>
                          setData({
                            ...data,
                            email: e.target.value,
                          })
                        }
                        className="h-10 border mt-1 rounded px-4 w-full bg-gray-50"
                        placeholder=""
                      />
                    </div>

                    <div className="md:col-span-2">
                      <label for="city">Profession</label>
                      <input
                        type="text"
                        name="lastname"
                        required
                        value={data.profession}
                        onChange={(e) =>
                          setData({
                            ...data,
                            profession: e.target.value,
                          })
                        }
                        className="h-10 border mt-1 rounded px-4 w-full bg-gray-50"
                        placeholder=""
                      />
                    </div>

                    <div className="md:col-span-3">
                      <label for="address">N° Téléphone</label>
                      <input
                        type="text"
                        name="telephone"
                        required
                        value={data.telephone}
                        onChange={(e) =>
                          setData({
                            ...data,
                            telephone: e.target.value,
                          })
                        }
                        className="h-10 border mt-1 rounded px-4 w-full bg-gray-50"
                        placeholder=""
                      />
                    </div>

                    <div className="md:col-span-2">
                      <label for="city"> Date de naissance</label>
                      <input
                        type="date"
                        required
                        value={data.dob}
                        onChange={(e) =>
                          setData({
                            ...data,
                            dob: e.target.value,
                          })
                        }
                        className="h-10 border mt-1 rounded px-4 w-full bg-gray-50"
                        placeholder=""
                      />
                    </div>
                    <div className="md:col-span-3">
                      <label for="Assurance"> Assurance</label>
                      <Select
                        placeholder="Assignez l'assurance"
                        options={assurances[0]}
                        value={data.insurance}
                        onChange={(handleInsuranceChange) =>
                          setData({
                            ...data,
                            insurance: handleInsuranceChange.value,
                          })
                        }
                        noOptionsMessage={() => "No Insurance found..."}
                        styles={{
                          placeholder: (baseStyles, state) => ({
                            ...baseStyles,
                            color: "red",
                          }),
                          dropdownIndicator: () => ({
                            color: "red",
                          }),
                        }}
                      />
                    </div>
                    <div className="md:col-span-2 mt-1">
                      <label for="Couverture assurance">
                        Couverture assurance en chiffre
                      </label>
                      <input
                        type="number"
                        name="insurance_pourcentage"
                        value={data.insurance_pourcentage}
                        onChange={(e) =>
                          setData({
                            ...data,
                            insurance_pourcentage: e.target.value,
                          })
                        }
                        className="h-10 border  rounded px-4 w-full bg-gray-50 mt-1"
                        placeholder="Couverture en chiffre "
                      />
                    </div>

                    <div className="md:col-span-3">
                      <label for="insurance_matricule">
                        Matricule du patient
                      </label>
                      <input
                        type="text"
                        name="insurance_matricule"
                        value={data.insurance_matricule}
                        onChange={(e) =>
                          setData({
                            ...data,
                            insurance_matricule: e.target.value,
                          })
                        }
                        className="transition-all flex items-center h-10 border mt-1 rounded px-4 w-full bg-gray-50"
                        placeholder=""
                      />
                    </div>
                  </div>
                  <div className="md:col-span-5 mt-6">
                    <button className="bg-white  text-black font-bold py-2 px-4 rounded w-full">
                      Mettre à jour
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

import React, { useEffect, useState } from "react";
import * as cdiService from "../../services/cdiService";

import { Chart as ChartJs, BarElement } from "chart.js/auto";
import { Bar, Line } from "react-chartjs-2";
import Header from "../../components/Header";
import SideBar2 from "../../components/SideBar2";
import ReportWelcome from "./ReportWelcome";

ChartJs.register(BarElement);

var options = {
  // animations: {
  //   tension: {
  //     duration: 1000,
  //     easing: 'linear',
  //     from: 1,
  //     to: 0,
  //     loop: true
  //   }
  // },
  responsive: true,

  scales: {
    y: {
      beginAtZero: true,
    },
  },
};

export default function ReportDataAssurance() {
  const [empInsrecord, setempInsrecord] = useState([]);
  const [treatments, setMyTreatments] = useState([]);

  const allTreatments = () => {
    cdiService.allTreatments().then((res) => {
      setMyTreatments(res.data);
    });
  };

  const ListInsuranceAndPat = () => {
    cdiService.insuranceAndPatientList().then((res) => {
      setempInsrecord(res.data);
    });
  };

  useEffect(() => {
    ListInsuranceAndPat();
    allTreatments();
  }, []);

  let arr = empInsrecord;

  function findOcc(arr, key) {
    let arr2 = [];
    arr.forEach((x) => {
      // Checking if there is any object in arr2
      // which contains the key value
      if (
        arr2.some((val) => {
          return val[key] === x[key];
        })
      ) {
        // If yes! then increase the occurrence by 1
        arr2.forEach((k) => {
          if (k[key] === x[key]) {
            k["occurrence"]++;
          }
        });
      } else {
        // If not! Then create a new object initialize
        // it with the present iteration key's value and
        // set the occurrence to 1
        let a = {};
        a[key] = x[key];
        a["occurrence"] = 1;
        arr2.push(a);
      }
    });
    return arr2;
  }

  let key = "insurance";
  console.log("PRINTING OCCURENCES OF EACH INSURANCE NAME ", findOcc(arr, key));

  var info = {
    labels: findOcc(arr, key).map((x) => x.insurance),
    datasets: [
      {
        label: "# Repartition des patients par Assurance",
        data: findOcc(arr, key).map((x) => x.occurrence),
        backgroundColor: [
          "rgb(255, 0, 0)",
          "rgb(148,0,211)",
          "rgb(0,0,255)",
          "rgb(255,255,0)",
          "rgb(255,165,0)",
          "rgb(0,128,0)",
          "rgb(255,225,107)",
          "rgb(250,51,105)",
        ],
        borderColor: ["rgb(0, 0, 0)"],
        borderWidth: 1,
      },
    ],
  };

  var array = [
    { Id: "01", qty: 11 },
    { Id: "02", qty: 8 },
    { Id: "03", qty: 13 },
    { Id: "01", qty: 10 },
    { Id: "02", qty: 12 },
    { Id: "03", qty: 2 },
    { Id: "01", qty: 12 },
    { Id: "02", qty: 12 },
  ];

  var result = [];
  array.reduce(function (res, value) {
    if (!res[value.Id]) {
      res[value.Id] = { Id: value.Id, qty: 0 };
      result.push(res[value.Id]);
    }
    res[value.Id].qty += value.qty;
    return res;
  }, {});

  var Insresult = [];
  treatments.reduce(function (res, amt) {
    if (!res[amt.patient.insurance]) {
      res[amt.patient.insurance] = {
        INSURANCE: amt.patient.insurance,
        partinsurance: 0,
      };
      Insresult.push(res[amt.patient.insurance]);
    }
    res[amt.patient.insurance].partinsurance += amt.partinsurance;
    return res;
  }, {});

  console.log("CHECKING INSURANCE", Insresult);
  console.log("ALL TREATMENTS", treatments);

  var info3 = {
    labels: Insresult.map((x) => x.INSURANCE),
    datasets: [
      {
        label: "# Recette par assurance",
        data: Insresult.map((x) => x.partinsurance),
        backgroundColor: [
          "rgb(255, 0, 0)",
          "rgb(148,0,211)",
          "rgb(0,0,255)",
          "rgb(255,255,0)",
          "rgb(255,165,0)",
          "rgb(0,128,0)",
          "rgb(255,225,107)",
          "rgb(250,51,105)",
        ],
        borderColor: ["rgb(0, 0, 0)"],
        borderWidth: 1,
      },
    ],
  };

  return (
    <div className="min-h-screen flex flex-col flex-auto flex-shrink-0 antialiased bg-white dark:bg-gray-700 text-black dark:text-white">
      <Header />
      <SideBar2 />
      <div className="h-full ml-14 mt-14 mb-10 md:ml-64">
        <ReportWelcome />

        <div className="grid grid-cols-1 lg:grid-cols-1 p-4 gap-2 mt-10">
          <div className="">
            <Line data={info} height={100} options={options} />
            <p> NOMBRE DE PATIENTS PAR ASSURANCE</p>
          </div>
          <div className="">
            <Line data={info3} height={100} options={options} />
            <p> RECETTE PAR ASSURANCE</p>
          </div>
        </div>
      </div>
    </div>
  );
}

import {
  MdSick,
  MdAnalytics,
} from "react-icons/md";

import { CiTimer } from "react-icons/ci";import {
  BsCalendarCheck,
  BsFiles,
  BsActivity,
  BsFillHospitalFill, BsEnvelopeCheck 
} from "react-icons/bs";

import { GiTakeMyMoney, GiReceiveMoney } from "react-icons/gi";
import { FaUserDoctor } from "react-icons/fa6";
import { AiFillInsurance, AiFillDashboard } from "react-icons/ai";

export const admin = [
  { title: "Welcome", path: "/dashboardwelcome", icon: <CiTimer /> },
  { title: "Dashboard", path: "/dashboard", icon: <AiFillDashboard /> },
  { title: "Assurance", path: "/insurance", icon: <AiFillInsurance /> },
  { title: "Bills", path: "/bill", icon: <GiTakeMyMoney /> },
  { title: "Cash Flow", path: "/expenses", icon: <GiReceiveMoney /> },
  { title: "Clinique Settings", path: "/clinic", icon: <BsFillHospitalFill /> },
  { title: "Intervention", path: "/intervention", icon: <MdAnalytics /> },
  { title: "Messages", path: "/message", icon: <BsEnvelopeCheck /> },
  { title: "Patient", path: "/patient", icon: <BsActivity /> },
  { title: "Report", path: "/report", icon: <BsFiles /> },
  { title: "Rendez-vous", path: "/appointment", icon: <BsCalendarCheck /> },
  { title: "Traitement", path: "/treatment", icon: <MdSick /> },
  { title: "User", path: "/user", icon: <FaUserDoctor /> },
];

export const doc = [
  { title: "Welcome", path: "/dashboardwelcome", icon: <CiTimer /> },
  { title: "Dashboard", path: "/dashboard", icon: <AiFillDashboard /> },
  { title: "Messages", path: "/message", icon: <BsEnvelopeCheck /> },
  { title: "Patient", path: "/patient", icon: <BsActivity /> },
  { title: "Traitement", path: "/treatment", icon: <MdSick /> },
  { title: "Rendez-vous", path: "/appointment", icon: <BsCalendarCheck /> },
];

export const cas = [
  { title: "Welcome", path: "/dashboardwelcome", icon: <CiTimer /> },
  { title: "Bills", path: "/bill", icon: <GiTakeMyMoney /> },
  { title: "Cash Flow", path: "/expenses", icon: <GiReceiveMoney /> },
  { title: "Messages", path: "/message", icon: <BsEnvelopeCheck /> },
  { title: "Patient", path: "/patient", icon: <BsActivity /> },
  { title: "Rendez-vous", path: "/appointment", icon: <BsCalendarCheck /> },
  { title: "Traitement", path: "/treatment", icon: <MdSick /> },
];

export const acc = [
  { title: "Welcome", path: "/dashboardwelcome", icon: <CiTimer /> },
  { title: "Bills", path: "/bill", icon: <GiTakeMyMoney /> },
  { title: "Report", path: "/report", icon: <BsFiles /> },

];


export const patient = [
  { title: "Welcome", path: "/dashboardwelcome", icon: <CiTimer /> },
  { title: "Rendez-vous", path: "/appointment", icon: <BsCalendarCheck /> },
  { title: "Traitement", path: "/treatment", icon: <MdSick /> },
];
